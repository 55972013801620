import {
  esrAddress,
  esrIndividual,
} from "../service-catalog/EntitySpecificationRelationship";

export const esrWorkSiteAddress = esrAddress("Work Site.Address");
export const esrWorkSiteContact = esrIndividual("Work Site.Site Contact");

export const esrCustomerSiteAddress = esrAddress("Customer Site.Address");
export const esrCustomerSiteContact = esrIndividual(
  "Customer Site.Site Contact"
);

export const esrASiteAddress = esrAddress("A-Site.Address");
export const esrASiteContact = esrIndividual("A-Site.Site Contact");

export const esrBSiteAddress = esrAddress("B-Site.Address");
export const esrBSiteContact = esrIndividual("B-Site.Site Contact");

export const esrCurrentSiteAddress = esrAddress(
  "Current Customer Site.Address"
);
export const esrCurrentSiteContact = esrIndividual(
  "Current Customer Site.Site Contact"
);

export const esrNewSiteAddress = esrAddress("New Customer Site.Address");
export const esrNewSiteContact = esrIndividual(
  "New Customer Site.Site Contact"
);


export const esrCurrentASiteAddress = esrAddress("Current A-Site.Address");
export const esrCurrentASiteContact = esrIndividual("Current A-Site.Site Contact");

export const esrCurrentBSiteAddress = esrAddress("Current B-Site.Address");
export const esrCurrentBSiteContact = esrIndividual("Current B-Site.Site Contact");

export const esrNewASiteAddress = esrAddress("New A-Site.Address");
export const esrNewASiteContact = esrIndividual("New A-Site.Site Contact");

export const esrNewBSiteAddress = esrAddress("New B-Site.Address");
export const esrNewBSiteContact = esrIndividual("New B-Site.Site Contact");