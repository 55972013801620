import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivityC2P } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { orderTypeValues, orderTypeValuesIsdn } from "../common/Constants";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
  esrASiteAddress,
  esrASiteContact,
  esrCurrentSiteAddress,
  esrCurrentSiteContact,
  esrNewSiteAddress,
  esrNewSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery, svcspecCopperAdvanceInstallation } from "../catalogs/CatalogFieldServices";

import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";
import { SO, addAppointmentGroupName, getGenericConnectivitysA, getGenericInstallations, getGenericRecoveryA } from "../common/CommonUtils";

export function schemaC2P(props) {
  const { name, installationSpec, cableSpec, genericInstallations, 
    genericConnectivity, genericRecovery, BIZVOICE, onlyCPE } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PAddVAS,
    },
    "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PExtRelocationHot,
    },
    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [],
      decompose: (flatInstance) => [],
    },
  };

  function decomposeC2PProvide(flatInstance) {

    const svcCP2Cable = cableSpec?.updateInstance({}, flatInstance, [
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;

    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    let genericInstallationServices = [];
    if(genericInstallations){
      genericInstallationServices = []
      genericInstallations?.forEach((key, value) => {
        const genEqptInstlAndRecvry = key ? key?.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ]):null;
        addAppointmentGroupName(genEqptInstlAndRecvry, value)
        genericInstallationServices.push({ action: "add", service: genEqptInstlAndRecvry})
        });
      }


      let genericConnectivitys = [];
      if(genericConnectivity){
        genericConnectivity?.forEach((cableSpec, productName) => {
        const genericCableSpec = cableSpec?cableSpec?.updateInstance({}, flatInstance, [
          [esrASiteAddress, esrCustomerSiteAddress],
          [esrASiteContact, esrCustomerSiteContact],
        ]):null;
          addAppointmentGroupName(genericCableSpec, productName)
          genericConnectivitys.push({ action: "add", service: genericCableSpec})
          });
      }



    console.debug(
      "schemaC2P:decompose New Provisioning svcCP2Cable,svcInstallation, genericInstallationServices",
      svcCP2Cable,
      svcInstallation,
      genericInstallationServices
    );
    return [
     
      { action: "add", service: svcCP2Cable },
      { action: "add", service: svcInstallation },
      { action: "add", service: svcWiring },
      ...genericInstallationServices,
      ...genericConnectivitys,
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PAddVAS(flatInstance) {


    if(onlyCPE){
      const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
        //mapping
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrCustomerSiteAddress],
        [esrWorkSiteContact, esrCustomerSiteContact],
      ]);
      // let genericInstallationServices = [];
      // if (genericInstallations) {
      //   genericInstallations?.forEach((key, value) => {
      //     const genEqptInstlAndRecvry = key ? key?.updateInstance({}, flatInstance, [
      //       [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      //       [esrWorkSiteAddress, esrCustomerSiteAddress],
      //       [esrWorkSiteContact, esrCustomerSiteContact],
      //     ]) : null;
      //       addAppointmentGroupName(genEqptInstlAndRecvry, value)
      //       genericInstallationServices.push({ action: "add", service: genEqptInstlAndRecvry })
      //   });
      // }
      return [{ action: "add", service: svcInstallation }].filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
    }

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    console.debug(
      "schemaC2P:decompose Change Add VAS svcInstallation",
      svcInstallation
    );

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;

    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    let genericInstallationServices = [];
    let genericConnectivitys = [];

    if (!BIZVOICE) {
      let genericInstallationServices = [];
      let isBizVoiceAdded = false;
      if (genericInstallations) {
        genericInstallationServices = []
        genericInstallations?.forEach((key, value) => {
          const genEqptInstlAndRecvry = key ? key?.updateInstance({}, flatInstance, [
            [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
            [esrWorkSiteAddress, esrCustomerSiteAddress],
            [esrWorkSiteContact, esrCustomerSiteContact],
          ]) : null;
          if (BIZVOICE && !isBizVoiceAdded) {
            addAppointmentGroupName(genEqptInstlAndRecvry, value)
            genericInstallationServices.push({ action: "add", service: genEqptInstlAndRecvry })
            isBizVoiceAdded = true
          } else {
            if (BIZVOICE && isBizVoiceAdded) {
              addAppointmentGroupName(svcInstallation, value)
            }
          }
        });
      }

      if (genericConnectivity) {
        genericConnectivity?.forEach((cableSpec, productName) => {
          const genericCableSpec = cableSpec ? cableSpec?.updateInstance({}, flatInstance, [
            [esrASiteAddress, esrCustomerSiteAddress],
            [esrASiteContact, esrCustomerSiteContact],
          ]) : null;
          addAppointmentGroupName(genericCableSpec, productName)
          genericConnectivitys.push({ action: "add", service: genericCableSpec })
        });
      }

    }
    

  

    return [{ action: "add", service: svcInstallation },
           { action: "add", service: svcWiring },
           ...genericConnectivitys,
           ...genericInstallationServices
          ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PExtRelocationHot(flatInstance) {
    const svcCable = cableSpec?.updateInstance({}, flatInstance, [
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value; 
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrNewSiteAddress],
          [esrWorkSiteContact,esrNewSiteContact]
        ]);
    }

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiCable = svcCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcCable,
        })
      : undefined;
    
      const soiWiring = svcWiring
      ? new ServiceOrderItem({
          action: "add",
          service: svcWiring,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiRecovery, soiCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;


        let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
          [
            [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
            [esrWorkSiteAddress, esrNewSiteAddress],
            [esrWorkSiteContact, esrNewSiteContact],

          ], SO);
  
        
      let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
        [
          [esrASiteAddress, esrNewSiteAddress],
          [esrASiteContact, esrNewSiteContact],
        ],SO);


        let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ], SO);
        
 
    let orderItems =  [soiInstallation, soiCable, soiWiring, 
      ...genericInstallationServicesA, ...genericConnectivitysA].filter((a) => a);

      if(!BIZVOICE){
        orderItems.push(soiRecovery);
        orderItems.push(...genericRecoveryA);
       }

      return orderItems.filter((a) => a);
  }

  function decomposeC2PUpDownGradeHot(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
      
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
      [
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrNewSiteAddress],
        [esrWorkSiteContact, esrNewSiteContact],
      ], JSON);

    
  let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
    [
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ],JSON);

let orderItems =  [
  { action: "add", service: svcInstallation },
  { action: "add", service: svcWiring },
  ...genericInstallationServicesA,
  ...genericConnectivitysA
];

    if(!BIZVOICE){
      orderItems.push( { action: "add", service: svcRecovery });
     }

    return orderItems.filter((a) => a.service).map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PUpDownGradeParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
      
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    const soiNewCable = svcNewCable
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //In parallel upgrade/downgrade scenarios equipment recovery should be done in 1 field job with installation.
    //So we will put any dependency for it hoping DFF will merge the field services into a single appointment
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiWiring = svcWiring
      ? new ServiceOrderItem({
          action: "add",
          service: svcWiring,
        })
      : undefined;



      let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
        [
         [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
        ], SO);

      
    let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
      [
        [esrASiteAddress, esrCustomerSiteAddress],
        [esrASiteContact, esrCustomerSiteContact],
      ],SO);


      let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],

      ], SO);



      let orderItems = [soiInstallation, soiOldCable, soiNewCable, soiWiring,
        ...genericInstallationServicesA,
         ...genericConnectivitysA
        ];
      
          if(!BIZVOICE){
            orderItems.push(soiRecovery);
            orderItems.push(...genericRecoveryA);
           }




    return orderItems.filter((a) => a);
  }

  function decomposeC2PCeaseEquipmentRecovery(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    return [soiRecovery].filter((a) => a);
  }

  function decomposeC2PExtRelocationParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCurrentSiteAddress],
      [esrASiteContact, esrCurrentSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
      
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    const soiNewCable = svcNewCable
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //recovery of the equpment from old site can only be done after new installation completed
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiWiring = svcWiring
      ? new ServiceOrderItem({
          action: "add",
          service: svcWiring,
        })
      : undefined;


      let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
        [
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrNewSiteAddress],
          [esrWorkSiteContact, esrNewSiteContact],
        ], SO);

      
    let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
      [
        [esrASiteAddress, esrNewSiteAddress],
        [esrASiteContact, esrNewSiteContact],
      ],SO);


      let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrCurrentSiteAddress],
        [esrWorkSiteContact, esrCurrentSiteContact],
      ], SO);


      let orderItems =  [soiInstallation, soiOldCable, soiNewCable, soiWiring,
        ...genericInstallationServicesA, ...genericConnectivitysA];
      
          if(!BIZVOICE){
            orderItems.push( soiRecovery);
            orderItems.push( ...genericRecoveryA);
           }


      

    return orderItems.filter(
      (a) => a
    );
  }

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

//temporary limit list of Order types until all order types supported
const chrspecC2POrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),

    chvspecString(orderTypeValues.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});


export function svcspecC2PCopper(props) {
  const { name, installationServiceSpec, characteristics } = props;
  
  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2P({
      name: "C2P-" + name,
      installationSpec: installationServiceSpec,
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderType;

  return spec;
}

export function svcspecC2P(props) {
  const { name, installationServiceSpec, cableSpec, characteristics, 
    genericInstallations, genericConnectivity,
    BIZVOICE, onlyCPE } = props;

  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2P({
      name: "C2P-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericInstallations,
      genericConnectivity,
      BIZVOICE, onlyCPE
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderType;

  return spec;
}



const chrspecC2PNoHotUpgradeDowngradeOrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValuesIsdn.NEW_PROVISIONING),
    chvspecString(orderTypeValuesIsdn.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValuesIsdn.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValuesIsdn.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValuesIsdn.HOT_EXT_RELOCATION),
     chvspecString(orderTypeValuesIsdn.CEASE_EQ_RECOVERY),
     chvspecString(orderTypeValuesIsdn.ADD_VAS),
  ],
});


export function svcspecC2PNoHotUpgradeDowngrade(props) {
  const { name, installationServiceSpec, cableSpec, characteristics, genericInstallations, genericConnectivity, genericRecovery } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  console.debug(
    "svcspecC2P name,props,chrspecCommonSet(name),characteristics",
    name,
    props,
    chrspecCommonSet(name),
    characteristics
  );

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2P({
      name: "C2P-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericInstallations,
      genericConnectivity,
      genericRecovery
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2PNoHotUpgradeDowngradeOrderType;

  return spec;
}





const chrspecC2POrderTypeIsdn = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValuesIsdn.NEW_PROVISIONING),
    chvspecString(orderTypeValuesIsdn.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValuesIsdn.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValuesIsdn.PARALLEL_EXT_RELOCATION),
   // chvspecString(orderTypeValuesIsdn.HOT_UPGRADE),
   // chvspecString(orderTypeValuesIsdn.HOT_DOWNGRADE),
    chvspecString(orderTypeValuesIsdn.HOT_EXT_RELOCATION),
     chvspecString(orderTypeValuesIsdn.CEASE_EQ_RECOVERY),
     chvspecString(orderTypeValuesIsdn.ADD_VAS),
  ],
});

export function svcspecC2PIsdn(props) {
  const { name, installationServiceSpec, cableSpec, characteristics, 
     genericInstallations, genericConnectivity, genericRecovery,
     genericName
     } = props;

  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  console.debug(
    "svcspecC2P name,props,chrspecCommonSet(name),characteristics",
    name,
    props,
    chrspecCommonSet(name),
    characteristics
  );

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2P({
      name: "C2P-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericInstallations,
      genericConnectivity,
      genericRecovery,
      genericName
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderTypeIsdn;

  return spec;
}
