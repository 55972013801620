import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecC2P } from "../schema/schemaC2P";
import { chrspec } from "../service-catalog/CharacteristicSpecification";
import { svcspecBusinessDigitalLineEquipmentInstallationFtth, svcspecGenericEquipmentInstallationFtthNo } from "./CatalogFieldServices";
import { svcspecCableConnectivityC2PFtth, svcspecGenericFTTHCableConnectivity } from "./CatalogCableConnectivity";
import { SIP_Generic } from "../common/Constants";
import { chrspecFtthNo } from "./CharacteristicsCatalog";

const chrspecProductTypeBusinessDigitalLine = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Telephony - Business Digital Line",
});

function BDLTemplate(name) {
  return svcspecC2P({
    name: name,
    cableSpec: svcspecCableConnectivityC2PFtth,
    installationServiceSpec:svcspecBusinessDigitalLineEquipmentInstallationFtth,
    characteristics: [chrspecProductTypeBusinessDigitalLine, chrspecFtthNo],
    genericInstallations: new Map().set(SIP_Generic, svcspecGenericEquipmentInstallationFtthNo),
    genericConnectivity: new Map().set(SIP_Generic, svcspecGenericFTTHCableConnectivity),
    onlyCPE: true
  });
}

export const CatalogTelephony = new ServiceCatalog()
  
  .category("Business Digital Line (BDL)")
  .serviceSpecs([
    BDLTemplate("Business Digital Line"),
  ])
  
  .endCategory();
