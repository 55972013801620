import { useState, useRef } from "react";
import { Row, Col, Steps } from "antd";

import { OrganizationPanel } from "../party/organizationPanel";
import { DocumentPanel } from "../document/documentPanel";
import { EditServicesPage } from "./EditServicesPage";
import { BookingPage } from "./BookingPage";

import { masterServiceCatalog } from "../catalogs/MasterCatalog";
import NamedCache from "../common/namedCache";
import { organizationDummy } from "../common/mockupData";
import { Summary } from "./Summary";
import {
  addressKerrisdale,
  addressComcentre,
  addressComcentreUnit,
  johnDoe,
} from "../common/mockupData";

export function BookingWizard(props) {
  const { userName, email} = props;
  const [step, setStep] = useState(0);
  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [appointments, setAppointments] = useState([]);

  const servicesRef = useRef({});
  const customerOrgRef = useRef(null);
  const orderContextRef = useRef(null);

  const organizationAddressBook = useRef(
    new NamedCache(null, [[customerOrgRef?.current?._name(), customerOrgRef?.current]])
  );

  const addressBookRef = useRef(
    new NamedCache(null, [
      [addressKerrisdale.name, addressKerrisdale],
      [addressComcentre.name, addressComcentre],
      [addressComcentreUnit.name, addressComcentreUnit],
    ])
  );

  const phoneBookRef = useRef(
    new NamedCache(null, [[johnDoe.name(), johnDoe]])
  );

  const STEP_CUSTOMER = "Customer";
  const STEP_SERVICES = "Services";
  const STEP_DOCUMENTS = "Documents";

  const goNext = () => step < wizardSteps.length && setStep(step + 1);
  const goBack = () => step > 0 && setStep(step - 1);

  const onServiceNext = (orderContext) => {
    orderContextRef.current = orderContext;
    goNext();
  };

  console.debug("BookingWizard:step", step);

  const wizardSteps = [
    {
      title: STEP_CUSTOMER,
      renderer: () => (
        <OrganizationPanel
          onWizardNext={goNext}
          onChange={customerOrgChange}
          organizationAddressBook={organizationAddressBook.current}
          value={customerOrgRef.current}
        />
      ),
    },
    {
      title: STEP_SERVICES,
      renderer: () => (
        <EditServicesPage
          serviceCatalog={masterServiceCatalog}
          initialServices={servicesRef.current}
          onWizardNext={onServiceNext}
          onWizardBack={goBack}
          onChange={onServicesChange}
          customerOrganization={customerOrgRef.current}
          addressBookRef={addressBookRef}
          phoneBookRef={phoneBookRef}
          userName={userName} 
          email={email}
        />
      ),
    },
    {
      title: STEP_DOCUMENTS,
      type: "navigation",
      renderer: () => (
        <DocumentPanel
          onWizardNext={goNext}
          onWizardBack={goBack}
          supportingDocuments={supportingDocuments}
          setSupportingDocuments={setSupportingDocuments}
        />
      ),
    },
    /*  {
      title: "Appointments",
      renderer: () => (
        <AppointmentsPage
          serviceContexts={servicesRef.current}
          orderContext={orderContextRef.current}
          onWizardNext={goNext}
          onWizardBack={goBack}
        />
      ),
    },*/
    {
      title: "Booking",
      renderer: () => (
        <BookingPage
          appointments={setAppointments}
          orderContext={orderContextRef.current}
          onWizardNext={goNext}
          onWizardBack={goBack}
        />
      ),
    },
    {
      title: "Summary",
      renderer: () => (
        <Summary
          customerOrganization={customerOrgRef.current}
          appointments={appointments}
          initialServices={servicesRef.current}
          orderContext={orderContextRef.current}
          supportingDocuments={supportingDocuments}
          catalog={masterServiceCatalog}
          addressBookRef={addressBookRef}
          phoneBookRef={phoneBookRef}
          onWizardBack={goBack}
        />
      ),
    },
  ];
  const stepIndex = (stepTitle) =>
    wizardSteps.findIndex((s) => s.title === stepTitle);

  function onServicesChange(s) {
    servicesRef.current = s;
  }

  function customerOrgChange(s) {
    customerOrgRef.current = s;
  }

  return (
    <div className="booking-wizard">
      {/* STEPS */}
      <Row>
        <Col span="24">
          <Steps current={step} items={wizardSteps} onChange={setStep} />
        </Col>
      </Row>
      {/* WIZARD PAGE */}
      {wizardSteps[step].renderer()}
      <Row>{/* NAVI BUTTONS */}</Row>
    </div>
  );
}
