import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivityC2P } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { orderTypeValues } from "../common/Constants";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
  esrASiteAddress,
  esrASiteContact,
  esrCurrentSiteAddress,
  esrCurrentSiteContact,
  esrNewSiteAddress,
  esrNewSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery } from "../catalogs/CatalogFieldServices";
import _ from "lodash";

import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";
import { JSON, SO, addAppointmentGroupName, deepClone, getGenericConnectivitysA, getGenericInstallations, getGenericRecoveryA } from "../common/CommonUtils";

export function schemaC2PX2(props) {
  const { name, installationSpec, cableSpec, genericInstallations, genericConnectivity, genericRecovery } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2Provide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2AddVAS,
    },
    "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PX2ExtRelocationHot,
    },
    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2UpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2UpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2UpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2UpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PX2ExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PX2CeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [],
      decompose: (flatInstance) => [],
    },
  };

  function decomposeC2PX2Provide(flatInstance) {
   

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);


    let genericConnectivitys = [];
    if(genericConnectivity){
      genericConnectivity?.forEach((cableSpec, groupName) => {
      const genericCableSpec = cableSpec?cableSpec?.updateInstance({}, flatInstance, [
        [esrASiteAddress, esrCustomerSiteAddress],
        [esrASiteContact, esrCustomerSiteContact],
      ]):null;
        addAppointmentGroupName(genericCableSpec, groupName)
        genericConnectivitys.push({ action: "add", service: genericCableSpec})
        });
    }

    const svcCP2Cable1 = cableSpec?.updateInstance({}, flatInstance, [
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    addAppointmentGroupName(svcCP2Cable1, "Gigawave Generic Equipment Installation - Monitoring")

     const svcCP2Cable2 = cableSpec?.updateInstance({}, flatInstance, [
       [esrASiteAddress, esrCustomerSiteAddress],
       [esrASiteContact, esrCustomerSiteContact],
     ]);
    addAppointmentGroupName(svcCP2Cable2, "Gigawave Generic Equipment Installation - DWDM Fibre Main")
    
    let genericInstallationServices = null;
    if(genericInstallations){
      genericInstallationServices = [];
      genericInstallations?.forEach((key, value) => {
        const genEqptInstlAndRecvry = key ? key?.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ]):null;
        addAppointmentGroupName(genEqptInstlAndRecvry, value)
        genericInstallationServices.push({ action: "add", service: genEqptInstlAndRecvry})
        });
    }


 

    return [
     
      { action: "add", service: svcInstallation },
      { action: "add", service: svcCP2Cable1 },
      { action: "add", service: svcCP2Cable2 },
    
       ...genericInstallationServices, 
       ...genericConnectivitys
      
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PX2AddVAS(flatInstance) {
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    console.debug(
      "schemaC2PX2:decompose Change Add VAS svcInstallation",
      svcInstallation
    );

    //Generic Equipment Installation
//SingNet Gigawave Generic Equipment Installation
//Gigawave Generic Equipment Installation - Monitoring
//Gigawave Generic Equipment Installation - DWDM Fibre Main    
let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
  [
    [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact]
  ], JSON);


//Generic E2E Cable Connectivity
//default
//SingNet Gigawave Generic Equipment Installation
let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
[
  [esrASiteAddress, esrNewSiteAddress],
[esrASiteContact, esrNewSiteContact],
],JSON);


    return [{ action: "add", service: svcInstallation },
  ...genericInstallationServicesA,
...genericConnectivitysA]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PX2ExtRelocationHot(flatInstance) {
    const svcCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiCable = svcCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcCable,
        })
      : undefined;
    
    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiRecovery, soiCable, soiCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;




      let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
        [
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
        ], SO);

      
        
    let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
      [
        [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
      ],SO);


      let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrCurrentSiteAddress],
        [esrWorkSiteContact, esrCurrentSiteContact],
      ], SO);


      let newMonitoringC2P = _.cloneDeep(soiCable)
      let newDWDMFibreC2P= _.cloneDeep(soiCable)
      addAppointmentGroupName(newMonitoringC2P, "Gigawave Generic Equipment Installation - DWDM Fibre Main")
      addAppointmentGroupName(newDWDMFibreC2P, "Gigawave Generic Equipment Installation - Monitoring")
    

    return [soiRecovery, soiInstallation, newMonitoringC2P, newDWDMFibreC2P,
    ...genericInstallationServicesA,
    ...genericConnectivitysA,
    ...genericRecoveryA
  ].filter((a) => a);
  }

  function decomposeC2PX2UpDownGradeHot(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);



    
    let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
      [
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrCustomerSiteAddress],
        [esrWorkSiteContact, esrCustomerSiteContact],
      ], JSON);

    

    let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ], JSON);




    return [
      { action: "add", service: svcInstallation },
      { action: "add", service: svcRecovery },
      ...genericInstallationServicesA,
      ...genericRecoveryA
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PX2UpDownGradeParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    const soiNewCable = svcNewCable
    ? new ServiceOrderItem({
        action: "add",
        service: svcNewCable,
      })
    : undefined;

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);


    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //In parallel upgrade/downgrade scenarios equipment recovery should be done in 1 field job with installation.
    //So we will put any dependency for it hoping DFF will merge the field services into a single appointment
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      let newMonitoringC2P = _.cloneDeep(soiNewCable)
      let newDWDMFibreC2P= _.cloneDeep(soiNewCable)
      addAppointmentGroupName(newMonitoringC2P, "Gigawave Generic Equipment Installation - DWDM Fibre Main")
      addAppointmentGroupName(newDWDMFibreC2P, "Gigawave Generic Equipment Installation - Monitoring")
    
      let oldMonitoringC2P  = _.cloneDeep(soiOldCable)
      let oldDWDMFibreC2P= _.cloneDeep(soiOldCable)
      addAppointmentGroupName(oldMonitoringC2P, "Gigawave Generic Equipment Installation - DWDM Fibre Main")
      addAppointmentGroupName(oldDWDMFibreC2P, "Gigawave Generic Equipment Installation - Monitoring")
    

      
//Generic Equipment Installation
//SingNet Gigawave Generic Equipment Installation
//Gigawave Generic Equipment Installation - Monitoring
//Gigawave Generic Equipment Installation - DWDM Fibre Main    
let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
  [
    [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
[esrWorkSiteAddress, esrNewSiteAddress],
[esrWorkSiteContact, esrNewSiteContact],
  ], SO);


//Generic E2E Cable Connectivity
//default
//SingNet Gigawave Generic Equipment Installation
let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
[
  [esrASiteAddress, esrNewSiteAddress],
[esrASiteContact, esrNewSiteContact],
],SO);


//Generic Equipment Recovery
//SingNet Gigawave Generic Equipment Installation
//Gigawave Generic Equipment Installation - Monitoring
//Gigawave Generic Equipment Installation - DWDM Fibre Main
let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
  [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
  [esrWorkSiteAddress, esrCurrentSiteAddress],
  [esrWorkSiteContact, esrCurrentSiteContact],
], SO);

    return [soiRecovery, soiInstallation, newMonitoringC2P, newDWDMFibreC2P, oldMonitoringC2P,oldDWDMFibreC2P, 
      ...genericInstallationServicesA,
      ...genericConnectivitysA,
      ...genericRecoveryA
     ].filter(
      (a) => a
    );
  }

  function decomposeC2PX2CeaseEquipmentRecovery(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;


    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

           //Generic Equipment Recovery
      //SingNet Gigawave Generic Equipment Installation
      //Gigawave Generic Equipment Installation - Monitoring
      //Gigawave Generic Equipment Installation - DWDM Fibre Main
      let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrCustomerSiteAddress],
        [esrWorkSiteContact, esrCustomerSiteContact],
      ], SO);



    return [soiRecovery, ...genericRecoveryA].filter((a) => a);
  }

  function decomposeC2PX2ExtRelocationParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const soiNewCable = svcNewCable
    ? new ServiceOrderItem({
        action: "add",
        service: svcNewCable,
      })
    : undefined;
    

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      [esrASiteAddress, esrCurrentSiteAddress],
      [esrASiteContact, esrCurrentSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;
      

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);



    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //recovery of the equpment from old site can only be done after new installation completed
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;


//Generic Equipment Installation
//SingNet Gigawave Generic Equipment Installation
//Gigawave Generic Equipment Installation - Monitoring
//Gigawave Generic Equipment Installation - DWDM Fibre Main    
      let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
        [
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
        ], SO);

      
      //Generic E2E Cable Connectivity
      //default
      //SingNet Gigawave Generic Equipment Installation
    let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
      [
        [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
      ],SO);


      //Generic Equipment Recovery
      //SingNet Gigawave Generic Equipment Installation
      //Gigawave Generic Equipment Installation - Monitoring
      //Gigawave Generic Equipment Installation - DWDM Fibre Main
      let genericRecoveryA  = getGenericRecoveryA(genericRecovery,flatInstance,[
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrCurrentSiteAddress],
        [esrWorkSiteContact, esrCurrentSiteContact],
      ], SO);


      let newMonitoringC2P = _.cloneDeep(soiNewCable)
      let newDWDMFibreC2P= _.cloneDeep(soiNewCable)
      addAppointmentGroupName(newMonitoringC2P, "Gigawave Generic Equipment Installation - DWDM Fibre Main")
      addAppointmentGroupName(newDWDMFibreC2P, "Gigawave Generic Equipment Installation - Monitoring")
    

      let oldMonitoringC2P  = _.cloneDeep(soiOldCable)
      let oldDWDMFibreC2P= _.cloneDeep(soiOldCable)
      addAppointmentGroupName(oldMonitoringC2P, "Gigawave Generic Equipment Installation - DWDM Fibre Main")
      addAppointmentGroupName(oldDWDMFibreC2P, "Gigawave Generic Equipment Installation - Monitoring")
    
    return [soiRecovery, soiInstallation, oldMonitoringC2P, oldDWDMFibreC2P,  newMonitoringC2P, newDWDMFibreC2P,
    ...genericInstallationServicesA, ...genericConnectivitysA, ...genericRecoveryA
    ].filter(
      (a) => a
    );
  }

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

//temporary limit list of Order types until all order types supported
const chrspecC2PX2OrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-C2PX2",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});

export function svcspecC2PX2(props) {
  const { name, installationServiceSpec, cableSpec, characteristics,
     genericInstallations, genericConnectivity, genericRecovery } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  console.debug(
    "svcspecC2PX2 name,props,chrspecCommonSet(name),characteristics, genericInstallations",
    name,
    props,
    chrspecCommonSet(name),
    characteristics,
    genericInstallations
  );

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2PX2({
      name: "C2PX2-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericInstallations,
      genericConnectivity,
      genericRecovery
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2PX2OrderType;

  return spec;
}












