import { ServiceSchema } from "./ServiceSchema";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import { svcspecCableConnectivityC2C } from "../catalogs/CatalogCableConnectivity";
import { chrspecCommonSet } from "../catalogs/CharacteristicsCatalog";
import {
  esrASiteAddress,
  esrASiteContact,
  esrBSiteAddress,
  esrBSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";

import { ServiceOrderItem } from "../serviceOrder/serviceOrder";

export function schemaC2CCableOnly(props) {
  const { name } = props;

  const schema = {
    default: {
      specs: (flatInstance) => [
        //esrCustomerSiteAddress,esrCustomerSiteContact
      ],
      decompose: (flatInstance) => {
        const svcCable = svcspecCableConnectivityC2C.updateInstance(
          {},
          flatInstance,
          []
        );

        console.debug("schemaC2CCableOnly:decompose default", svcCable);
        return [new ServiceOrderItem({ action: "add", service: svcCable })];
      },
    },
  };

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

export function svcspecC2CCableOnly(props) {
  const { name, installationServiceSpec, characteristics } = props;
  return new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    entitySpecRelationship: [
      esrASiteAddress,
      esrASiteContact,
      esrBSiteAddress,
      esrBSiteContact,
    ],
    schema: schemaC2CCableOnly({
      name: "C2CFS-" + name,
      installationSpec: installationServiceSpec,
    }),
  });
}
