import React, { useRef, useEffect } from "react";
import { Form, Input, Button, Row, Col, Divider } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Organization } from "./Party";
function UenForm(props) {
  const { Item } = Form;
  const { mode, onFinish, onCancel, uensearch, ...otherProps } = props;
  const focusInputRef = useRef(null);

  const required = { rules: [{ required: true }] };

  function cleanInput(inputVal) {
    if (!inputVal) return undefined;
    return inputVal.trim().toUpperCase();
  }

  function onFormFinish(values) {
    const intialUenData = new Organization({
        name:cleanInput(values?.["Company Name"]),
        uen:cleanInput(values?.["UEN/BRN"])
      });
    onFinish(intialUenData);
  }

  function onFormCancel(values) {
    console.log("UenForm::onFormCancel", values);
    onCancel();
  }

  useEffect(() => {
    console.log("UenForm:Effect", focusInputRef);
    if (focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, [mode]);

  return (
    <Form {...otherProps} onFinish={onFormFinish}>
      <Item label="Company Name" name="Company Name" {...required}>
        <Input placeholder="THE COMPANY PTE LTD" ref={focusInputRef} />
      </Item>
      <Item label="UEN/BRN" name="UEN/BRN" {...required}>
        <Input placeholder="12345678W" />
      </Item>

      <Divider className="compact-divider" />
      <Row justify="end">
        <Col span={6}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<CheckCircleOutlined />}
          >
            Select
          </Button>
        </Col>
        <Col span={6}>
          <Button
            type="ghost"
            htmlType="reset"
            icon={<CloseCircleOutlined />}
            onClick={onFormCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default UenForm;
