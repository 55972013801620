import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecC2CCableOnly } from "../schema/schemaC2CCableOnly";
import { chrspec } from "../service-catalog/CharacteristicSpecification";

const chrspecProductTypeDarkFiber = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Dark Fibre",
});

function DarkFiberTemplate(name) {
  return svcspecC2CCableOnly({
    name: name,
    characteristics: [chrspecProductTypeDarkFiber],
  });
}

export const catalogDarkFiber = new ServiceCatalog()
  .category("Dark Fibre")
  .serviceSpecs([
    DarkFiberTemplate("Dark Fibre (Main)"),
    DarkFiberTemplate("Dark Fibre with Exchange Diversity"),
    DarkFiberTemplate("Dark Fiber for Backhaul"),
    DarkFiberTemplate("Dark Fibre with Path Diversity"),
    DarkFiberTemplate("RIO Dark Fiber Channel"),
    DarkFiberTemplate("Backhaul (Dark Fibre)"),
    DarkFiberTemplate("Backhaul (Dark Fibre) with Path Diversity"),
    DarkFiberTemplate("Backhaul (Dark Fibre) with Exchange Diversity"),
  ])
  .endCategory();
