import { Table, message, Space, Modal, Row, Col, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { defaultAuthNContext } from "../api/authorization";
import { fetchJsonRes } from "../api/utils";
import { CancelServiceOrder } from "../serviceOrder/serviceOrder";
import { CANCEL_SERVICE_ORDER } from "../api/endpoints";
import { ServiceOrderContext } from "../api/ServiceOrderAPI";
import { LaunchMicrosite } from "../appBookingWizard/LaunchMicrosite.js";

export function ServiceOrderDetailsView(props) {
  const { state } = props;
  const [loading, setLoading] = useState(false);
  const [SOid, setSOID] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [orderContext, setOrderContext] = useState(new ServiceOrderContext());

  const { TextArea } = Input;
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Service Order ID",
      dataIndex: "serviceOrderId",
      key: "serviceOrderId",
    },
    {
      title: "Appointment ID",
      dataIndex: "appointmentId",
      key: "appointmentId",
    },
    {
      title: "Ftth No",
      dataIndex: "ftthNo",
      key: "ftthNo",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },

    {
      title: "Product Offering",
      dataIndex: "productOffering",
      key: "productOffering",
    },

    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
    },

    // {
    //   title: "Contact Role",
    //   dataIndex: "contactRole",
    //   key: "contactRole",
    // },
    {
      key: "Actions",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                onEditRecord(record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                onDeleteRecord(record);
              }}
              style={{ color: "red", marginLeft: 12 }}
            />
          </>
        );
      },
    },
  ];
  const handleDeleteCancel = () => setIsDeleteModal(false);
  const handleEditCancel = () => setIsEditModal(false);

  const [datasource, setDataSource] = useState(
    state?.map((row) => ({
      key: row?.id,
      serviceOrderId: row?.id,
      appointmentId: row?.orderItem[0]?.service?.serviceCharacteristic
        .filter((item) => item.name === "Appointment Group ID")
        .map((item) => item.value),
        ftthNo: row?.orderItem[0]?.service?.serviceCharacteristic
        .filter((item) => item.name === "Ftth No")
        .map((item) => item.value),
        productOffering: row?.orderItem[0]?.service?.serviceCharacteristic
        .filter((item) => item.name === "Product Offering")
        .map((item) => item.value),
        orderType: row?.orderItem[0]?.service?.serviceCharacteristic
        .filter((item) => item?.name === "Standalone AB: Order Type")
        .map((item) => item?.value.substring(item?.value?.indexOf(":")+2)),
      companyName: row?.relatedParty[0]?.name,
      //contactRole: row?.relatedParty[1]?.role,
    }))
  );

  const onEditRecord = (record) => {
    const editOrderContext = new ServiceOrderContext();
    const editSO = state.find((item) => item.id === record.serviceOrderId);
    setSOID(record.serviceOrderId);
    editOrderContext.soiWithAppointment = editSO?.orderItem?.filter(
      (soi) => soi?.service?.appointment
    );
    setOrderContext(editOrderContext);
    setIsEditModal(true);
  };

  const onDeleteRecord = (record) => {
    setIsDeleteModal(true);
    setSOID(record.serviceOrderId);
  };

  function cancelServiceOrder(values) {
    const cancellationReason =  defaultAuthNContext?.user?.email+ " : "+values.trim();
    console.log("cancellation reason", cancellationReason);
    setIsDeleteModal(false);
    setLoading(true);
    const cancelServiceOrder = new CancelServiceOrder({
      cancellationReason: cancellationReason,
      serviceOrder: {
        id: SOid,
        "@referredType": "ServiceOrder",
      },
    });
    defaultAuthNContext
      .getToken()
      .then((token) => {
        fetchJsonRes(CANCEL_SERVICE_ORDER, {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(cancelServiceOrder),
        }).then((data) => {
          if (data.response.status === 200) {
            setLoading(false);
            setDataSource((data) =>
              data.filter((item) => item.serviceOrderId !== SOid)
            );
            message.success("Service Order Cancelled successfully");
          } else {
            setLoading(false);
            message.error("Error occured in cancel service order");
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        message.error("Error occured in cancel service order");
        console.error(
          "startServiceOrdering:ERROR\n%o,\nCause:%o",
          error,
          error?.cause
        );
      });
  }

  return (
    <>
      <Modal
        open={isDeleteModal}
        justify="center"
        alignItems= "center"
        title="Delete Confirmation"
        onCancel={handleDeleteCancel}
        destroyOnClose={true}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              cancelServiceOrder(values.cancellationReason);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          <Row>
            <Col flex="5 0">
            <Form  form={form}>
              <Form.Item rules={[{ required: true, message: "Cancellation Reason is Required" }]}  name="cancellationReason"
                id="cancellationReason">
              <TextArea
                rows={4}
                placeholder="Enter the reason for cancellation"   
              />
              </Form.Item>
              </Form>
            </Col>
          </Row>
        </Space>
      </Modal>

      <Modal
    
        open={isEditModal}
        wrapClassName="service-edit-modal"
        width={"100%"}
         onCancel={handleEditCancel}
         onOk={handleEditCancel}
        footer={null}
        destroyOnClose={true}
      >
        <LaunchMicrosite orderContext={orderContext} SOid={SOid} />
      </Modal>
      <Table
        pagination={{ pageSize: 5 }}
        dataSource={datasource}
        columns={columns}
        loading={loading}
      ></Table>
    </>
  );
}

export default ServiceOrderDetailsView;
