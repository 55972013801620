import {v4 as uuid} from 'uuid';

export class ServiceOrderItemRef{
  //A string. Identifier of the line item.
  itemId=null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type"="ServiceOrderItemRef";
  //A string. When sub-classing, this defines the super-class.
  "@baseType"=null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation"=null;
  //A string. The actual type of the target instance when needed for disambiguation.
  "@referredType"="ServiceOrderItem";
  //An uri (Uri). Link to the order to which this item belongs to.
  serviceOrderHref=null;
  //A string. Identifier of the order that this item belongs to.
  serviceOrderId=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("ServiceOrderItemRef unknown property",e);
      });
  }
}

export class ServiceOrderItemRelationship{
  //A string. When sub-classing, this defines the super-class.
  "@baseType"=null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation"=null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type"="ServiceOrderItemRelationship";
  //A service order item reference (ServiceOrderItemRef). A service order item in relationship with this order item.
  orderItem=null;
  //A string. The type of related order item, can be: dependency if the order item needs to be not started until another order item is complete.
  relationshipType=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("ServiceOrderItemRelationship unknown property",e);
      });
  }

}

export class ServiceOrderItem{
  //A string. When sub-classing, this defines the super-class.  
  "@baseType"=null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation"=null;
  //A string. When sub-classing, this defines the sub-class Extensible name
  "@type"="ServiceOrderItem"
  //An order item action type (OrderItemActionType). The action to be carried out on the Service. Can be: add, modify, delete, noChange.
  action=null;
  //An appointment reference (AppointmentRef). An appointment that was set up with a related party for this order item.
  appointment=null;
  //A list of service order item error messages (ServiceOrderItemErrorMessage [*]). the error(s) cause an order item status change.
  errorMessage=null;
  //A string. Identifier of the individual line item.
  id=null;
  //An integer. Quantity ordered.
  quantity=1;
  //A service ref or value (ServiceRefOrValue). The Service to be acted on by the order item.
  service=null;
  //A list of service order items (ServiceOrderItem [*]). A list of order items embedded to this order item.
  serviceOrderItem=null;
  //A list of service order item relationships (ServiceOrderItemRelationship [*]). A list of order items related to this order item.
  serviceOrderItemRelationship=null;
  //A service order item state type (ServiceOrderItemStateType). State of the order item: described in the state machine diagram. This is the requested state.
  state=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("ServiceOrderItem unknown property",e);
      });
    if(!this.id)  if(!this.id) this.id="local-id-"+uuid();
  }

}

export class ServiceOrder{
  //A string. Hyperlink to access the order.
  href=null;
  //A string. ID created on repository side.
  id=null;
  //A string. When sub-classing, this defines the super-class.
  "@baseType"=null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation"=null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type"="ServiceOrder"
  //A date time (DateTime). Date when the order is cancelled. This is used when order is cancelled.
  cancellationDate=null;
  //A string. Reason why the order is cancelled. This is used when order is cancelled.
  cancellationReason=null;
  //A string. Used to categorize the order, useful for the OM system, such as: Broadband, TVOption.
  category=null;
  //A date time (DateTime). Effective delivery date amended by the provider.
  completionDate=null;
  //A string. A free-text description of the service order.
  description=null;
  //A list of service order error messages (ServiceOrderErrorMessage [*]). the error(s) cause an order status change.
  errorMessage=null;
  //A date time (DateTime). Expected delivery date amended by the provider.
  expectedCompletionDate=null;
  //A string. ID given by the consumer to facilitate searches.
  externalId=null;
  //A list of external references (ExternalReference [*]). External reference of the individual or reference in other system.
  externalReference=null;
  //A list of service order jeopardy alerts (ServiceOrderJeopardyAlert [*]). A list of jeopardy alerts related to this order.
  jeopardyAlert=null;
  //A list of service order milestones (ServiceOrderMilestone [*]). A list of milestones related to this order.
  milestone=null;
  //A list of notes (Note [*]). Extra-information about the order; e.g. useful to add extra delivery information that could be useful for a human process.
  note=null;
  //A string. Contact attached to the order to send back information regarding this order.
  notificationContact=null;
  //A date time (DateTime).
  orderDate=null;
  //A list of service order relationships (ServiceOrderRelationship [*]). A list of service orders related to this order (e.g. prerequisite, dependent on).
  orderRelationship=null;
  //A string. Can be used by consumers to prioritize orders in a Service Order Management system.
  priority=null;
  //A list of related parties (RelatedParty [*]). A list of parties which are involved in this order and the role they are playing.
  relatedParty=null;
  //A date time (DateTime). Requested delivery date from the requestors perspective.
  requestedCompletionDate=null;
  //A date time (DateTime). Order start date wished by the requestor.
  requestedStartDate=null;
  //A list of service order items (ServiceOrderItem [*]). A list of service order items to be processed by this order.
  serviceOrderItem=null;
  //A date time (DateTime). Date when the order was started for processing.
  startDate=null;
  //A service order state type (ServiceOrderStateType). State of the order: described in the state-machine diagram.
  state=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("ServiceOrder unknown property",e);
      });
  }
}

export class CancelServiceOrder{

  cancellationReason = null;
  serviceOrder = null;
  "@type" = "CancelServiceOrder";

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("ServiceOrder unknown property",e);
      });
  }

}

//shorthand constructor for ServiceOrderItemRelationship structure
export function soiRelationship(soi,relationshipType){
  const soiRef=new ServiceOrderItemRef({
    itemId:soi.id
  })

  return new ServiceOrderItemRelationship({
    orderItem:soiRef,
    relationshipType:relationshipType
  });
}