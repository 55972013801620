import moment from "moment";

export const getDateAndTime = (appointment) => {
  if (appointment && appointment?.validFor) {
    let startDateTime = moment
      .utc(appointment?.validFor?.startDateTime)
      ?.local();
    let endDateTime = moment.utc(appointment?.validFor?.endDateTime)?.local();
    let appDate = startDateTime?.format("DD/MM/YYYY");
    let time = `${startDateTime?.format("LT")} - ${endDateTime?.format("LT")}`;
    return `${appDate} : ${time} `;
  } else {
    return "Not Booked";
  }
};
