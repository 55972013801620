import ServiceCatalog from "../service-catalog/ServiceCatalog";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";

import {
  chrspecCommonSet,
  chrspecCommonSetWithFtthNo,
  chrspecFieldServiceAddressRole,
  chrspecProductType,
} from "../catalogs/CharacteristicsCatalog";
import { GENERIC_EE, GENERIC_ER } from "../common/Constants";

function equipmentInstallation(name, id, category ) {
  return new ServiceSpecification({
    id: id,
    name: name,
    category: category?category:"Equipment Installation",
    specCharacteristic: [
      ...chrspecCommonSet(name),
      chrspecFieldServiceAddressRole,
      chrspecProductType,
    ],
    entitySpecRelationship: [esrWorkSiteAddress, esrWorkSiteContact],
  });
}

function equipmentInstallationFtthNo(name, id, category ) {
  return new ServiceSpecification({
    id: id,
    name: name,
    category: category?category:"Equipment Installation",
    specCharacteristic: [
      ...chrspecCommonSetWithFtthNo(name),
      chrspecFieldServiceAddressRole,
      chrspecProductType,
    ],
    entitySpecRelationship: [esrWorkSiteAddress, esrWorkSiteContact],
  });
}

function copperInstallation(name, id, category) {
  return new ServiceSpecification({
    id: id,
    name: name,
    category: category?category:"Copper Installation",
    specCharacteristic: [
      ...chrspecCommonSet(name),
      chrspecFieldServiceAddressRole,
      chrspecProductType,
    ],
    entitySpecRelationship: [esrWorkSiteAddress, esrWorkSiteContact],
  });
}


function copperAdvanceInstallation(name, id, category) {
  return new ServiceSpecification({
    id: id,
    name: name,
    category: category?category:"Copper Installation",
    specCharacteristic: [
      ...chrspecCommonSet(name),
      chrspecFieldServiceAddressRole,
      chrspecProductType,
    ],
    entitySpecRelationship: [esrWorkSiteAddress, esrWorkSiteContact],
  });
}

//Equipment installation
export const svcspecInstallationElite = equipmentInstallation(
  "eLite Equipment Installation",
  "com.singtel.fs.installation.elite"
);



//Generic Service Specifications
export const svcspecGenericEquipmentInstallation = equipmentInstallation(
  GENERIC_EE,
  "serviceCharacteristic:com.singtel.fs.installation.generic.equipment.installation",
);

export const svcspecGenericEquipmentInstallationFtthNo = equipmentInstallationFtthNo(
  GENERIC_EE,
  "serviceCharacteristic:com.singtel.fs.installation.generic.equipment.installation",
);

export const svcspecGenericEqupmentRecovery = equipmentInstallation(
  GENERIC_ER,
  "com.singtel.fs.generic,recovery"
);






export const svcspecInstallationMetroE = equipmentInstallation(
  "Metro E Equipment Installation",
  "com.singtel.fs.installation.metroe"
);
export const svcspecInstallationeVolve = equipmentInstallationFtthNo(
  "Singnet Evolve Equipment Installation",
  "com.singtel.fs.installation.singnet-evolve"
);

export const svcspecInstallationeWiFiMesh = equipmentInstallation(
  "Wifi Mesh Equipment Installation",
  "com.singtel.fs.installation.wifi-mesh"
);
export const svcspecInstallationeSingtelTV = equipmentInstallation(
  "Singtel TV Equipment Installation",
  "com.singtel.fs.installation.tv"
);

export const svcspecInstallationeSingtelTVWithFtth = equipmentInstallationFtthNo(
  "Singtel TV Equipment Installation",
  "com.singtel.fs.installation.tv"
);

export const svcspecInstallationDiginet = equipmentInstallation(
  "Diginet Equipment Installation",
  "com.singtel.fs.installation.diginet"
);

//Copper Installation
export const svcspecInstallationeALLC = copperInstallation(
  "ALLC Copper Installation",
  "com.singtel.fs.installation.allc"
);
export const svcspecInstallationeBizDelCopper = copperInstallation(
  "BizDel Copper Installation",
  "com.singtel.fs.installation.bizdel"
);
//Equipment Recovery
export const svcspecEqupmentRecovery = equipmentInstallation(
  "Equipment Recovery",
  "com.singtel.fs.recovery"
);

export const svcspecBAccessEquipmentInstallation = equipmentInstallationFtthNo(
  "B-Access Equipment Installation",
  "com.singtel.fs.installation.b-access"
);
export const svcspecBizvoiceEquipmentInstallation = equipmentInstallation(
  "Bizvoice Equipment Installation",
  "com.singtel.fs.installation.bizvoice"
);

export const svcspecBizvoiceEquipmentInstallationFtth = equipmentInstallationFtthNo(
  "Bizvoice Equipment Installation",
  "com.singtel.fs.installation.bizvoice"
);


export const svcspecBusinessDigitalLineEquipmentInstallationFtth = equipmentInstallationFtthNo(
  "Business Digital Line Equipment Installation",
  "com.singtel.fs.installation.BusinessDigitalLine"
);

export const svcspecSIPTrunkingEquipmentInstallationFtth = equipmentInstallationFtthNo(
  "SIP Trunking Equipment Installation",
  "com.singtel.fs.installation.BusinessDigitalLine.ftth"
);

export const svcspecSIPTrunkingEquipmentInstallation = equipmentInstallation(
  "SIP Trunking Equipment Installation",
  "com.singtel.fs.installation.BusinessDigitalLine"
);




export const svcspecCopperInstallation = equipmentInstallation(
  "Copper Installation",
  "com.singtel.fs.installation.copper"
);
export const svcspecDarkFibreEquipmentInstallation = equipmentInstallation(
  "Dark Fibre Equipment Installation",
  "com.singtel.fs.installation.darkfibre"
);
export const svcspecDifferentChannelListEquipmentInstallation =
  equipmentInstallation(
    "Different Channel List Equipment Installation",
    "com.singtel.fs.installation.differentchannellist"
  );
export const svcspecDiginetEquipmentInstallation = equipmentInstallation(
  "Diginet Equipment Installation",
  "com.singtel.fs.installation.diginet"
);
export const svcspecEquipmentInstallation = equipmentInstallation(
  "Equipment Installation",
  "com.singtel.fs.installation.equipment"
);
export const svcspecEthernetlinkCEEquipmentInstallation = equipmentInstallation(
  "Ethernetlink (CE) Equipment Installation",
  "com.singtel.fs.installation.ethernetlink(ce)"
);
export const svcspecEvolveplusEquipmentInstallation = equipmentInstallation(
  "Evolveplus Equipment Installation",
  "com.singtel.fs.installation.evolveplus"
);
export const svcspecFibreInstallation = equipmentInstallation(
  "Fibre Installation",
  "com.singtel.fs.installation.fibre"
);
export const svcspecFTTHEquipmentInstallation = equipmentInstallation(
  "FTTH Equipment Installation",
  "com.singtel.fs.installation.ftth"
);
export const svcspecGigawaveEquipmentInstallation = equipmentInstallation(
  "Gigawave Equipment Installation",
  "com.singtel.fs.installation.gigawave"
);

export const svcspecIEResourceTopup = equipmentInstallation(
  "IE Resource Topup",
  "com.singtel.fs.installation.ieresourcetopup"
);
export const svcspecInterCabPatching = equipmentInstallation(
  "Inter-cab Patching",
  "com.singtel.fs.installation.inter-cabpatching"
);
export const svcspecISDNEquipmentInstallation = equipmentInstallation(
  "ISDN Equipment Installation",
  "com.singtel.fs.installation.isdn"
);
export const svcspecLastMilePatching = equipmentInstallation(
  "Last-mile Patching",
  "com.singtel.fs.installation.last-milepatching"
);
export const svcspecMegPOPeAccessEquipmentInstallation = equipmentInstallation(
  "Meg@POP eAccess Equipment Installation",
  "com.singtel.fs.installation.meg@popeaccess"
);
export const svcspecMegPOPEthernetLinkEquipmentInstallation =
  equipmentInstallation(
    "Meg@POP EthernetLink Equipment Installation",
    "com.singtel.fs.installation.meg@popethernetlink"
  );
export const svcspecMegPOPEVPLEquipmentInstallation = equipmentInstallation(
  "Meg@POP EVPL Equipment Installation",
  "com.singtel.fs.installation.meg@popevpl"
);
export const svcspecMegPOPEVPNEquipmentInstallation = equipmentInstallation(
  "Meg@POP EVPN Equipment Installation",
  "com.singtel.fs.installation.meg@popevpn"
);
export const svcspecMegapopEliteEquipmentInstallation = equipmentInstallation(
  "Megapop Elite Equipment Installation",
  "com.singtel.fs.installation.megapopelite"
);
export const svcspecPhoneNetEquipmentInstallation = copperInstallation(
  "PhoneNet Equipment Installation",
  "com.singtel.fs.installation.phonenet"
);
export const svcspecSingnetBizVoiceEquipmentInstallation =
  equipmentInstallation(
    "Singnet BizVoice Equipment Installation",
    "com.singtel.fs.installation.singnetbizvoice"
  );
  export const svcspecSingnetBizVoiceCopperInstallation =
  equipmentInstallation(
    "BizVoice Copper Installation",
    "com.singtel.fs.installation.singnetbizvoicecopper"
  );
export const svcspecSingneteAccessEquipmentInstallation = equipmentInstallation(
  "Singnet eAccess Equipment Installation",
  "com.singtel.fs.installation.singneteaccess"
);
export const svcspecSingneteLiteEquipmentInstallation = equipmentInstallation(
  "Singnet eLite Equipment Installation",
  "com.singtel.fs.installation.singnetelite"
);
export const svcspecSingnetEliteplusEquipmentInstallation =
  equipmentInstallation(
    "Singnet Eliteplus Equipment Installation",
    "com.singtel.fs.installation.singneteliteplus"
  );
export const svcspecSingnetEthernetEquipmentInstallation =
  equipmentInstallation(
    "Singnet Ethernet Equipment Installation",
    "com.singtel.fs.installation.singnetethernet"
  );
export const svcspecSingnetGigawaveLiteEquipmentInstallation =
  equipmentInstallation(
    "Singnet GigawaveLite Equipment Installation",
    "com.singtel.fs.installation.singnetgigawavelite"
  );
export const svcspecSingnetMetroEEquipmentInstallation = equipmentInstallation(
  "Singnet Metro E Equipment Installation",
  "com.singtel.fs.installation.singnetmetroe"
);
export const svcspecSingnetWirelessEquipmentInstallation =
  equipmentInstallation(
    "Singnet Wireless Equipment Installation",
    "com.singtel.fs.installation.singnetwireless"
  );
export const svcspecSingtelFibreGEFibreEquipmentInstallation =
  equipmentInstallation(
    "Singtel Fibre (GE Fibre) Equipment Installation",
    "com.singtel.fs.installation.singtelfibre(gefibre)"
  );
export const svcspecSingtelFTTHEquipmentInstallation = equipmentInstallation(
  "Singtel FTTH Equipment Installation",
  "com.singtel.fs.installation.singtelftth"
);
export const svcspecSingtelTVEquipmentInstallation = equipmentInstallation(
  "Singtel TV Equipment Installation",
  "com.singtel.fs.installation.singteltv"
);

export const svcspecSiteSurvey = equipmentInstallation(
  "Site Survey",
  "com.singtel.fs.installation.sitesurvey"
);

export const svcspecCopperAdvanceInstallation = copperAdvanceInstallation(
  "Copper Advance Wiring Installation",
  "com.singtel.fs.other.copperadvancewiring",
  "Copper Advance Installation"
);


export const catalogFieldServices = new ServiceCatalog()
  .category("Field Services")
  .serviceSpecs([
    svcspecInstallationElite,
    svcspecInstallationeVolve,
    svcspecInstallationeWiFiMesh,
    svcspecInstallationeALLC,
    svcspecInstallationeBizDelCopper,
    svcspecEqupmentRecovery,
    svcspecInstallationMetroE,
    svcspecBAccessEquipmentInstallation,
    svcspecBizvoiceEquipmentInstallation,
    svcspecCopperInstallation,
    svcspecDarkFibreEquipmentInstallation,
    svcspecDifferentChannelListEquipmentInstallation,
    svcspecDiginetEquipmentInstallation,
    svcspecEquipmentInstallation,
    svcspecEthernetlinkCEEquipmentInstallation,
    svcspecEvolveplusEquipmentInstallation,
    svcspecFibreInstallation,
    svcspecFTTHEquipmentInstallation,
    //svcspecGigawaveEquipmentInstallation,
    svcspecIEResourceTopup,
    svcspecInterCabPatching,
    svcspecISDNEquipmentInstallation,
    svcspecLastMilePatching,
    svcspecMegPOPeAccessEquipmentInstallation,
    svcspecMegPOPEthernetLinkEquipmentInstallation,
    svcspecMegPOPEVPLEquipmentInstallation,
    svcspecMegPOPEVPNEquipmentInstallation,
    svcspecMegapopEliteEquipmentInstallation,
    svcspecPhoneNetEquipmentInstallation,
    svcspecSingnetBizVoiceEquipmentInstallation,
    svcspecSingneteAccessEquipmentInstallation,
    svcspecSingneteLiteEquipmentInstallation,
    svcspecSingnetEliteplusEquipmentInstallation,
    svcspecSingnetEthernetEquipmentInstallation,
    //svcspecSingnetGigawaveLiteEquipmentInstallation,
    svcspecSingnetMetroEEquipmentInstallation,
    svcspecSingnetWirelessEquipmentInstallation,
    svcspecSingtelFibreGEFibreEquipmentInstallation,
    svcspecSingtelFTTHEquipmentInstallation,
    svcspecSingtelTVEquipmentInstallation,
    svcspecSiteSurvey,
  ])
  .endCategory();

/*
ALLC - Copper Installation	                  ALLC - Copper Installation
B-Access Equipment Installation	
BizDel - Copper Installation	                BizDel - Copper Installation
Bizvoice Equipment Installation	
Copper Installation	Copper Installation
Dark Fibre Equipment Installation	            Configuration of checklists (service tasks) and resource skillset specific Dark Fibre Equipment Installation.
Different Channel List Equipment Installation	Configuration of checklists (service tasks) and resource skillset specific Different Channel List Equipment Installation.
Diginet Equipment Installation	              Configuration of checklists (service tasks) and resource skillset specific Diginet Equipment Installation.
+ eLite Equipment Installation	                Configuration of checklists (service tasks) and resource skillset specific Elite Equipment Installation.
Equipment Installation	
+Equipment Recovery	
Ethernetlink (CE) Equipment Installation	    Configuration of checklists (service tasks) and resource skillset specific Ethernetlink (CE) Equipment Installation.
Evolveplus Equipment Installation	            Configuration of checklists (service tasks) and resource skillset specific Evolveplus (Broadband plus) Equipment Installation.
Fibre Installation	                          Configuration of checklists (service tasks) and resource skillset specific to In-building field job.
FTTH Equipment Installation	
Gigawave Equipment Installation	
IE Resource Topup	IE Resource Topup
Inter-cab Patching	                          Configuration of checklists (service tasks) and resource skillset specific to Inter-cab Patching field job.
ISDN Equipment Installation	                  Configuration of checklists (service tasks) and resource skillset specific ISDN Equipment Installation.
Last-mile Patching	                          Configuration of checklists (service tasks) and resource skillset specific to Last-mile Patching field job.
Meg@POP eAccess Equipment Installation	      Configuration of checklists (service tasks) and resource skillset specific Meg@POP eAccess Equipment Installation.
Meg@POP EthernetLink Equipment Installation	  Configuration of checklists (service tasks) and resource skillset specific Meg@POP EthernetLink Equipment Installation.
Meg@POP EVPL Equipment Installation	          Configuration of checklists (service tasks) and resource skillset specific Meg@POP EVPL Equipment Installation.
Meg@POP EVPN Equipment Installation	          Configuration of checklists (service tasks) and resource skillset specific Meg@POP EVPN Equipment Installation.
Megapop Elite Equipment Installation	        Configuration of checklists (service tasks) and resource skillset specific Megapop Elite Equipment Installation.
+ Metro E Equipment Installation	              Configuration of checklists (service tasks) and resource skillset specific Metro E Equipment Installation.
PhoneNet Equipment Installation	
Singnet BizVoice Equipment Installation	      Configuration of checklists (service tasks) and resource skillset specific BizVoice Equipment Installation.
Singnet eAccess Equipment Installation	      Configuration of checklists (service tasks) and resource skillset specific Singnet eAccess Equipment Installation.
Singnet eLite Equipment Installation	
Singnet Eliteplus Equipment Installation	    Configuration of checklists (service tasks) and resource skillset specific Singnet Eliteplus Equipment Installation.
Singnet Ethernet Equipment Installation	      Configuration of checklists (service tasks) and resource skillset specific Singnet Ethernetlink Equipment Installation.
+ Singnet Evolve Equipment Installation	        Configuration of checklists (service tasks) and resource skillset specific Singnet Evolve Equipment Installation.
Singnet GigawaveLite Equipment Installation	  Configuration of checklists (service tasks) and resource skillset specific Gigawave Equipment Installation.
Singnet Metro E Equipment Installation	      Configuration of checklists (service tasks) and resource skillset specific Singnet Metro E Equipment Installation.
Singnet Wireless Equipment Installation	          Configuration of checklists (service tasks) and resource skillset specific Singnet Wireless (4G) Equipment Installation.
Singtel Fibre (GE Fibre) Equipment Installation	  Configuration of checklists (service tasks) and resource skillset specific NLT SME FTTH (ST on CPE only) Equipment Installation.
Singtel FTTH Equipment Installation	              Configuration of checklists (service tasks) and resource skillset specific Singtel SME FTTH Equipment Installation.
Singtel TV Equipment Installation	             Configuration of checklists (service tasks) and resource skillset specific Singtel TV (Upgrade/New) Equipment Installation.
Site Survey	                                   Configuration of checklists (service tasks) and resource skillset specific to site survey field job for all products.
+Wifi Mesh Equipment Installation	              Configuration of checklists (service tasks) and resource skillset specific Wifi Mesh Equipment Installation.
*/
