import ServiceCatalog from '../service-catalog/ServiceCatalog'
import {svcspecBizvoiceEquipmentInstallation, svcspecBizvoiceEquipmentInstallationFtth, svcspecGenericEquipmentInstallationFtthNo, svcspecSingnetBizVoiceCopperInstallation} from '../catalogs/CatalogFieldServices'
import {chrspec} from '../service-catalog/CharacteristicSpecification'
import {chrspecFtthNo, chrspecOrderTypeProvide,chrspecPremiseWiring} from './CharacteristicsCatalog'
import { svcspecC2P } from '../schema/schemaC2P'
import {svcspecFieldService} from '../schema/schemaFieldService'
import { svcspecCableConnectivityC2PFtth, svcspecGenericCableConnectivity } from './CatalogCableConnectivity'

const chrspecProductTypeBizVoice=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"BIZ VOICE - BIZ VOICE"
});

function bizVoiceTemplateCopper(name){
  const spec= svcspecFieldService({
    name:name,
    installationServiceSpec:svcspecSingnetBizVoiceCopperInstallation,
    characteristics:[chrspecProductTypeBizVoice,chrspecPremiseWiring]
  });

// const i=spec.specCharacteristic.findIndex(e=>e.name==="Order Type");
// if (i>-1) spec.specCharacteristic[i]=chrspecOrderTypeProvide;
return spec;
};

function bizVoiceTemplateFiber(name){
  const spec= svcspecC2P({
                      name:name,
                      cableSpec: svcspecCableConnectivityC2PFtth,
                      installationServiceSpec:svcspecBizvoiceEquipmentInstallationFtth,
                      characteristics:[chrspecProductTypeBizVoice, chrspecFtthNo],
                      genericInstallations: new Map()
                      .set("IPhoneNet Generic", svcspecGenericEquipmentInstallationFtthNo)
                      .set("SingNet / Elite / Ethernetlink Generic", svcspecGenericEquipmentInstallationFtthNo),

                      genericConnectivity: new Map()
                      .set("SingNet / Elite / Ethernetlink Generic", svcspecGenericCableConnectivity)
                      .set("IPhoneNet Generic", svcspecGenericCableConnectivity),
                      BIZVOICE: "IPhoneNet Generic",
                      onlyCPE: true
                    });

  // const i=spec.specCharacteristic.findIndex(e=>e.name==="Order Type");
  // if (i>-1) spec.specCharacteristic[i]=chrspecOrderTypeProvide;
  return spec;
};

export const CatalogBizVoice=((new ServiceCatalog())
  .category("Biz Voice")
    .category("Copper")
      .serviceSpecs([
        bizVoiceTemplateCopper("Biz Voice Copper"),
        bizVoiceTemplateCopper("BizVoice UC add-on Copper"),
        bizVoiceTemplateCopper("BizVoice UC Standalone Copper"),
        bizVoiceTemplateCopper("Starter VAS pack 1 Copper"),
        bizVoiceTemplateCopper("Starter VAS pack 2 Copper"),
        bizVoiceTemplateCopper("Starter VAS pack 3 Copper")
       ])
       .endCategory()
       // Disabled in Prod
       .category("Fiber")
      .serviceSpecs([
        bizVoiceTemplateFiber("Biz Voice Fiber"),
        bizVoiceTemplateFiber("BizVoice UC add-on Fiber"),
        bizVoiceTemplateFiber("BizVoice UC Standalone Fiber"),
        bizVoiceTemplateFiber("Starter VAS pack 1 Fiber "),
        bizVoiceTemplateFiber("Starter VAS pack 2 Fiber"),
        bizVoiceTemplateFiber("Starter VAS pack 3 Fiber")
       ])
       .endCategory()
  .endCategory());
