import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecC2P } from "../schema/schemaC2P";
import { chrspec } from "../service-catalog/CharacteristicSpecification";
import { svcspecGenericEquipmentInstallation, svcspecGenericEquipmentInstallationFtthNo, svcspecSIPTrunkingEquipmentInstallation, svcspecSIPTrunkingEquipmentInstallationFtth } from "./CatalogFieldServices";
import { svcspecCableConnectivityC2P, svcspecCableConnectivityC2PFtth, svcspecGenericCableConnectivity, svcspecGenericFTTHCableConnectivity } from "./CatalogCableConnectivity";
import { SIP_Generic } from "../common/Constants";
import { chrspecFtthNo } from "./CharacteristicsCatalog";

const chrspecProductTypeSIPTrunking = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "SIP Trunking",
});

const chrspecProductTypeSIPTrunkingWholesale = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "SIP Trunking Wholesale",
});

function SIPTrunkingTemplate(name) {
  return svcspecC2P({
    name: name,
    cableSpec: svcspecCableConnectivityC2P,
    installationServiceSpec:svcspecSIPTrunkingEquipmentInstallation,
    characteristics: [chrspecProductTypeSIPTrunking],
    genericInstallations: new Map().set(SIP_Generic, svcspecGenericEquipmentInstallation),
    genericConnectivity: new Map().set(SIP_Generic, svcspecGenericCableConnectivity),
    onlyCPE: true
  });
}


function SIPTrunkingTemplateWithFtth(name) {
  return svcspecC2P({
    name: name,
    cableSpec: svcspecCableConnectivityC2PFtth,
    installationServiceSpec:svcspecSIPTrunkingEquipmentInstallationFtth,
    characteristics: [chrspecProductTypeSIPTrunking, chrspecFtthNo],
    genericInstallations: new Map().set(SIP_Generic, svcspecGenericEquipmentInstallationFtthNo),
    genericConnectivity: new Map().set(SIP_Generic, svcspecGenericFTTHCableConnectivity),
    onlyCPE: true
  });
}


function SIPTrunkingWholesaleTemplate(name) {
  return svcspecC2P({
    name: name,
    installationServiceSpec:svcspecSIPTrunkingEquipmentInstallation,
    cableSpec: svcspecCableConnectivityC2P,
    characteristics: [chrspecProductTypeSIPTrunkingWholesale],
    genericInstallations: new Map().set(SIP_Generic, svcspecGenericEquipmentInstallation),
    genericConnectivity: new Map().set(SIP_Generic, svcspecGenericCableConnectivity),
    onlyCPE: true
  });
}



function SIPTrunkingWholesaleTemplateWithFtth(name) {
  return svcspecC2P({
    name: name,
    installationServiceSpec:svcspecSIPTrunkingEquipmentInstallationFtth,
    cableSpec: svcspecCableConnectivityC2PFtth,
    characteristics: [chrspecProductTypeSIPTrunkingWholesale, chrspecFtthNo],
    genericInstallations: new Map().set(SIP_Generic, svcspecGenericEquipmentInstallationFtthNo),
    genericConnectivity: new Map().set(SIP_Generic, svcspecGenericFTTHCableConnectivity),
  });
}


export const CatalogVoice = new ServiceCatalog()
  .category("SIP")
  .category("SIP Trunking")
  .serviceSpecs([
    SIPTrunkingTemplate("(CE-Main) EthernetLink for SIP Voice"),
    SIPTrunkingTemplate("(CE-Main) EthernetLink with ED for SIP Voice"),
    SIPTrunkingTemplate(
      "(CE-Main) EthernetLink with ED for SIP Voice - Auto Failover (Dual Switch)"
    ),
    SIPTrunkingTemplate("(Main) EthernetLink for SIP Voice"),
    SIPTrunkingTemplate("(Main) eLite for SIP Voice"),
    SIPTrunkingTemplateWithFtth("(Main) evolve for SIP voice"),
    
  ])
  .endCategory()



  .category("SIP Trunking Wholesale")
  .serviceSpecs([
    SIPTrunkingWholesaleTemplate("(CE-Main) EthernetLink for SIP Voice"),
    SIPTrunkingWholesaleTemplate(
      "(CE-Main) EthernetLink with ED for SIP Voice"
    ),
    SIPTrunkingWholesaleTemplate(
      "(CE-Main) EthernetLink with ED for SIP Voice - Auto Failover (Dual Switch)"
    ),
    SIPTrunkingWholesaleTemplate("(Main) eLite for SIP Voice"),
    SIPTrunkingWholesaleTemplate("(Main) EthernetLink for SIP Voice"),
    SIPTrunkingWholesaleTemplateWithFtth("(Main) evolve for SIP voice"),
  ])
  .endCategory()
  .endCategory();
