import React, { useState, useRef,useEffect } from 'react';
import {Form,Input,Divider,Row,Col,Button} from 'antd';
import {CheckCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';
import CustomSelectBase from '../common/CustomSelectBase';
import {Individual} from './Party';

export default function ContactInput(props){
  const {Item}=Form;
  const {value,edit,onChange,phonebook:phoneBook,...otherProps}=props;
  const [selectOptions, setSelectOptions] = useState(phoneBook.asLabeledValues());
  const [selectValue, setSelectValue] = useState(value?.name());
  const [open, setOpen] = useState(false);
  const [dropdownMode, setDropdownMode] = useState("select");
  const focusInputRef=useRef(null);
  

  function cleanInput(inputVal,toLower=false){
    if (!inputVal) return undefined;
    return (toLower)?inputVal.trim().toLowerCase():inputVal.trim();
  }

  function onSelectChange(value, option) {
    console.debug("ContactInput:onChange", value, option);
    setSelectValue(value);
    if (onChange) onChange(phoneBook.get(option.label));
  }

  function onSelectFocus(){
    if (dropdownMode!=="contact" && !selectOptions?.length) setDropdownMode("contact");
  }
  
  function onClickManualEditMode(e) {
    console.debug("ContactInput:onClickManualEditMode", e);
    setDropdownMode("contact");
  }

  function onFormCancel() {
    setDropdownMode("select");
  }

  function onFormFinish(values) {

    console.debug("ContactInput:onFormFinish", values);
    const newParty=new Individual({
      givenName:cleanInput(values?.["First Name"]),
      familyName:cleanInput(values?.["Last Name"]),
      emailAddress:cleanInput(values?.["Email"],true),
      phoneNumber:cleanInput(values?.["Phone"])
    });
    phoneBook.setPersistent(newParty);
    setSelectOptions(phoneBook.asLabeledValues());
    setOpen(false);
    onSelectChange(newParty.name(),{label:newParty.name(),value:newParty.name()});
  }



  useEffect(() => {
    console.log("ContactInput:Effect",focusInputRef);
    if (dropdownMode!=="select" && focusInputRef.current) focusInputRef.current.focus();
  }, [dropdownMode]);

  function renderForm(option, mode) {
    const required={rules:[{required:true}]};

    console.debug("ContactInput:renderForm",option,mode);
    if (mode==="select") return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <span>Select contact from the list or <a onClick={onClickManualEditMode}>click here to enter contact manually</a></span>
      );

    return (
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          //address={localAddressBook.get(option?.label)}
          //mode={mode}
          onFinish={onFormFinish}
          onCancel={onFormCancel}
        >
            <Item label="First Name" name="First Name" {...required}>
              <Input placeholder="John" ref={focusInputRef}/>
            </Item>
            <Item label="Last Name" name="Last Name" {...required}>
              <Input placeholder="Doe"/>
            </Item>
            <Item label="Email" name="Email" {...required}>
              <Input placeholder="john.doe@singtel.com"/>
            </Item>
            <Item label="Phone" name="Phone">
              <Input placeholder="98765432"/>
            </Item>
            <Divider className="compact-divider"/>
            <Row justify="end">
              <Col span={6}>
                <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined/>}>Select</Button>
              </Col>
              <Col span={6}>
                <Button type="ghost" htmlType="reset" icon={<CloseCircleOutlined/>} onClick={onFormCancel}>Cancel</Button>
              </Col>
            </Row>
        </Form>
      );
  }

  return (edit)?(
    <CustomSelectBase
      {...otherProps}
      className="form-field-long" 
      options={selectOptions}
      value={selectValue}
      mode={dropdownMode}
      onFocus={onSelectFocus}
      onDropdownModeChange={setDropdownMode}
      open={open}
      onDropdownVisibleChange={setOpen}
      dropdownMatchSelectWidth={450}
      onChange={onSelectChange}
      footerRender={renderForm}
      placeholder="Select or enter contact details"
    />):
    (
      <div className="form-field-long" >{props.value?.name()  ?? "N/A"}</div>
    )
}