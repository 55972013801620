import { LogoutOutlined } from "@ant-design/icons";
import { aad } from "../msal/auth";

export function UserPanel(props) {
  const { userName, email } = props;

  const logout = () => {
    aad.logoutRedirect();
  };

  return (
    <div
      style={{
        width: "250px",
        marginBottom: "-1em",
      }}
    >
      <p>
        User: {userName} <LogoutOutlined onClick={() => logout()} />
      </p>
      <p>Email: {email}</p>
    </div>
  );
}
