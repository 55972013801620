import ServiceCategory from "./ServiceCategory";

class CategoryBuilder{
  catalog=null;
  parent=null;
  thisCategory=null;

  categories=[];
  specifications=[];
  
  constructor(catalog,parent,name,isRoot){
    this.catalog=catalog;
    this.parent=parent;
    this.thisCategory=new ServiceCategory({
      name:name,
      isRoot:isRoot
    })

    this.specifications.forEach((s)=>{
      s.onAddToCategory(this.thisCategory.name,parent?.thisCategory.name)
    });
  }

  getId=()=>this.thisCategory?.id;

  import(catBuider){
    this.specifications.push(...catBuider.specifications);
    this.categories.push(...catBuider.categories,catBuider.thisCategory);
    this.thisCategory.category.push(catBuider.getId());
    return this;
  }

  category=(name)=>new CategoryBuilder(this.catalog,this,name,false);
  endCategory=()=>this.parent.import(this);

  serviceSpecs(svc){
    this.specifications=[...svc];
    this.thisCategory.serviceCandidate=svc.map(s=>s.id);
    return this;
  }
}

export default class ServiceCatalog{
  categories=[];
  specifications=[];
  navigationTree=[];

  category=(name)=>new CategoryBuilder(this,this,name,true);

  import(catalog){
    console.debug("ServiceCatalog:import",this,catalog);
    this.specifications.push(...catalog.specifications);
    this.categories.push(...catalog.categories);
    if (catalog?.thisCategory) this.categories.push(catalog.thisCategory);
    return this;
  }

  getServiceSpec=(id)=>this.specifications.find(spec=>spec.id===id);
  getServiceSpecByName=(name)=>this.specifications.find(spec=>spec.name===name);
  getServiceSpecByInstance=(instance)=>(instance?.serviceSpecification?.name)?this.specifications.find(spec=>spec.name===instance?.serviceSpecification?.name):undefined;


  getCategory=(id)=>this.categories.find(cat=>cat.id===id);
  
 
   expandCategory(cat){
     let res={
       value: cat.id,
       label: cat.name,
       children: []
     };
    
     const serviceSpecChildren=cat.serviceCandidate?.map(sc_id=>({value: sc_id, label: this.getServiceSpec(sc_id).name }));
     const categoryChildren=cat.category?.map(cat=>this.expandCategory(this.getCategory(cat)));
 
     if (serviceSpecChildren) res.children=res.children.concat(serviceSpecChildren);
     if (categoryChildren) res.children=res.children.concat(categoryChildren);
     return res;
   }
 
   buildNavigationTree(){
 
     const roots=this.categories.filter(cat=>cat?.isRoot);
     const nav=roots.map((cat,index)=>{
      console.debug(index, " index -->  ", cat);
     return this.expandCategory(cat)
     });
     console.debug("ServiceCatalog:buildNavigationTree",nav);
     return nav;
   }
 
   constructor(catalogsToImport){
    catalogsToImport?.forEach((cat, index) => this.import(cat));
    this.navigationTree=this.buildNavigationTree();
   }
  
}