import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivityC2C } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { orderTypeValues } from "../common/Constants";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
  esrASiteAddress,
  esrASiteContact,
  esrCurrentSiteAddress,
  esrCurrentSiteContact,
  esrNewSiteAddress,
  esrNewSiteContact,
  esrBSiteAddress,
  esrBSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery } from "../catalogs/CatalogFieldServices";

import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";

export function schemaC2C(props) {
  const { name, installationSpec, cableSpec } = props;
  const schema = {
    "New Provisioning": {
     // specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      specs: (flatInstance) => [],
      decompose: decomposeC2CProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [],
      decompose: decomposeC2CAddVAS,
    },
    "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2CExtRelocationHot,
    },
    "Change Upgrade Hot": {
     specs: (flatInstance) => [],
      decompose: decomposeC2CUpDownGradeHot,
    },
    "Change Downgrade Hot": {
     specs: (flatInstance) => [],
      decompose: decomposeC2CUpDownGradeHot,
    },
    "New Upgrade Parallel": {
     specs: (flatInstance) => [],
      decompose: decomposeC2CUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
     specs: (flatInstance) => [],
      decompose: decomposeC2CUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        // esrCurrentSiteAddress,
        // esrCurrentSiteContact,
        // esrNewSiteAddress,
        // esrNewSiteContact,
      ],
      decompose: decomposeC2CExtRelocationParallel,
    },
    "Cease with equipment recovery": {
     specs: (flatInstance) => [],
      decompose: decomposeC2CCCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [],
      decompose: (flatInstance) => [],
    },
  };

  function decomposeC2CProvide(flatInstance) {

    const svcCable = svcspecCableConnectivityC2C.updateInstance(
      {},
      flatInstance,
      []
    );


    const svcInstallationA = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    const svcInstallationB=installationSpec.updateInstance({},flatInstance,
      [//mapping
        [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
        [esrWorkSiteAddress,esrCustomerSiteAddress],
        [esrWorkSiteContact,esrCustomerSiteAddress]
      ]);


    console.info(
      "schemaC2C:decompose New Provisioning svcCP2Cable,svcInstallation",
      svcCable,
      svcInstallationA,
      svcInstallationB
    );
    return [
      { action: "add", service: svcCable },
      { action: "add", service: svcInstallationA },
      { action: "add", service: svcInstallationB },
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2CAddVAS(flatInstance) {
    const svcInstallationA = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    const svcInstallationB=installationSpec.updateInstance({},flatInstance,
      [//mapping
        [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
        [esrWorkSiteAddress,esrBSiteAddress],
        [esrWorkSiteContact,esrBSiteContact]
      ]);

    console.debug(
      "schemaC2C:decompose Change Add VAS svcInstallation",
      svcInstallationA,
      svcInstallationB
    );
    return [{ action: "add", service: svcInstallationA },
    { action: "add", service: svcInstallationB }]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2CExtRelocationHot(flatInstance) {
    const svcCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiCable = svcCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiRecovery, soiCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecovery, soiInstallation, soiCable].filter((a) => a);
  }

  function decomposeC2CUpDownGradeHot(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    return [
      { action: "add", service: svcInstallation },
      { action: "add", service: svcRecovery },
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2CUpDownGradeParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      // [esrASiteAddress, esrCustomerSiteAddress],
      // [esrASiteContact, esrCustomerSiteContact],
    ]);

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      // [esrASiteAddress, esrCustomerSiteAddress],
      // [esrASiteContact, esrCustomerSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    const soiNewCable = svcNewCable
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //In parallel upgrade/downgrade scenarios equipment recovery should be done in 1 field job with installation.
    //So we will put any dependency for it hoping DFF will merge the field services into a single appointment
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecovery, soiInstallation, soiOldCable, soiNewCable].filter(
      (a) => a
    );
  }

  function decomposeC2CCCeaseEquipmentRecovery(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    return [soiRecovery].filter((a) => a);
  }

  function decomposeC2CExtRelocationParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCurrentSiteAddress],
      [esrASiteContact, esrCurrentSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    const soiNewCable = svcNewCable
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //recovery of the equpment from old site can only be done after new installation completed
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecovery, soiInstallation, soiOldCable, soiNewCable].filter(
      (a) => a
    );
  }

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

//temporary limit list of Order types until all order types supported
const chrspecC2COrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-C2C",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE),
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});

export function svcspecC2C(props) {
  const { name, installationServiceSpec, cableSpec, characteristics } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2C : cableSpec;

  console.debug(
    "svcspecC2C name,props,chrspecCommonSet(name),characteristics",
    name,
    props,
    chrspecCommonSet(name),
    characteristics
  );

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    entitySpecRelationship: [
      esrASiteAddress,
      esrASiteContact,
      esrBSiteAddress,
      esrBSiteContact,
    ],
    schema: schemaC2C({
      name: "C2CFS-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
    }),


    
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2COrderType;

  return spec;
}
