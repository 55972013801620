export default class NamedCache{

  persistent = new Map();
  transient = new Map();
  parentCache=undefined;

  constructor(parent,persistent,transient){
    this.parentCache=parent;
    if (persistent) this.persistent=new Map(persistent);
    if (transient) this.transient=new Map(transient);

  }
  
  getObjectName(obj){
    const name_override=obj?._name;
    if (name_override) return obj._name();
    const obj_name=obj?.name;
    return (typeof obj_name == "function" )? obj_name():obj_name;
  }

  set(item){
    this.data.set(this.getObjectName(item),item);
  }

  setPersistent(item){
    if (this.parentCache) this.parentCache.setPersistent(item);
    else this.persistent.set(this.getObjectName(item),item);
  }

  setTransient(item){
    if (Array.isArray(item)) {
      const entriesArray=item.map(i=>([this.getObjectName(i),i]));
      this.transient=new Map(entriesArray);
    }
    else this.transient.set(this.getObjectName(item),item);
  }

  getPersistent(){
    return (this.parentCache)?this.parentCache.persistent:this.persistent;
  }

  get(name){
    const persistentVal=this.getPersistent().get(name);
    return (persistentVal)?persistentVal:this.transient.get(name);
  }

  static entryToLabeledValue=([label,val])=>({
    label: label,
    value: label
  });

  asLabeledValues(){
    return [...new Map(
                [
                  ...this.transient.entries(),
                  ...this.getPersistent().entries()
                ])
  ].map(NamedCache.entryToLabeledValue);
  }
}

