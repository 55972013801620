import { useState,forwardRef,useImperativeHandle, useEffect,useRef, useCallback} from 'react';
import {Select,Divider} from 'antd';
import {debounce}  from 'lodash'

const  CustomSelectBase=forwardRef((props, ref) => {
  
  const { Option } = Select;
  const {options,
        onOptionSelected,
        open,onDropdownVisibleChange,
        mode,onDropdownModeChange,
        onSearch,onDebouncedSearch,
        searchTimeout=500,
        optionRender,footerRender,
        ...otherProps}=props;

  const [selectedOption,setSelectedOption]=useState();
  const [debouncedSearchTerm,setDebouncedSearchTerm]=useState("");

  const searchAbortControllerRef=useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch=useCallback(debounce((searchTerm)=>setDebouncedSearchTerm(searchTerm),searchTimeout),[searchTimeout]);

  console.debug("CustomSelectBase::render")

  useImperativeHandle(ref, () => ({
    dropdownOpen: () => onDropdownVisibleChange(true),
    dropdownClose: () => onDropdownVisibleChange(false),
    setDropdownMode: (mode) => onDropdownModeChange(mode)
  }));

  function onOptionClick(e,option){
    console.debug("CustomSelect:onOptionClick",e,option);
    const enableSelection=(onOptionSelected)?onOptionSelected(option):undefined;

    //if callback returned null/undefined/false just proceed with default select handling
    if (!enableSelection) return;

    e.stopPropagation();
    onDropdownVisibleChange(true);
    onDropdownModeChange(enableSelection);
    setSelectedOption(option);
}

    function onSelectDropdownVisibleChange(visible){
      if (mode==='select' || visible) onDropdownVisibleChange(visible);
      if (visible) onDropdownModeChange("select");
    }

    function onSelectSearch(searchString){
      onDropdownModeChange("select");
      searchAbortControllerRef.current?.abort();
      debouncedSearch(searchString);
    }

  function renderOptions() {
    return options.map(o => (
      <Option key={o.label} value={o.value} label={o.label}
        className="custom-select-option-container">
        <div className='custom-select-option' onClick={(e) => onOptionClick(e, o)}>
          {(optionRender) ? optionRender(o) : o.label}
        </div>
      </Option>
    ))
  }

  function renderDropdown(menu){
    const footer=(footerRender)?footerRender(selectedOption,mode):null;
    return(
      <div className='custom-select-dropdown'>
        {(mode==="select")?menu:null}
        {(footer)?<Divider className="compact-divider"/>:null}
        <div className="custom-select-form-container">
         {footer}
        </div>
      </div>
    )
  }


  useEffect(()=>{
      console.debug("CustomSelectBase::debouncedSearchEffect",debouncedSearchTerm, onDebouncedSearch)
      if (!onDebouncedSearch || debouncedSearchTerm.length<3) return;

      const abortController=new AbortController();
      searchAbortControllerRef.current=abortController;
      onDebouncedSearch(debouncedSearchTerm,abortController);
      
      return ()=>abortController.abort();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[debouncedSearchTerm]);
  

  return (
    
    <Select 
      onBlur={()=>{/*forceOpen=true*/}}
      filterSort={(optionA, optionB)=>((optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()))}
      showSearch={true}
      filterOption={false}
      {...otherProps}
      onDropdownVisibleChange={onSelectDropdownVisibleChange}
      open={open}
      dropdownRender={renderDropdown}
      onSearch={onSelectSearch}
    >
    {renderOptions()}
    </Select>
  );

}
);

export default CustomSelectBase;