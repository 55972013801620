import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivity2C2P, svcspecCableConnectivityC2P, svcspecGenericCableConnectivity } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { orderTypeValues } from "../common/Constants";
import {
  esrWorkSiteAddress,esrWorkSiteContact,
  esrCustomerSiteAddress,esrCustomerSiteContact,
  esrASiteAddress,esrASiteContact,
  esrCurrentSiteAddress,esrCurrentSiteContact,
  esrNewSiteAddress,esrNewSiteContact,
  esrBSiteAddress,esrBSiteContact,
  esrCurrentASiteAddress, esrCurrentASiteContact,
  esrCurrentBSiteAddress, esrCurrentBSiteContact,
  esrNewASiteAddress, esrNewASiteContact,
  esrNewBSiteAddress, esrNewBSiteContact

} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery , svcspecGenericEquipmentInstallation, svcspecGenericEqupmentRecovery, svcspecGigawaveEquipmentInstallation} from "../catalogs/CatalogFieldServices";

import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";
import { addAppointmentGroupName } from "../common/CommonUtils";
import { v4 as uuid } from "uuid";

export function schemaGigawaveDc2Dc(props) {
  const { name, installationSpec, cableSpec,channels, genericEquipmentInstallation, } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWAddVAS,
    },
    "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentASiteAddress,
        esrCurrentASiteContact,
        esrNewASiteAddress,
        esrNewASiteContact,
        esrCurrentBSiteAddress,
        esrCurrentBSiteContact,
        esrNewBSiteAddress,
        esrNewBSiteContact
      ],
      decompose: decomposeGWExtRelocationHot,
    },
    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWUpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWUpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentASiteAddress,
        esrCurrentASiteContact,
        esrNewASiteAddress,
        esrNewASiteContact,
        esrCurrentBSiteAddress,
        esrCurrentBSiteContact,
        esrNewBSiteAddress,
        esrNewBSiteContact
      ],
      decompose: decomposeGWExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decomposeGWCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [],
      decompose: (flatInstance) => [],
    },
  };

  function decomposeGWProvide(flatInstance) {

    
    const svcInstallationA=installationSpec.updateInstance({},flatInstance,
      [//mapping
        [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
        [esrWorkSiteAddress,esrASiteAddress],
        [esrWorkSiteContact,esrASiteContact]
      ]);

      const genEqptInstlAndRecvryA = genericEquipmentInstallation ? genericEquipmentInstallation?.updateInstance({}, flatInstance, [
        //mapping
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrASiteAddress],
        [esrWorkSiteContact, esrASiteContact],
      ]):null;
     // addAppointmentGroupName(genEqptInstlAndRecvryA, "Gigawave Generic Equipment Installation - Monitoring A")



  
      const svcInstallationB=installationSpec.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
          [esrWorkSiteAddress,esrBSiteAddress],
          [esrWorkSiteContact,esrBSiteContact]
        ]);


        const genEqptInstlAndRecvryB = genericEquipmentInstallation ? genericEquipmentInstallation?.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
          [esrWorkSiteAddress, esrBSiteAddress],
          [esrWorkSiteContact, esrBSiteContact],
        ]):null;
     //   addAppointmentGroupName(genEqptInstlAndRecvryB, "Gigawave Generic Equipment Installation - Monitoring B")
  
        
        return [svcInstallationA, svcInstallationB]
              .filter((a) => a)
              .map((svc) => (new ServiceOrderItem({action:"add",service:svc})));
  }

  function decomposeGWAddVAS(flatInstance) {
    const svcInstallationASite = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
      [esrWorkSiteAddress, esrASiteAddress],
      [esrWorkSiteContact, esrASiteContact],
    ]);

    const svcInstallationBSite = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
      [esrWorkSiteAddress, esrBSiteAddress],
      [esrWorkSiteContact, esrBSiteContact],
    ]);

    return [
      { action: 'add', service: svcInstallationASite },
      { action: 'add', service: svcInstallationBSite },
    ]
      .filter(a => a.service)
      .map(a => new ServiceOrderItem(a));
  }

  function decomposeGWExtRelocationHot(flatInstance) {
    // Install/Recovery A-Site ========================================================

    const aSiteCurrent = flatInstance['place:Current A-Site.Address'];
    const aSiteNew = flatInstance['place:New A-Site.Address'];
    const bSiteCurrent = flatInstance['place:Current B-Site.Address'];
    const bSiteNew = flatInstance['place:New B-Site.Address'];
    let aSiteInstallSOIs = [];

    if (aSiteCurrent.value.name !== aSiteNew.value.name) {
      //Default EI - A Main //Gigawave Equipment Installation
      const svcInstallationA = installationSpec.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrNewASiteAddress],
        [esrWorkSiteContact, esrNewASiteContact],
      ]);

      //Generic E2E Cable Connectivity
      const genericE2ECableSpecA = svcspecGenericCableConnectivity?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrASiteAddress, esrNewASiteAddress],
        [esrASiteContact, esrNewASiteContact],
      ]);

      //Generic Equipment Recovery
      const genericEquipmentRecovery = svcspecGenericEqupmentRecovery?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrCurrentASiteAddress],
        [esrWorkSiteContact, esrCurrentASiteContact],
      ]);

      const soGenCabConA = genericE2ECableSpecA
        ? new ServiceOrderItem({
            action: 'modify',
            service: genericE2ECableSpecA,
          })
        : undefined;

      const soERA = genericEquipmentRecovery
        ? new ServiceOrderItem({
            action: 'add',
            service: genericEquipmentRecovery,
          })
        : undefined;
      const soEIA = svcInstallationA
        ? new ServiceOrderItem({
            action: 'add',
            service: svcInstallationA,
            serviceOrderItemRelationship: [soGenCabConA, soERA]
              .filter(a => a)
              .map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      // C2P Cable A - C2P E2E Cable Connectivity
      const svcCableA1 = svcspecCableConnectivityC2P.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrASiteAddress, esrNewASiteAddress],
        [esrASiteContact, esrNewASiteContact],
      ]);
      addAppointmentGroupName(svcCableA1, 'Monitoring A');
      //Generic Equipment Installation/Recovery
      const genEIA1 = svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrNewASiteAddress],
        [esrWorkSiteContact, esrNewASiteContact],
      ]);
      addAppointmentGroupName(genEIA1, 'Monitoring A');

      const genERA1 = svcspecEqupmentRecovery?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrCurrentASiteAddress],
        [esrWorkSiteContact, esrCurrentASiteContact],
      ]);
      addAppointmentGroupName(genERA1, 'Monitoring A');

      const soCableA1 = svcCableA1
        ? new ServiceOrderItem({
            action: 'add',
            service: svcCableA1,
          })
        : undefined;

      const genOEIA1 = genEIA1
        ? new ServiceOrderItem({
            action: 'add',
            service: genEIA1,
            // serviceOrderItemRelationship: [soCableA1].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const genOERA1 = genERA1
        ? new ServiceOrderItem({
            action: 'add',
            service: genERA1,
            //serviceOrderItemRelationship: [genOEIA1].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      // 2C2P E2E Cable Connectivity
      const svcCableA2 =
        bSiteCurrent.value.name !== bSiteNew.value.name
          ? svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
              [esrASiteAddress, esrNewASiteAddress],
              [esrASiteContact, esrNewASiteContact],
              [esrBSiteAddress, esrNewBSiteAddress],
              [esrBSiteContact, esrNewBSiteContact],
            ])
          : svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
              [esrASiteAddress, esrNewASiteAddress],
              [esrASiteContact, esrNewASiteContact],
            ]);
      addAppointmentGroupName(svcCableA2, 'DWDM Fibre Main');
      // Generic Equipment Installation/Recovery
      const genEIA2 = svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrNewASiteAddress],
        [esrWorkSiteContact, esrNewASiteContact],
      ]);
      addAppointmentGroupName(genEIA2, 'DWDM Fibre Main');

      const genERA2 = svcspecGenericEqupmentRecovery?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrCurrentASiteAddress],
        [esrWorkSiteContact, esrCurrentASiteContact],
      ]);
      addAppointmentGroupName(genERA2, 'DWDM Fibre Main');

      const soCableA2 = svcCableA2
        ? new ServiceOrderItem({
            action: 'add',
            service: svcCableA2,
          })
        : undefined;

      const genOEIA2 = genEIA2
        ? new ServiceOrderItem({
            action: 'add',
            service: genEIA2,
            //serviceOrderItemRelationship: [soCableA2].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const genOERA2 = genERA2
        ? new ServiceOrderItem({
            action: 'add',
            service: genERA2,
            //serviceOrderItemRelationship: [genOEIA2].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const svcCableA3 =
        channels > 1
          ? bSiteCurrent.value.name !== bSiteNew.value.name
            ? svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
                [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
                [esrASiteAddress, esrNewASiteAddress],
                [esrASiteContact, esrNewASiteContact],
                [esrBSiteAddress, esrNewBSiteAddress],
                [esrBSiteContact, esrNewBSiteContact],
              ])
            : svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
                [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
                [esrASiteAddress, esrNewASiteAddress],
                [esrASiteContact, esrNewASiteContact],
              ])
          : null;

      const genEIA3 =
        channels > 1
          ? svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
              [esrWorkSiteAddress, esrNewASiteAddress],
              [esrWorkSiteContact, esrNewASiteContact],
            ])
          : null;
      const genERA3 =
        channels > 1
          ? svcspecGenericEqupmentRecovery?.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
              [esrWorkSiteAddress, esrCurrentASiteAddress],
              [esrWorkSiteContact, esrCurrentASiteContact],
            ])
          : null;

      if (channels > 1) {
        addAppointmentGroupName(svcCableA3, 'DWDM Fibre Standby');
        addAppointmentGroupName(genEIA3, 'DWDM Fibre Standby');
        addAppointmentGroupName(genERA3, 'DWDM Fibre Standby');
      }
      const soCableA3 = svcCableA3
        ? new ServiceOrderItem({
            action: 'add',
            service: svcCableA3,
          })
        : undefined;

      const genOEIA3 = genEIA3
        ? new ServiceOrderItem({
            action: 'add',
            service: genEIA3,
            // serviceOrderItemRelationship: [soCableA3].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const genOERA3 = genERA3
        ? new ServiceOrderItem({
            action: 'add',
            service: genERA3,
            //   serviceOrderItemRelationship: [genOEIA3].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;
      aSiteInstallSOIs = [
        soGenCabConA,
        soEIA,
        soERA,
        soCableA1,
        genOEIA1,
        genOERA1,
        soCableA2,
        genOEIA2,
        genOERA2,
        soCableA3,
        genOEIA3,
        genOERA3,
      ].filter(a => !!a);
    } else {
      console.warn('schemaGigawave:decomposeGWExtRelocationHot - A-Site NOT CHANGED');
    }

    // Install/Recovery B-Site ========================================================

    let bSiteInstallSOIs = [];
    if (bSiteCurrent.value.name !== bSiteNew.value.name) {
      //Default EI - A Main //Gigawave Equipment Installation
      const svcInstallationB = installationSpec.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrNewBSiteAddress],
        [esrWorkSiteContact, esrNewBSiteContact],
      ]);

      //Generic E2E Cable Connectivity
      const genericE2ECableSpecB = svcspecGenericCableConnectivity?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrBSiteAddress, esrNewBSiteAddress],
        [esrBSiteContact, esrNewBSiteContact],
      ]);

      //Generic Equipment Recovery
      const genericEquipmentRecovery = svcspecGenericEqupmentRecovery?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrCurrentBSiteAddress],
        [esrWorkSiteContact, esrCurrentBSiteContact],
      ]);

      const soGenCabConB = genericE2ECableSpecB
        ? new ServiceOrderItem({
            action: 'modify',
            service: genericE2ECableSpecB,
          })
        : undefined;

      const soERB = genericEquipmentRecovery
        ? new ServiceOrderItem({
            action: 'add',
            service: genericEquipmentRecovery,
          })
        : undefined;
      const soEIB = svcInstallationB
        ? new ServiceOrderItem({
            action: 'add',
            service: svcInstallationB,
            serviceOrderItemRelationship: [soGenCabConB, soERB]
              .filter(a => a)
              .map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      // C2P Cable A - C2P E2E Cable Connectivity
      const svcCableB1 = svcspecCableConnectivityC2P.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrBSiteAddress, esrNewBSiteAddress],
        [esrBSiteContact, esrNewBSiteContact],
      ]);
      addAppointmentGroupName(svcCableB1, 'Monitoring B');
      //Generic Equipment Installation/Recovery
      const genEIB1 = svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrNewBSiteAddress],
        [esrWorkSiteContact, esrNewBSiteContact],
      ]);
      addAppointmentGroupName(genEIB1, 'Monitoring B');

      const genERB1 = svcspecEqupmentRecovery?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrCurrentBSiteAddress],
        [esrWorkSiteContact, esrCurrentBSiteContact],
      ]);
      addAppointmentGroupName(genERB1, 'Monitoring B');

      const soCableB1 = svcCableB1
        ? new ServiceOrderItem({
            action: 'add',
            service: svcCableB1,
          })
        : undefined;

      const genOEIB1 = genEIB1
        ? new ServiceOrderItem({
            action: 'add',
            service: genEIB1,
            // serviceOrderItemRelationship: [soCableB1].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const genOERB1 = genERB1
        ? new ServiceOrderItem({
            action: 'add',
            service: genERB1,
            // serviceOrderItemRelationship: [genOEIB1].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      // 2C2P E2E Cable Connectivity
      const svcCableB2 =
        aSiteCurrent.value.name !== aSiteNew.value.name
          ? svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
              [esrASiteAddress, esrNewASiteAddress],
              [esrASiteContact, esrNewASiteContact],
              [esrBSiteAddress, esrNewBSiteAddress],
              [esrBSiteContact, esrNewBSiteContact],
            ])
          : svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
              [esrBSiteAddress, esrNewBSiteAddress],
              [esrBSiteContact, esrNewBSiteContact],
            ]);
      addAppointmentGroupName(svcCableB2, 'DWDM Fibre Main');
      // Generic Equipment Installation/Recovery
      const genEIB2 = svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrNewBSiteAddress],
        [esrWorkSiteContact, esrNewBSiteContact],
      ]);
      addAppointmentGroupName(genEIB2, 'DWDM Fibre Main');

      const genERB2 = svcspecGenericEqupmentRecovery?.updateInstance({}, flatInstance, [
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrCurrentBSiteAddress],
        [esrWorkSiteContact, esrCurrentBSiteContact],
      ]);
      addAppointmentGroupName(genERB2, 'DWDM Fibre Main');

      const soCableB2 = svcCableB2
        ? new ServiceOrderItem({
            action: 'add',
            service: svcCableB2,
          })
        : undefined;

      const genOEIB2 = genEIB2
        ? new ServiceOrderItem({
            action: 'add',
            service: genEIB2,
            //     serviceOrderItemRelationship: [soCableB2].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const genOERB2 = genERB2
        ? new ServiceOrderItem({
            action: 'add',
            service: genERB2,
            //   serviceOrderItemRelationship: [genOEIB2].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const svcCableB3 =
        channels > 1
          ? aSiteCurrent.value.name !== aSiteNew.value.name
            ? svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
                [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
                [esrASiteAddress, esrNewASiteAddress],
                [esrASiteContact, esrNewASiteContact],
                [esrBSiteAddress, esrNewBSiteAddress],
                [esrBSiteContact, esrNewBSiteContact],
              ])
            : svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
                [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
                [esrBSiteAddress, esrNewBSiteAddress],
                [esrBSiteContact, esrNewBSiteContact],
              ])
          : null;

      const genEIB3 =
        channels > 1
          ? svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
              [esrWorkSiteAddress, esrNewBSiteAddress],
              [esrWorkSiteContact, esrNewBSiteContact],
            ])
          : null;
      const genERB3 =
        channels > 1
          ? svcspecGenericEqupmentRecovery?.updateInstance({}, flatInstance, [
              [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
              [esrWorkSiteAddress, esrCurrentBSiteAddress],
              [esrWorkSiteContact, esrCurrentBSiteContact],
            ])
          : null;

      if (channels > 1) {
        addAppointmentGroupName(svcCableB3, 'DWDM Fibre Standby');
        addAppointmentGroupName(genEIB3, 'DWDM Fibre Standby');
        addAppointmentGroupName(genERB3, 'DWDM Fibre Standby');
      }
      const soCableB3 = svcCableB3
        ? new ServiceOrderItem({
            action: 'add',
            service: svcCableB3,
          })
        : undefined;

      const genOEIB3 = genEIB3
        ? new ServiceOrderItem({
            action: 'add',
            service: genEIB3,
            //  serviceOrderItemRelationship: [soCableB3].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;

      const genOERB3 = genERB3
        ? new ServiceOrderItem({
            action: 'add',
            service: genERB3,
            //  serviceOrderItemRelationship: [genOEIB3].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
          })
        : undefined;
      bSiteInstallSOIs = [
        soGenCabConB,
        soEIB,
        soERB,
        soCableB1,
        genOEIB1,
        genOERB1,
        soCableB2,
        genOEIB2,
        genOERB2,
        soCableB3,
        genOEIB3,
        genOERB3,
      ].filter(a => !!a);
    } else {
      console.warn('schemaGigawave:decomposeGWExtRelocationHot - B-Site NOT CHANGED');
    }

    return [...aSiteInstallSOIs, ...bSiteInstallSOIs];
  }

  function decomposeGWUpDownGradeHot(flatInstance) {
    const svcRecoveryASite = installationSpec
    ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
        //mapping
        [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
        [esrWorkSiteAddress, esrASiteAddress],
        [esrWorkSiteContact, esrASiteContact],
      ])
    : undefined;

  const svcInstallationASite = installationSpec?.updateInstance({}, flatInstance, [
    //mapping
    [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
    [esrWorkSiteAddress, esrASiteAddress],
    [esrWorkSiteContact, esrASiteContact],
  ]);

  const svcRecoveryBSite = installationSpec
    ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
        //mapping
        [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
        [esrWorkSiteAddress, esrASiteAddress],
        [esrWorkSiteContact, esrASiteContact],
      ])
    : undefined;

  const svcInstallationBSite = installationSpec?.updateInstance({}, flatInstance, [
    //mapping
    [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
    [esrWorkSiteAddress, esrASiteAddress],
    [esrWorkSiteContact, esrASiteContact],
  ]);

  return [
    { action: 'add', service: svcInstallationASite },
    { action: 'add', service: svcRecoveryASite },
    { action: 'add', service: svcInstallationBSite },
    { action: 'add', service: svcRecoveryBSite },
  ]
    .filter(a => a.service)
    .map(a => new ServiceOrderItem(a));
  }

  function decomposeGWUpDownGradeParallel(flatInstance) {
    // **** for A - Site
    const svcNewCableASite = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
      [esrASiteAddress, esrASiteAddress],
      [esrASiteContact, esrASiteContact],
    ]);
    addAppointmentGroupName(svcNewCableASite, 'Monitoring A');
    const svcOldCableASite = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
      [esrASiteAddress, esrASiteAddress],
      [esrASiteContact, esrASiteContact],
    ]);
    if (svcOldCableASite) svcOldCableASite.state = 'terminated';
    addAppointmentGroupName(svcOldCableASite, 'Monitoring A');
    const svcRecoveryASite = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
          [esrWorkSiteAddress, esrASiteAddress],
          [esrWorkSiteContact, esrASiteContact],
        ])
      : undefined;
    addAppointmentGroupName(svcRecoveryASite, 'DWDM Fibre Main');
    const svcInstallationASite = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('A-Site')],
      [esrWorkSiteAddress, esrASiteAddress],
      [esrWorkSiteContact, esrASiteContact],
    ]);
    addAppointmentGroupName(svcInstallationASite, 'DWDM Fibre Main');
    const soiNewCableASite = svcNewCableASite
      ? new ServiceOrderItem({
          action: 'add',
          service: svcNewCableASite,
        })
      : undefined;

    const soiInstallationASite = svcInstallationASite
      ? new ServiceOrderItem({
          action: 'add',
          service: svcInstallationASite,
          serviceOrderItemRelationship: [soiNewCableASite].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
        })
      : undefined;

    const soiRecoveryASite = svcRecoveryASite
      ? new ServiceOrderItem({
          action: 'add',
          service: svcRecoveryASite,
        })
      : undefined;

    const soiOldCableASite = svcOldCableASite
      ? new ServiceOrderItem({
          action: 'modify',
          service: svcOldCableASite,
          serviceOrderItemRelationship: [soiInstallationASite]
            .filter(a => a)
            .map(a => soiRelationship(a, 'Dependent On')),
        })
      : undefined;

    // **** for B - Site ***** //
    const svcNewCableBSite = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
      [esrBSiteAddress, esrBSiteAddress],
      [esrBSiteContact, esrBSiteContact],
    ]);
    addAppointmentGroupName(svcNewCableBSite, 'Monitoring B');
    const svcOldCableBSite = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
      [esrBSiteAddress, esrBSiteAddress],
      [esrBSiteContact, esrBSiteContact],
    ]);
    if (svcOldCableBSite) svcOldCableBSite.state = 'terminated';
    addAppointmentGroupName(svcOldCableBSite, 'Monitoring B');
    const svcRecoveryBSite = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
          [esrWorkSiteAddress, esrBSiteAddress],
          [esrWorkSiteContact, esrBSiteContact],
        ])
      : undefined;
    addAppointmentGroupName(svcRecoveryBSite, 'DWDM Fibre Main');
    const svcInstallationBSite = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst('B-Site')],
      [esrWorkSiteAddress, esrBSiteAddress],
      [esrWorkSiteContact, esrBSiteContact],
    ]);
    addAppointmentGroupName(svcInstallationBSite, 'DWDM Fibre Main');
    const soiNewCableBSite = svcNewCableBSite
      ? new ServiceOrderItem({
          action: 'add',
          service: svcNewCableBSite,
        })
      : undefined;

    const soiInstallationBSite = svcInstallationBSite
      ? new ServiceOrderItem({
          action: 'add',
          service: svcInstallationBSite,
          serviceOrderItemRelationship: [soiNewCableBSite].filter(a => a).map(a => soiRelationship(a, 'Dependent On')),
        })
      : undefined;

    const soiRecoveryBSite = svcRecoveryBSite
      ? new ServiceOrderItem({
          action: 'add',
          service: svcRecoveryBSite,
        })
      : undefined;

    const soiOldCableBSite = svcOldCableBSite
      ? new ServiceOrderItem({
          action: 'modify',
          service: svcOldCableBSite,
          serviceOrderItemRelationship: [soiInstallationBSite]
            .filter(a => a)
            .map(a => soiRelationship(a, 'Dependent On')),
        })
      : undefined;

    return [
      soiRecoveryASite,
      soiInstallationASite,
      soiOldCableASite,
      soiNewCableASite,
      soiRecoveryBSite,
      soiInstallationBSite,
      soiOldCableBSite,
      soiNewCableBSite,
    ].filter(a => a);
  }

  function decomposeGWCeaseEquipmentRecovery(flatInstance) {
    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrASiteAddress],
          [esrWorkSiteContact,esrASiteContact]
        ])
      : undefined;

    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
        })
      : undefined;


      const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
          [esrWorkSiteAddress,esrBSiteAddress],
          [esrWorkSiteContact,esrBSiteContact]
        ])
      : undefined;

    const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
        })
      : undefined;


    return [soiRecoveryA,soiRecoveryB].filter((a) => a);
  }


  function decomposeGWExtRelocationParallelSite(flatInstance, side,deps){
    const siteRole = side === 'A' ? 'A-Site' : 'B-Site';
    const isBSite = side === 'B';
    const newSiteAddressRelation = isBSite ? esrNewBSiteAddress : esrNewASiteAddress;
    const newSiteContactRelation = isBSite ? esrNewBSiteContact : esrNewASiteContact;
    const currentSiteAddressRelation = isBSite ? esrCurrentBSiteAddress : esrCurrentASiteAddress;
    const currentSiteContactRelation = isBSite ? esrCurrentBSiteContact : esrCurrentASiteContact;

    let svcCableMonitoringC2P = svcspecCableConnectivityC2P.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst(siteRole)],
      [esrASiteAddress, newSiteAddressRelation],
      [esrASiteContact, newSiteContactRelation],
    ]);
    if (isBSite) svcCableMonitoringC2P.place[0].role = 'B-Site.Address';

    const soiCableMonitoringC2P = new ServiceOrderItem({
      action: 'add',
      service: svcCableMonitoringC2P,
    });

    addAppointmentGroupName(soiCableMonitoringC2P.service, isBSite ? 'Monitoring B' : 'Monitoring A');

    const svcInstallation = svcspecGigawaveEquipmentInstallation.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst(siteRole)],
      [esrWorkSiteAddress, newSiteAddressRelation],
      [esrWorkSiteContact, newSiteContactRelation],
    ]);

    const soiInstallation = new ServiceOrderItem({
      action: 'add',
      service: svcInstallation,
      serviceOrderItemRelationship: [...deps, soiCableMonitoringC2P]
        .filter(a => a)
        .map(a => soiRelationship(a, 'Dependent On')),
    });

    const svcCableA2 = svcspecCableConnectivity2C2P.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst(siteRole)],
      [esrASiteAddress, newSiteAddressRelation],
      [esrASiteContact, newSiteContactRelation],
    ]);
    addAppointmentGroupName(svcCableA2, 'DWDM Fibre Main');

    const svcRecovery = svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst(siteRole)],
      [esrWorkSiteAddress, currentSiteAddressRelation],
      [esrWorkSiteContact, currentSiteContactRelation],
    ]);
    addAppointmentGroupName(svcRecovery, 'DWDM Fibre Main');
    const soiRecovery = new ServiceOrderItem({
      action: 'add',
      service: svcRecovery,
      serviceOrderItemRelationship: [soiInstallation, ...deps]
        .filter(a => a)
        .map(a => soiRelationship(a, 'Dependent On')),
    });

    let svcCableMonitoringC2PTerminate = svcspecCableConnectivityC2P.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst(siteRole)],
      [esrASiteAddress, currentSiteAddressRelation],
      [esrASiteContact, currentSiteContactRelation],
    ]);
    if (isBSite) svcCableMonitoringC2PTerminate.place[0].role = 'B-Site.Address';
    svcCableMonitoringC2PTerminate.state = 'terminated';
    addAppointmentGroupName(svcCableMonitoringC2PTerminate, isBSite ? 'Monitoring B' : 'Monitoring A');

    const soiCableMonitoringC2PTerminate = new ServiceOrderItem({
      action: 'modify',
      service: svcCableMonitoringC2PTerminate,
      serviceOrderItemRelationship: [soiCableMonitoringC2P, ...deps]
        .filter(a => a)
        .map(a => soiRelationship(a, 'Dependent On')),
    });

    return [soiCableMonitoringC2P, soiInstallation, soiRecovery, soiCableMonitoringC2PTerminate];
  }

  function decomposeGWExtRelocationParallel(flatInstance) {

    const svcChannelCableInstallationSOIs=Array.from(Array(channels), ()=>(
      new ServiceOrderItem({
        action: "add",
        service:svcspecCableConnectivity2C2P.updateInstance(
            {},
            flatInstance,
            [
              [esrASiteAddress, esrNewASiteAddress],
              [esrASiteContact, esrNewASiteContact],
              [esrBSiteAddress, esrNewBSiteAddress],
              [esrBSiteContact, esrNewBSiteContact]
            ]
          )})
        )
        
      );

      const svcChannelCableTerminationSOIs=Array.from(Array(channels), ()=>(
        new ServiceOrderItem({
          action: "modify",
          service:svcspecCableConnectivity2C2P.updateInstance(
              {},
              flatInstance,
              [
                [esrASiteAddress, esrCurrentASiteAddress],
                [esrASiteContact, esrCurrentASiteContact],
                [esrBSiteAddress, esrCurrentBSiteAddress],
                [esrBSiteContact, esrCurrentBSiteContact]
              ]
            ),
          serviceOrderItemRelationship: [...svcChannelCableInstallationSOIs]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On"))
          })
          )
        ).map(soi=>{
          soi.service.state = "terminated";
          return soi;
        });

    const aSideSOIs=decomposeGWExtRelocationParallelSite(flatInstance,"A",svcChannelCableInstallationSOIs);
    const bSideSOIs=decomposeGWExtRelocationParallelSite(flatInstance,"B",svcChannelCableInstallationSOIs);

    return [...svcChannelCableInstallationSOIs,...aSideSOIs,...bSideSOIs,...svcChannelCableTerminationSOIs];
  }

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

const chrspecC2POrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE),
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});

export function gigawavwServiceSpec2C2PDc2Dc(props) {
  const { name, installationServiceSpec, cableSpec, characteristics, genericEquipmentInstallation,channels } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaGigawaveDc2Dc({
      name: "GWdc2dc-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericEquipmentInstallation,
      channels: channels
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderType;

  return spec;
}
