import ServiceCatalog from '../service-catalog/ServiceCatalog'
import {svcspecC2CFieldService} from '../schema/schemaC2CFieldService'
import {svcspecFieldService} from '../schema/schemaFieldService'
import {svcspecInstallationeALLC} from '../catalogs/CatalogFieldServices'
import {chrspec} from '../service-catalog/CharacteristicSpecification'
import {chrspecPremiseWiring} from './CharacteristicsCatalog'

const chrspecProductTypeSingneteALLC=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"ALLC - ALLC"
});

function ALLCTemplate(name){
  return  svcspecC2CFieldService({
                      name:name,
                      installationServiceSpec:svcspecInstallationeALLC,
                      characteristics:[chrspecProductTypeSingneteALLC,chrspecPremiseWiring]
                    })
};

function ALLCFsTemplate(name){
  return  svcspecFieldService({
                      name:name,
                      installationServiceSpec:svcspecInstallationeALLC,
                      characteristics:[chrspecProductTypeSingneteALLC]
                    })
};


export const catalogALLC=((new ServiceCatalog())
  .category("ALLC")
      .serviceSpecs([
        ALLCTemplate("ALLC - ALLC"),
        ALLCTemplate("ALLC - Data"),
        ALLCTemplate("ALLC - hotline"),
        ALLCTemplate("Analogue Leased Circuits Rental"),
        ALLCFsTemplate("ALLC Standby"),
        ALLCFsTemplate("ALLC Visit Charge"),
        ALLCTemplate("External Relocation Charge")
       ])
  .endCategory());
