import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivity2C2P, svcspecCableConnectivityC2C, svcspecCableConnectivityC2P, svcspecCableConnectivityC2PBSite } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { orderTypeValues } from "../common/Constants";
import {
  esrWorkSiteAddress,esrWorkSiteContact,
  esrCustomerSiteAddress,esrCustomerSiteContact,
  esrASiteAddress,esrASiteContact,
  esrCurrentSiteAddress,esrCurrentSiteContact,
  esrNewSiteAddress,esrNewSiteContact,
  esrBSiteAddress,esrBSiteContact,
  esrCurrentASiteAddress, esrCurrentASiteContact,
  esrCurrentBSiteAddress, esrCurrentBSiteContact,
  esrNewASiteAddress, esrNewASiteContact,
  esrNewBSiteAddress, esrNewBSiteContact

} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery , svcspecInstallationMetroE} from "../catalogs/CatalogFieldServices";
import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";

export function schema2C2P(props) {
  const { name, installationSpec, cableSpec,channels } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompos2C2PProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decompose2C2PAddVAS,
    },
  "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decompose2C2PExtRelocationHot,
    },

    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decompose2C2PUpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decompose2C2PUpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompose2C2PUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompose2C2PUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentASiteAddress,
        esrCurrentASiteContact,
        esrNewASiteAddress,
        esrNewASiteContact,
        esrCurrentBSiteAddress,
        esrCurrentBSiteContact,
        esrNewBSiteAddress,
        esrNewBSiteContact
      ],
      decompose: decompose2C2PExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompose2C2PCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: (flatInstance) => [],
    },
  };

  function decompos2C2PProvide(flatInstance) {

    const svcInstallationA=installationSpec.updateInstance({},flatInstance,
      [//mapping
        [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
        [esrWorkSiteAddress,esrASiteAddress],
        [esrWorkSiteContact,esrASiteContact]
      ]);

      const svcInstallationB=installationSpec.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
          [esrWorkSiteAddress,esrBSiteAddress],
          [esrWorkSiteContact,esrBSiteContact]
        ]);

        const svcCableA1 = svcspecCableConnectivityC2P.updateInstance(
          {},
          flatInstance,
        [   [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrASiteAddress, esrASiteAddress],
          [esrASiteContact, esrASiteContact]]
        );

        const svcCableB1 = svcspecCableConnectivityC2P.updateInstance(
          {},
          flatInstance,
        [   [chrspecFieldServiceAddressRole,mapperConst("B-Site")], 
          [esrASiteAddress, esrBSiteAddress],
            [esrBSiteContact, esrBSiteContact]]
        );

        svcCableB1.place[0].role="B-Site.Address";

        const svcCableA2 = svcspecCableConnectivity2C2P.updateInstance(
          {},
          flatInstance,
          []
        );
        const svcCableB2 = (channels>1)?svcspecCableConnectivity2C2P.updateInstance(
          {},
          flatInstance,
          [ ]
        ):null;
        
        return [svcInstallationA, svcInstallationB, svcCableA1, svcCableA2,svcCableB1,svcCableB2]
              .filter((a) => a)
              .map((svc) => (new ServiceOrderItem({action:"add",service:svc})));
  }

  function decompose2C2PAddVAS(flatInstance) {
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    console.debug(
      "schemaC2P:decompose Change Add VAS svcInstallation",
      svcInstallation
    );
    return [{ action: "add", service: svcInstallation }]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

 function decompose2C2PExtRelocationHot(flatInstance) {
    const svcCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

  
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiCable = svcCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcCable,
        })
      : undefined;
    

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiRecovery, soiCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecovery, soiInstallation, soiCable].filter((a) => a);
  }

  function decompose2C2PUpDownGradeHot(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    return [
      { action: "add", service: svcInstallation },
      { action: "add", service: svcRecovery },
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decompose2C2PUpDownGradeParallel(flatInstance) {

    const svcNewCableA = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
      [esrASiteAddress, esrASiteAddress],
      [esrASiteContact, esrASiteContact]
    ]);
	
	 const svcNewCableB = svcspecCableConnectivityC2PBSite?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
      [esrBSiteAddress, esrBSiteAddress],
      [esrBSiteContact, esrBSiteContact]
    ]);

    const svcOldCableA = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
      [esrASiteAddress, esrASiteAddress],
      [esrASiteContact, esrASiteContact]
    ]);
	
    if (svcOldCableA) svcOldCableA.state = "terminated";
	
	 const svcOldCableB = svcspecCableConnectivityC2PBSite?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
      [esrBSiteAddress, esrBSiteAddress],
      [esrBSiteContact, esrBSiteContact]
    ]);

 if (svcOldCableB) svcOldCableB.state = "terminated";
 
    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrASiteAddress],
          [esrWorkSiteContact, esrASiteContact],
        ])
      : undefined;
	  
	   const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
          [esrWorkSiteAddress, esrBSiteAddress],
          [esrWorkSiteContact, esrBSiteContact],
        ])
      : undefined;

    const svcInstallationA = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrASiteAddress],
      [esrWorkSiteContact, esrASiteContact],
    ]);
	
	 const svcInstallationB = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
      [esrWorkSiteAddress, esrBSiteAddress],
      [esrWorkSiteContact, esrBSiteContact],
    ]);

    const soiNewCableA = svcNewCableA
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableA,
        })
      : undefined;
	  
	  const soiNewCableB = svcNewCableB
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableB,
        })
      : undefined;

    const soiInstallationA = svcInstallationA
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationA,
          serviceOrderItemRelationship: [soiNewCableA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;
	  
	   const soiInstallationB = svcInstallationB
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationB,
          serviceOrderItemRelationship: [soiNewCableB]
            .filter((b) => b)
            .map((b) => soiRelationship(b, "Dependent On")),
        })
      : undefined;

    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
        })
      : undefined;
	  
	const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
        })
      : undefined;

    const soiOldCableA = svcOldCableA
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableA,
          serviceOrderItemRelationship: [soiInstallationA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;
	  
	   const soiOldCableB = svcOldCableB
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableB,
          serviceOrderItemRelationship: [soiInstallationB]
            .filter((b) => b)
            .map((b) => soiRelationship(b, "Dependent On")),
        })
      : undefined;

    return [soiRecoveryA, soiInstallationA, soiOldCableA, soiNewCableA, soiRecoveryB, soiInstallationB, soiOldCableB, soiNewCableB].filter(
      (a) => a
    );
  }

  function decompose2C2PExtRelocationParallel(flatInstance) {
    const svcNewCableA = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewASiteAddress],
      [esrASiteContact, esrNewASiteContact],
    ]);

    const svcNewCableB = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrBSiteAddress, esrNewBSiteAddress],
      [esrBSiteContact, esrNewBSiteContact],
    ]);

    const svcOldCableA = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCurrentASiteAddress],
      [esrASiteContact, esrCurrentASiteContact],
    ]);
    if (svcOldCableA) svcOldCableA.state = "terminated";

    const svcOldCableB = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrBSiteAddress, esrCurrentBSiteAddress],
      [esrBSiteContact, esrCurrentBSiteContact],
    ]);
    if (svcOldCableB) svcOldCableB.state = "terminated";

    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentASiteAddress],
          [esrWorkSiteContact, esrCurrentASiteContact],
        ])
      : undefined;

      const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
          [esrWorkSiteAddress, esrCurrentBSiteAddress],
          [esrWorkSiteContact, esrCurrentBSiteContact],
        ])
      : undefined;

    const svcInstallationA = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewASiteAddress],
      [esrWorkSiteContact, esrNewASiteContact],
    ]);

    const svcInstallationB = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
      [esrWorkSiteAddress, esrNewBSiteAddress],
      [esrWorkSiteContact, esrNewBSiteContact],
    ]);

    const soiNewCableA = svcNewCableA
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableA,
        })
      : undefined;

      const soiNewCableB = svcNewCableB
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableB,
        })
      : undefined;

    const soiInstallationA = svcInstallationA
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationA,
          serviceOrderItemRelationship: [soiNewCableA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiInstallationB = svcInstallationB
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationB,
          serviceOrderItemRelationship: [soiNewCableB]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //recovery of the equpment from old site can only be done after new installation completed
    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
          serviceOrderItemRelationship: [soiInstallationA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
          serviceOrderItemRelationship: [soiInstallationB]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCableA = svcOldCableA
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableA,
          serviceOrderItemRelationship: [soiInstallationA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiOldCableB = svcOldCableB
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableB,
          serviceOrderItemRelationship: [soiInstallationB]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecoveryA, soiInstallationA, soiOldCableA, soiNewCableA,soiRecoveryB, soiInstallationB, soiOldCableB, soiNewCableB].filter(
      (a) => a
    );
  }

  function decompose2C2PCeaseEquipmentRecovery(flatInstance) {
    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrASiteAddress],
          [esrWorkSiteContact,esrASiteContact]
        ])
      : undefined;

    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
        })
      : undefined;


      const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
          [esrWorkSiteAddress,esrBSiteAddress],
          [esrWorkSiteContact,esrBSiteContact]
        ])
      : undefined;

    const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
        })
      : undefined;


    return [soiRecoveryA,soiRecoveryB].filter((a) => a);
  }


  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}


/////// Schema Start
export function schemaC2CMetroE(props) {
  const { name, installationSpec, cableSpec,channels } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompos2C2PProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decompose2C2PAddVAS,
    },
  "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decompose2C2PExtRelocationHot,
    },

    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decompose2C2PUpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decompose2C2PUpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompose2C2PUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompose2C2PUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentASiteAddress,
        esrCurrentASiteContact,
        esrNewASiteAddress,
        esrNewASiteContact,
        esrCurrentBSiteAddress,
        esrCurrentBSiteContact,
        esrNewBSiteAddress,
        esrNewBSiteContact
      ],
      decompose: decompose2C2PExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: decompose2C2PCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [esrASiteAddress,esrASiteContact, esrBSiteAddress,esrBSiteContact],
      decompose: (flatInstance) => [],
    },
  };

  function decompos2C2PProvide(flatInstance) {

    const svcInstallationA=installationSpec.updateInstance({},flatInstance,
      [//mapping
        [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
        [esrWorkSiteAddress,esrASiteAddress],
        [esrWorkSiteContact,esrASiteContact]
      ]);

      const svcInstallationB=installationSpec.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
          [esrWorkSiteAddress,esrBSiteAddress],
          [esrWorkSiteContact,esrBSiteContact]
        ]);

        const svcCableA1 = svcspecCableConnectivityC2C.updateInstance(
          {},
          flatInstance,
       []
        );

        const svcCableB1 = svcspecCableConnectivityC2C.updateInstance(
          {},
          flatInstance,
        [   [chrspecFieldServiceAddressRole,mapperConst("B-Site")], 
          [esrASiteAddress, esrBSiteAddress],
            [esrBSiteContact, esrBSiteContact]]
        );
        svcCableB1.place[0].role="B-Site.Address";

       
        
        return [svcInstallationA, svcInstallationB, svcCableA1]
              .filter((a) => a)
              .map((svc) => (new ServiceOrderItem({action:"add",service:svc})));
  }

  function decompose2C2PAddVAS(flatInstance) {
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    console.debug(
      "schemaC2P:decompose Change Add VAS svcInstallation",
      svcInstallation
    );
    return [{ action: "add", service: svcInstallation }]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

 function decompose2C2PExtRelocationHot(flatInstance) {
    const svcCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

  
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiCable = svcCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcCable,
        })
      : undefined;
    

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiRecovery, soiCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecovery, soiInstallation, soiCable].filter((a) => a);
  }

  function decompose2C2PUpDownGradeHot(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    return [
      { action: "add", service: svcInstallation },
      { action: "add", service: svcRecovery },
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decompose2C2PUpDownGradeParallel(flatInstance) {

    const svcNewCableA = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
      [esrASiteAddress, esrASiteAddress],
      [esrASiteContact, esrASiteContact]
    ]);
	
	 const svcNewCableB = svcspecCableConnectivityC2PBSite?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
      [esrBSiteAddress, esrBSiteAddress],
      [esrBSiteContact, esrBSiteContact]
    ]);

    const svcOldCableA = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
      [esrASiteAddress, esrASiteAddress],
      [esrASiteContact, esrASiteContact]
    ]);
	
    if (svcOldCableA) svcOldCableA.state = "terminated";
	
	 const svcOldCableB = svcspecCableConnectivityC2PBSite?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
      [esrBSiteAddress, esrBSiteAddress],
      [esrBSiteContact, esrBSiteContact]
    ]);

 if (svcOldCableB) svcOldCableB.state = "terminated";
 
    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrASiteAddress],
          [esrWorkSiteContact, esrASiteContact],
        ])
      : undefined;
	  
	   const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
          [esrWorkSiteAddress, esrBSiteAddress],
          [esrWorkSiteContact, esrBSiteContact],
        ])
      : undefined;

    const svcInstallationA = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrASiteAddress],
      [esrWorkSiteContact, esrASiteContact],
    ]);
	
	 const svcInstallationB = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
      [esrWorkSiteAddress, esrBSiteAddress],
      [esrWorkSiteContact, esrBSiteContact],
    ]);

    const soiNewCableA = svcNewCableA
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableA,
        })
      : undefined;
	  
	  const soiNewCableB = svcNewCableB
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableB,
        })
      : undefined;

    const soiInstallationA = svcInstallationA
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationA,
          serviceOrderItemRelationship: [soiNewCableA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;
	  
	   const soiInstallationB = svcInstallationB
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationB,
          serviceOrderItemRelationship: [soiNewCableB]
            .filter((b) => b)
            .map((b) => soiRelationship(b, "Dependent On")),
        })
      : undefined;

    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
        })
      : undefined;
	  
	const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
        })
      : undefined;

    const soiOldCableA = svcOldCableA
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableA,
          serviceOrderItemRelationship: [soiInstallationA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;
	  
	   const soiOldCableB = svcOldCableB
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableB,
          serviceOrderItemRelationship: [soiInstallationB]
            .filter((b) => b)
            .map((b) => soiRelationship(b, "Dependent On")),
        })
      : undefined;

    return [soiRecoveryA, soiInstallationA, soiOldCableA, soiNewCableA, soiRecoveryB, soiInstallationB, soiOldCableB, soiNewCableB].filter(
      (a) => a
    );
  }

  function decompose2C2PExtRelocationParallel(flatInstance) {
    const svcNewCableA = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewASiteAddress],
      [esrASiteContact, esrNewASiteContact],
    ]);

    const svcNewCableB = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrBSiteAddress, esrNewBSiteAddress],
      [esrBSiteContact, esrNewBSiteContact],
    ]);

    const svcOldCableA = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCurrentASiteAddress],
      [esrASiteContact, esrCurrentASiteContact],
    ]);
    if (svcOldCableA) svcOldCableA.state = "terminated";

    const svcOldCableB = svcspecCableConnectivity2C2P?.updateInstance({}, flatInstance, [
      //mapping
      [esrBSiteAddress, esrCurrentBSiteAddress],
      [esrBSiteContact, esrCurrentBSiteContact],
    ]);
    if (svcOldCableB) svcOldCableB.state = "terminated";

    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentASiteAddress],
          [esrWorkSiteContact, esrCurrentASiteContact],
        ])
      : undefined;

      const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
          [esrWorkSiteAddress, esrCurrentBSiteAddress],
          [esrWorkSiteContact, esrCurrentBSiteContact],
        ])
      : undefined;

    const svcInstallationA = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewASiteAddress],
      [esrWorkSiteContact, esrNewASiteContact],
    ]);

    const svcInstallationB = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("B-Site")],
      [esrWorkSiteAddress, esrNewBSiteAddress],
      [esrWorkSiteContact, esrNewBSiteContact],
    ]);

    const soiNewCableA = svcNewCableA
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableA,
        })
      : undefined;

      const soiNewCableB = svcNewCableB
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableB,
        })
      : undefined;

    const soiInstallationA = svcInstallationA
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationA,
          serviceOrderItemRelationship: [soiNewCableA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiInstallationB = svcInstallationB
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationB,
          serviceOrderItemRelationship: [soiNewCableB]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    //recovery of the equpment from old site can only be done after new installation completed
    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
          serviceOrderItemRelationship: [soiInstallationA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
          serviceOrderItemRelationship: [soiInstallationB]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCableA = svcOldCableA
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableA,
          serviceOrderItemRelationship: [soiInstallationA]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiOldCableB = svcOldCableB
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCableB,
          serviceOrderItemRelationship: [soiInstallationB]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecoveryA, soiInstallationA, soiOldCableA, soiNewCableA,soiRecoveryB, soiInstallationB, soiOldCableB, soiNewCableB].filter(
      (a) => a
    );
  }

  function decompose2C2PCeaseEquipmentRecovery(flatInstance) {
    const svcRecoveryA = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrASiteAddress],
          [esrWorkSiteContact,esrASiteContact]
        ])
      : undefined;

    const soiRecoveryA = svcRecoveryA
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryA,
        })
      : undefined;


      const svcRecoveryB = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
          [esrWorkSiteAddress,esrBSiteAddress],
          [esrWorkSiteContact,esrBSiteContact]
        ])
      : undefined;

    const soiRecoveryB = svcRecoveryB
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecoveryB,
        })
      : undefined;


    return [soiRecoveryA,soiRecoveryB].filter((a) => a);
  }


  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}


const chrspecC2POrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING)
  ],
});


export function serviceSpec2C2P(props) {
  const { name, installationServiceSpec, cableSpec, characteristics,channels } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schema2C2P({
      name: "GW-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      channels: channels
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderType;

  return spec;
}

const chrspec2C2PNoHotOrDownUpdradeOrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    // chvspecString(orderTypeValues.HOT_UPGRADE),
    // chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});


export function serviceSpec2C2PAll(props) {
  const { name, installationServiceSpec, cableSpec, characteristics,channels } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schema2C2P({
      name: "2C2P-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      channels: channels
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspec2C2PNoHotOrDownUpdradeOrderType;

  return spec;
}




///////Metro E decom

export function serviceSpecC2CMetroE(props) {
  const { name, installationServiceSpec, cableSpec, characteristics,channels } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2C : cableSpec;

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2CMetroE({
      name: "C2CMetroE-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      channels: channels
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspec2C2PNoHotOrDownUpdradeOrderType;

  return spec;
}
