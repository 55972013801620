import { fetchText } from "./utils";
import { aadToken } from "../msal/auth";
import {decodeJWT} from "../common/DecodeJWT";
import {
  AUTHN_MICROSITE_API_ENDPOINT,
  REDEEM_TOKEN_ENDPOINT,
} from "./endpoints";

const MAX_TOKEN_AGE = 15 * 60 * 1000; //15 mins

class AuthenticationContext {
  user = null;
  //main authentication token
  mainToken = null;
  mainTokenPromise = null;
  // AAD Access Token
  aadAccessToken = null;

  isMainTokenValid = () =>
    this.mainToken && Date.now() - this.mainToken.issued < MAX_TOKEN_AGE;

  //Returns Promise
  getToken = () => {
    const token = this.isMainTokenValid()
      ? Promise.resolve(this.mainToken.token)
      : aadToken()
          .then((tokenResponse) => {
            console.log("token response ", tokenResponse);
			const eMail = decodeJWT(tokenResponse.idToken);
            this.user = {
              id: tokenResponse.account.username,
              name: tokenResponse.account.name,
              email: eMail,
            };
            return this.requestRedeemToken(tokenResponse.idToken);
          })
          .catch((error) => console.error("Unable to obtain AAD token", error));
    return token;
  };

  requestMicrositeToken(props) {
    const { serviceOrderItemIDs, appointmentIDs, token } = props;
    const tokenReq = {
      operatorClaim: {
        userId: this?.user?.id,
        displayName: this?.user?.name,
        email: this?.user?.email,
        authenticationAuthority: "LDAP",
      },
      appointmentClaim: [...appointmentIDs],
      serviceClaim: [...serviceOrderItemIDs],
    };

    return fetchText(AUTHN_MICROSITE_API_ENDPOINT, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Headers":
          "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(tokenReq),
    }).then((data) => data.replaceAll('"', ""));
  }

  requestRedeemToken(token) {
    this.mainTokenPromise =
      this.mainTokenPromise ??
      fetchText(REDEEM_TOKEN_ENDPOINT, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((data) => {
          const cleanToken = data.replaceAll('"', "");
          this.mainToken = { token: cleanToken, issued: Date.now() };
          return cleanToken;
        })
        .catch((error) => {
          console.error(
            "AuthenticationContext:authenticate\n%o\nCause:%o",
            error,
            error?.cause
          );
          return Promise.reject(
            new Error("Authentication failed", { cause: error })
          );
        })
        .finally(() => {
          this.mainTokenPromise = null;
        });

    return this.mainTokenPromise;
  }

  //Returns Promise
  getMicrositeToken(props) {
    return this.getToken()
      .then((token) => this.requestMicrositeToken({ ...props, token: token }))
      .catch((error) => {
        console.error(
          "AuthenticationContext:getMicrositeToken failed.\n%o\nCause:%o",
          error,
          error?.cause
        );
        return Promise.reject(
          new Error("Microsite token request failed", { cause: error })
        );
      });
  }
}

export const defaultAuthNContext = new AuthenticationContext();
