export class CharacteristicValueSpecification {
  //A string. When sub-classing, this defines the super-class.
  "@baseType" = null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation" = null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type" = "CharacteristicValueSpecification";
  //  A boolean. If true, the Boolean Indicates if the value is the default value for a characteristic.
  isDefault = false;
  //A string. An indicator that specifies the inclusion or exclusion of the valueFrom and valueTo attributes. If applicable, possible values are "open", "closed", "closedBottom" and "closedTop".
  rangeInterval = null;
  //A string. A regular expression constraint for given value.
  regex = null;
  //A string. A length, surface, volume, dry measure, liquid measure, money, weight, time, and the like. In general, a determinate quantity or magnitude of the kind
  //designated, taken as a standard of comparison for others of the same kind, in assigning to them numerical values, as 1 foot, 1 yard, 1 mile, 1 square foot.
  unitOfMeasure = null;
  //A time period. The period of time for which a value is applicable.
  validFor = {};
  //An any (Any). A discrete value that the characteristic can take on, or the actual value of the characteristic.
  value = null;
  //An integer. The low range value that a characteristic can take on.
  valueFrom = null;
  //An integer. The upper range value that a characteristic can take on.
  valueTo = null;
  //A string. A kind of value that the characteristic value can take on, such as numeric, text and so forth.
  valueType = null;

  constructor(pojo) {
    Object.entries(pojo).forEach((e) => {
      if (this.hasOwnProperty(e[0])) this[e[0]] = e[1];
      else
        console.error("CharacteristicValueSpecification unknown property", e);
    });
  }
}

export function chvspecString(val) {
  return new CharacteristicValueSpecification({
    value: val,
    valueType: "string",
  });
}
export function chvspecStringDefault(val) {
  return new CharacteristicValueSpecification({
    value: val,
    isDefault: true,
    valueType: "string",
  });
}
