import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecC2P, svcspecC2PNoHotUpgradeDowngrade } from "../schema/schemaC2P";
import { serviceSpec2C2PAll, serviceSpecC2CMetroE } from "../schema/schema2C2P";
import { svcspecInstallationMetroE } from "../catalogs/CatalogFieldServices";
import { chrspec } from "../service-catalog/CharacteristicSpecification";
import { svcspecC2C } from "../schema/schemaC2C";


const chrspecProductTypeMetroEPTMP = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "MetroEthernet - PTMP",
});

function metroETemplatePTMP(name) {
  return svcspecC2PNoHotUpgradeDowngrade({
    name: name,
    installationServiceSpec: svcspecInstallationMetroE,
    characteristics: [chrspecProductTypeMetroEPTMP],
  });
}


const chrspecProductTypeMetroEPTP = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "MetroEthernet - PTP",
});

function metroETemplatePTP300MBPSAbove(name) {
  return svcspecC2C({
    name: name,
    installationServiceSpec: svcspecInstallationMetroE,
    characteristics: [chrspecProductTypeMetroEPTP],
  });
}

//serviceSpec2C2PAll
function metroETemplatePTP(name, channels) {
  return serviceSpecC2CMetroE({ 
    name: name,
    installationServiceSpec: svcspecInstallationMetroE,
    characteristics: [chrspecProductTypeMetroEPTP],
    channels: channels,
  });
}







export const catalogMetroE = new ServiceCatalog()
  .category("MetroEthernet")
  .category("PTMP")
  .serviceSpecs([
    metroETemplatePTMP("Point-to-Multipoint MetroEthernet Service (Head-End)"),
    metroETemplatePTMP("Point-to-Multipoint MetroEthernet Service (Tail-End)"),
    metroETemplatePTMP("(Carrier Ethernet) Point-to-Multipoint MetroEthernet Service (Head-End)"),
    metroETemplatePTMP("(Carrier Ethernet) Point-to-Multipoint MetroEthernet Service (Tail-End)"),
    metroETemplatePTMP("Point-to-Multipoint MetroEthernet Service (Head-End) with Exchange Diversity"),
    metroETemplatePTMP("Point-to-Multipoint MetroEthernet Service (Tail-End) with Exchange Diversity"),
    metroETemplatePTMP("(Carrier Ethernet) Point-to-Multipoint MetroEthernet Service (Head-End) with Exchange Diversity"),
    metroETemplatePTMP("(Carrier Ethernet) Point-to-Multipoint MetroEthernet Service (Tail-End) with Exchange Diversity"),
    metroETemplatePTMP("(Carrier Ethernet) MetroEthernet Point-to-Multipoint Path Diversity (Headend)"),
    metroETemplatePTMP("(Carrier Ethernet) MetroEthernet Point-to-Multipoint Path Diversity (Tailend)"),
    metroETemplatePTMP("MetroEthernet Pt-to-MultiPt Path Diversity (Tail-End)"),
    metroETemplatePTMP("Pt-to-Multipt Metro Ethernet Tail-End Exch Div"),
    metroETemplatePTMP("Temp Pt-to-MultiPt MetroE Svc (Tail-End)"),
    metroETemplatePTMP("Temp Pt-to-Multipt MetroE Tail-End Exch Div"),
    metroETemplatePTMP("MetroEthernet Pt-to-MultiPt Path Diversity (Head-End)"),
    metroETemplatePTMP("Pt-to-Multipt Metro Ethernet Head-End Exch Div"),
    metroETemplatePTMP("Temp Pt-to-Multipt MetroE Head-End Exch Div"),
    metroETemplatePTMP("Temp Pt-to-MultiPt MetroE Svc (Head-End)"),
 
  ])
  .endCategory()
  .category("PTP(>=300MBPS)")
  .serviceSpecs([
    metroETemplatePTP("MetroE PTP(>=300MBPS)"),
    metroETemplatePTP("MetroE Intl Gateway Access Exch Div Subscription (>=300MBPS)"),
    metroETemplatePTP("MetroE Intl Gateway Access Path Diversity (>=300MBPS)"),
    metroETemplatePTP("SingNet MetroEthernet Access (>=300MBPS)"),
    metroETemplatePTP("SingNet MetroEthernet Access Combo Bandwidth (>=300MBPS)"),
    metroETemplatePTP("SingNet MetroEthernet Access with Exchange Diversity (>=300MBPS)"),
    metroETemplatePTP("SingNet MetroEthernet Access (Path Diversity) (>=300MBPS)"), 
    metroETemplatePTP("Point-to-Point MetroEthernet Service (>=300MBPS)"),
    metroETemplatePTP("(Carrier Ethernet) Point-to-Point MetroEthernet Service (>=300MBPS)"),
    metroETemplatePTP("Point-to-Point MetroEthernet Service with Exchange Diversity (>=300MBPS)"),
    metroETemplatePTP("(Carrier Ethernet) Point-to-Point MetroEthernet Service with Exchange Diversity (>=300MBPS)"),
    metroETemplatePTP("(Carrier Ethernet) MetroEthernet Point-to-Point Path Diversity (>=300MBPS)"),
  ])
  .endCategory()


  .category("PTP(<300MBPS)")
  .serviceSpecs([
    metroETemplatePTP("MetroE PTP(<300MBPS)",1),
    metroETemplatePTP("MetroE Intl Gateway Access Exch Div Subscription (<300MBPS)",1),
    metroETemplatePTP("MetroE Intl Gateway Access Path Diversity(<300MBPS)",1),
    metroETemplatePTP("SingNet MetroEthernet Access (<300MBPS)",1),
    metroETemplatePTP("SingNet MetroEthernet Access Combo Bandwidth (<300MBPS)",1),
    metroETemplatePTP("SingNet MetroEthernet Access with Exchange Diversity (<300MBPS)",1),
    metroETemplatePTP("SingNet MetroEthernet Access (Path Diversity) (<300MBPS)",1), 
    metroETemplatePTP("Point-to-Point MetroEthernet Service (<300MBPS)",1),
    metroETemplatePTP("(Carrier Ethernet) Point-to-Point MetroEthernet Service (<300MBPS)",1),
    metroETemplatePTP("Point-to-Point MetroEthernet Service with Exchange Diversity (<300MBPS)",1),
    metroETemplatePTP("(Carrier Ethernet) Point-to-Point MetroEthernet Service with Exchange Diversity (<300MBPS)",1),
    metroETemplatePTP("(Carrier Ethernet) MetroEthernet Point-to-Point Path Diversity (<300MBPS)",1),
  ])
  .endCategory()
  .endCategory();
