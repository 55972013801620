import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecC2P } from "../schema/schemaC2P";
import { svcspecBAccessEquipmentInstallation } from "./CatalogFieldServices";
import { chrspecFtthNo, chrspecProductTypeOf } from "./CharacteristicsCatalog";


const chrspecProductTypeBAccess = chrspecProductTypeOf("B-Access");


function bAccessTemplate(name) {
  return svcspecC2P({
    name: name,
    characteristics: [chrspecProductTypeBAccess, chrspecFtthNo],
    installationServiceSpec: svcspecBAccessEquipmentInstallation,
  });
}


export const CatalogBAccess = new ServiceCatalog()
  .category("B-Access")
  .serviceSpecs([
    bAccessTemplate("B-Access"),
    bAccessTemplate("B-Access NLT"),
    bAccessTemplate("B-Access (Trial)"),
    bAccessTemplate("Access (Trial)"),
    bAccessTemplate("B-Access (300 Mbps)"),
    bAccessTemplate("B-Access NLT (300 Mbps)"),
    bAccessTemplate("B-Access (Glite-SingNet)"),
    bAccessTemplate("B-Access (Full Rate-SingNet)"),
    bAccessTemplate("B-Access (Full Rate-Qala)"),
    bAccessTemplate("B-Access (Glite-BCS)"),
    bAccessTemplate("B-Access (Full Rate-LGA)"),
    bAccessTemplate("B-Access L2TP (Full Rate-Equant)"),
    bAccessTemplate("B-Access (Full Rate-ViewQwest)"),
    bAccessTemplate("B-Access (FullRate-SingNet) $90 Incentive Promo (Apr 2002)"),
    bAccessTemplate("B-Access Promo (FullRate-PacNet) $60 Incentive Promo(May2002)"),
    bAccessTemplate("B-Access Sym 512K (SingNet)"),
    bAccessTemplate("B-Access 4M (SingNet)"),
    bAccessTemplate("B-Access 10M Biz (SingNet)"),
    bAccessTemplate("B-Access 4M (M1)"),
    bAccessTemplate("B-Access 4M (StarHub)"),
    bAccessTemplate("B-Access (Multiple ISP)"),
    bAccessTemplate("B-Access 10M (SingNet)"),
    bAccessTemplate("B-Access 25M (SingNet)"),
    bAccessTemplate("B-Access 10M Biz (M1)"),
    bAccessTemplate("B-Access (SingNet Multiple Accounts)"),

  ])
  .endCategory();
