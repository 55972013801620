import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecGenericEquipmentInstallation, svcspecGigawaveEquipmentInstallation } from "./CatalogFieldServices";
import { chrspecProductTypeOf } from "./CharacteristicsCatalog";
import { gigawavwServiceSpec2C2P } from "../schema/schemaGigawave";
import { gigawavwServiceSpec2C2PDc2Dc } from "../schema/schemaGigawaveDc2Dc";
import { svcspecGenericCableConnectivity } from "./CatalogCableConnectivity";



function gigawaveTemplate2C2P(name, productType,channels) {
  return gigawavwServiceSpec2C2P({
    name: name,
    characteristics: [productType],
    installationServiceSpec: svcspecGigawaveEquipmentInstallation,
    channels: channels,
   
    genericInstallationsA: new Map()
    .set("Monitoring A", svcspecGenericEquipmentInstallation)
    .set("DWDM Fibre Main", svcspecGenericEquipmentInstallation),
    genericInstallationsB: new Map()
    .set("Monitoring B", svcspecGenericEquipmentInstallation)
    .set("DWDM Fibre Main", svcspecGenericEquipmentInstallation),
    standBy: new Map()
    .set("DWDM Fibre Standby", svcspecGenericEquipmentInstallation),




    genericConnectivityA: new Map()
    .set("Monitoring A", svcspecGenericCableConnectivity)
    .set("DWDM Fibre Main", svcspecGenericCableConnectivity),
    genericInstallationsB: new Map()
    .set("Monitoring B", svcspecGenericCableConnectivity)
    .set("DWDM Fibre Main", svcspecGenericCableConnectivity),
    standBy: new Map()
    .set("DWDM Fibre Standby", svcspecGenericCableConnectivity)
  });
}

function gigawaveTemplate2C2PDc2Dc(name, productType,channels) {
  return gigawavwServiceSpec2C2PDc2Dc({
    name: name,
    characteristics: [productType],
    installationServiceSpec: svcspecGigawaveEquipmentInstallation,
    genericEquipmentInstallation: svcspecGenericEquipmentInstallation, 
    channels: channels
  });
}


export const CatalogGigawave = new ServiceCatalog()
  .category("Gigawave")
  .category("Gigawave - Standard")
  .serviceSpecs([
    gigawaveTemplate2C2P("Gigawave Standard", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Cross Connect - SM/MM Fibre", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Cross Connect - UTP", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave (DWDM Ring)", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave (Temp Svc)", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Exchange Diversity", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Exchange Diversity (Temp Svc)", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Exchange Diversity IGA", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Exchange Diversity Standby", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Exchange Diversity Standby (Active Usage)", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave IGA", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Path Diversity", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Path Diversity (Temp Svc)", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Path Diversity IGA", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Standby", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Standby (Active Usage)", chrspecProductTypeOf("Gigawave - Gigawave"),1),
    gigawaveTemplate2C2P("Gigawave Ultra Low Latency", chrspecProductTypeOf("Gigawave - Gigawave"),1),
   ])
.endCategory()

.category("Gigawave - Enhanced")
.serviceSpecs([
  gigawaveTemplate2C2P("Enhanced Gigawave", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Cross Connect - SM/MM Fibre", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Cross Connect - UTP", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave (DWDM Ring)", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave (Temp Svc)", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Exchange Diversity", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Exchange Diversity (Temp Svc)", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Exchange Diversity IGA", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Exchange Diversity Standby", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Exchange Diversity Standby (Active Usage)", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave IGA", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Path Diversity", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Standby", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
  gigawaveTemplate2C2P("Enhanced Gigawave Standby (Active Usage)", chrspecProductTypeOf("Gigawave - Enhanced Gigawave"),2),
 ])
.endCategory()


.category("Gigawave - DC to DC")
.serviceSpecs([
  gigawaveTemplate2C2PDc2Dc("Gigawave DC to DC", chrspecProductTypeOf("Gigawave - Gigawave DC to DC"),1),
  gigawaveTemplate2C2PDc2Dc("Cross Connect - SM/MM Fibre", chrspecProductTypeOf("Gigawave - Gigawave DC to DC"),1),
  gigawaveTemplate2C2PDc2Dc("Cross Connect - UTP", chrspecProductTypeOf("Gigawave - Gigawave DC to DC"),1),
  gigawaveTemplate2C2PDc2Dc("Gigawave Data Connect", chrspecProductTypeOf("Gigawave - Gigawave DC to DC"),1),
 ])
.endCategory()

  .endCategory();
