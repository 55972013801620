const API_ROOT = window?.STABEnvironment?.apiRoot;
console.debug("API_ROOT_EP ", API_ROOT);

export const AUTHN_MICROSITE_API_ENDPOINT = `${API_ROOT}/cs-dff-dc-authorization/aad/authorization/generateJWT`;
export const REDEEM_TOKEN_ENDPOINT = `${API_ROOT}/cs-dff-dc-authorization/aad/redeemaadtoken`;
export const UEN_ENDPOINT = `${API_ROOT}/uen`;

export const DOCUMENT_UPLOAD_ENDPOINT = `${API_ROOT}/cs-appt-document-upload/upload/report`;
export const DOCUMENT_DELETE_ENDPOINT = `${API_ROOT}/cs-appt-document-upload/delete/file`;

export const SERVICE_ORDER_API_ENDPOINT = `${API_ROOT}/cs-dff-dc-service-ordering/appointment-platform/serviceOrder`;
export const CANCEL_SERVICE_ORDER = `${API_ROOT}/cs-dff-dc-service-ordering/appointment-platform/cancelServiceOrder`;

export const APPOINTMENTS_URL = `${API_ROOT}/cs-dff-dc-appointment/appointment-platform/appointment`;
export const EMAIL_API_ENDPOINT = `${API_ROOT}/cs-dff-dc-communication/v1/rest/notification/communicationMessage/send`;

export const MICROSITE_URL = `${API_ROOT}/qs/microsite/`;

export const DOCUMENT_DOWNLOAD_URL = `${window?.STABEnvironment.sharepoint.url}`;

export const FTTH_RESOURCE_ORDER_URL = `${API_ROOT}/cs-dff-dc-resource-inventory-01/business/resourceinventory/resourceOrder`;

export const FTTH_NO_GENERATOR_URL_POOL0103 = `https://singteldff-uatrel3.crm5.dynamics.com/api/data/v9.2/ftthno_ftthnumbers?$top=10&$filter=ftthno_status eq 704670000 and ftthno_pool eq 'FTH0103'&$select=ftthno_name,ftthno_ftthnumberid,ftthno_status`;
export const FTTH_NO_GENERATOR_URL_POOL0102 = `https://singteldff-uatrel3.crm5.dynamics.com/api/data/v9.2/ftthno_ftthnumbers?$top=10&$filter=ftthno_status eq 704670000 and ftthno_pool eq 'FTH0102'&$select=ftthno_name,ftthno_ftthnumberid,ftthno_status`;
export const FTTH_ALLOCATE_URL = `https://singteldff-uatrel3.crm5.dynamics.com/api/data/v9.2/ftthno_ftthnumbers`;