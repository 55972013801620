import { masterServiceCatalog } from "../catalogs/MasterCatalog";
import { ServiceOrder } from "../serviceOrder/serviceOrder";
import { fetchText } from "./utils";
import { RefAsValue } from "../service-catalog/EntitySpecificationRelationship";
import { v4 as uuid } from "uuid";
import { defaultAuthNContext } from "./authorization";
import { dffPatchCreateSORequest } from "./DFFPatch";
import { Characteristic } from "../service/Characteristic";
import { SERVICE_ORDER_API_ENDPOINT } from "./endpoints";
import { Individual } from "../party/Party";

const catalog = masterServiceCatalog;

export class ServiceOrderContext {
  //Create SO promise
  soPromise = null;
  //Service Order As Requested. Instance of ServiceOrder class
  serviceOrder = null;
  //Service Order Request JSON
  requestBody = null;
  //Service Order Response JSON
  responseBody = null;
  //parsed response body
  responseAsObject = null;
  //Status of service ordering
  soState = "New"; // New/In Progress/Success/Failed
  //SOIs with appointments
  soiWithAppointment = null;

  constructor(pojo) {
    Object.entries(pojo ?? {}).forEach((e) => {
      if (this.hasOwnProperty(e[0])) this[e[0]] = e[1];
      else console.error("ServiceOrderContext unknown property", e);
    });
  }
}

//we have to patch request to address number of inconsitencies in DFF APIs implementation

async function processSOResponse() {}

//TODO: Async decomposition via promises
function decomposeServices(cfsContexts) {
  const finalObject = Object.entries(cfsContexts)?.flatMap(
    ([id, cfsContext]) => {
      const so = catalog
        .getServiceSpec(cfsContext.service.serviceSpecification.id)
        .decompose(cfsContext.service);
      //adding  customer facing services as characteristic  Note: externalId can't be used here from
      so?.filter(f => !f.service?.serviceCharacteristic?.find((ac) => ac.name === "Site ID"))
      .forEach((orderItem) => {
        orderItem?.service?.serviceCharacteristic?.push(
          new Characteristic({
            "@type": "Characteristic",
            name: "Site ID",
            value: id,
            valueType: "string",
          })
        );
      });
      return so;
    }
  );
  return finalObject;
}

function createUserAccountInfo(username, email) {
  const userAccountInfo = new Individual({
    givenName: username,
    familyName: username,
    emailAddress: email,
    phoneNumber: "98765431",
  });

  return userAccountInfo;
}
function constructServiceOrder(
  cfsContexts /*CFS Service contexts map (object)*/,
  customerOrganization /*instance of Organization*/,
  userInfo,
  orderParties /*array of partyRefValue */
) {
  const serviceOrder = new ServiceOrder({
    externalId: "STD-APPT-" + uuid().toString(),
    relatedParty: [
      ...orderParties,
      new RefAsValue({
        role: "Customer Organization",
        value: customerOrganization,
      }),
      //TODO: test stub replace with actual user information
      new RefAsValue({ role: "Account Manager", value: userInfo}),
    ],
    externalReference: [
      {
        externalReferenceType: "Source System",
        name: "Standalone AB",
      },
    ],
    serviceOrderItem: decomposeServices(cfsContexts),
  });

  return serviceOrder;
}

//intiates async Create Service Order call. Updates soPromise in context.
export function createServiceOrder(
  cfsContexts /*CFS Service contexts map (object)*/,
  customerOrganization /*instance of Organization*/,
  userName, 
  email,
  orderParties /*array of partyRefValue */
) {
  console.debug(
    "createServiceOrder",
    cfsContexts,
    customerOrganization,
    orderParties
  );

  const userInfo = createUserAccountInfo(userName, email);
  const orderContext = new ServiceOrderContext();

      orderContext.serviceOrder = constructServiceOrder(
        cfsContexts,
        customerOrganization,
        userInfo,
    orderParties
      );

  console.info("createServiceOrder:Service Order", orderContext.serviceOrder);

      //we have to patch request to address number of inconsitencies in DFF APIs implementation
      //we serialize original service order to JSON to create it's deep copy before patching
      orderContext.requestBody = dffPatchCreateSORequest(
        JSON.stringify(orderContext.serviceOrder, (key, value) =>
          !value ? undefined : value
        )
      );
      console.debug("startServiceOrdering requesting", orderContext);

  orderContext.promise = defaultAuthNContext
    .getToken()
    .then((token) =>
      fetchText(SERVICE_ORDER_API_ENDPOINT, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          //BUG: DFFSO-1 INCOMPLIANCE: DFF specific username to be populated in service order request
          username: "svc-app-dffd365-nprd",
          Authorization: "Bearer " + token,
        },
        body: orderContext.requestBody,
    })
    )
    .then((data) => {
      console.debug("CreateServiceOrder:Data Received", data);
      orderContext.responseBody = data;
      orderContext.responseAsObject =
        data && data !== "" ? JSON.parse(data) : null;
      orderContext.soiWithAppointment =
        orderContext?.responseAsObject?.orderItem.filter(
          (soi) => soi?.service?.appointment
        );
      orderContext.soState = "Success";
    })
    .catch((error) => {
      console.error(
        "startServiceOrdering:ERROR\n%o,\nCause:%o",
        error,
        error?.cause
      );
      orderContext.soState = "Failed";
      orderContext.soStateError = error;
    });

  return orderContext;

  //TODO: Future improvement  create SO call for  incomplete services. To address cases when create SO failed partially or service added after requesting.
  //BUG: INCOMPLIANCE appointment mispaplaced under service instead of SOI
}
