import ServiceCatalog from "../service-catalog/ServiceCatalog"
import {svcspecC2P} from "../schema/schemaC2P"
import {svcspecInstallationDiginet} from '../catalogs/CatalogFieldServices'
import {chrspecProductTypeOf} from './CharacteristicsCatalog'
import { serviceSpec2C2P } from "../schema/schema2C2P";

// verifiec
const chrspecProductTypeDigiLinkPTMP=chrspecProductTypeOf("DigiNet - DigiLink PTMP");
const digiLinkPTMPTemplateC2P = (name) =>
  svcspecC2P({
    name: name,
    installationServiceSpec: svcspecInstallationDiginet,
    characteristics: [chrspecProductTypeDigiLinkPTMP],
  });

//Verified
  const chrspecProductTypeDigiLinkPTP=chrspecProductTypeOf("DigiNet - DigiLink PTP");
  const digiLinkPTPTemplateC2P = (name) =>
  svcspecC2P({
    name: name,
    installationServiceSpec: svcspecInstallationDiginet,
    characteristics: [chrspecProductTypeDigiLinkPTP],
  });  
  const digiLinkPTPTemplate2C2P = (name) =>
  serviceSpec2C2P({
    name: name,
    installationServiceSpec: svcspecInstallationDiginet,
    characteristics: [chrspecProductTypeDigiLinkPTP],
  });  

//Verified
  const chrspecProductTypeDigiPlusPTMPCE=chrspecProductTypeOf("DigiNet - DigiPlus PTMP (CE)");
  const digiPlusPTMPTemplatePTMPCE = (name) =>
  svcspecC2P({
    name: name,
    installationServiceSpec: svcspecInstallationDiginet,
    characteristics: [chrspecProductTypeDigiPlusPTMPCE],
  });


  const chrspecProductTypeDigiPlusPTP=chrspecProductTypeOf("DigiNet - DigiPlus PTP (CE)");
  const digiPlusPTPTemplateC2C = (name) =>
  svcspecC2P({
    name: name,
    installationServiceSpec: svcspecInstallationDiginet,
    characteristics: [chrspecProductTypeDigiPlusPTP],
  });


  //Verified
 export const catalogDigiNet=((new ServiceCatalog())
  .category("DigiNet")
    .category("DigiLink PTMP")
      .serviceSpecs([
        digiLinkPTMPTemplateC2P("(CE) DigiLink PTMP High Speed Grooming Tail-End (2M)"),
        digiLinkPTMPTemplateC2P("(CE) DigiLink PTMP High Speed Grooming Head-End (155M)"),
        digiLinkPTMPTemplateC2P("(CE) DigiLink PTMP High Speed Grooming Tail-End (Nx64Kbps)"),
        digiLinkPTMPTemplateC2P("DigiNet High Speed Grooming Nx2Mbps into 155Mbps"),
        digiLinkPTMPTemplateC2P("DigiNet High Speed Grooming Nx45Mbps into 155Mbps"),
       ])
    .endCategory()
    //Verified
    .category("DigiLink PTP")
    .serviceSpecs([
        digiLinkPTPTemplateC2P("EthernetLink Tail End 2M Grooming"),
        digiLinkPTPTemplateC2P("RIO Change of Interface"),
        digiLinkPTPTemplateC2P("RIO DigiPlus Base Network"),
        digiLinkPTPTemplateC2P("DigiPlus Base Network"),
        digiLinkPTPTemplateC2P("DigiPlus with MDF (Master End)"),
        digiLinkPTPTemplateC2P("DigiPlus with ILC Customer End"),
        digiLinkPTPTemplateC2P("DigiPlus with ILC Gateway"),
        digiLinkPTPTemplateC2P("DigiPlus Grooming Channel"),
        digiLinkPTPTemplateC2P("DigiLink with ILC Tail End (128K to 622M) (SDH Copper)"),
        digiLinkPTPTemplateC2P("Diginet High Speed Grooming Head End"),
        digiLinkPTPTemplateC2P("DigiNet High Speed Grooming Tail End (Nx2M to 45/155/622M) [SDH Copper]"),
        digiLinkPTPTemplateC2P("Diginet High Speed Grooming Tail End (Nx64 to 45/155M) from 64 to 128K"),
        digiLinkPTPTemplateC2P("Diginet High Speed Grooming Tail End (Nx64 to 45/155M) from 192 to 1984K"),
        digiLinkPTPTemplateC2P("DRP Testing Job"),
        digiLinkPTPTemplateC2P("Installation of NTU"),
        digiLinkPTPTemplateC2P("IR Line with NTU/DIU"),
        digiLinkPTPTemplateC2P("Switching of Diginet Circuit"),
        digiLinkPTPTemplateC2P("Diginet High Speed Grooming Tail End (Nx45M to 45/155M)"),
        digiLinkPTPTemplateC2P("Network Terminating Eqpt Sale (128k & below) "),
        digiLinkPTPTemplateC2P("DigiNet Low Speed Grooming Tail End"),
        digiLinkPTPTemplateC2P("DigiNet Low Speed Grooming Head End"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Main"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) ILC Gateway Access"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Intl Gateway Access (C+IP/STIX)"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Designated Reroute Access and Surcharge"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Low Speed Grooming Head End Access"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Exchange Diversity Access"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Low Speed Grooming Tail End Access"),
        digiLinkPTPTemplateC2P("WebNet Main (CE)"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Change of Channel"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Internal Relocation"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) DRP Testing"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Eqpt Installation/ Internal Removal"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Equipment Maintenance"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Internal Cable"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Network Terminating Eqpt"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Overtime"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Reconfiguration"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Switching of Circuit from Active to Standby (and vice versa)"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Visitation, Testing or Standby"),
        digiLinkPTPTemplateC2P("WebNet (CE) Express Relocation"),
        digiLinkPTPTemplateC2P("WebNet (CE) 128kbps Internal Removal"),
        digiLinkPTPTemplateC2P("WebNet (CE) Relocation"),
        digiLinkPTPTemplateC2P("WebNet (CE) Low Speed Grooming Head End Access Relocation"),
        digiLinkPTPTemplateC2P("WebNet (CE) Low Speed Grooming Tail End Access Relocation"),
        digiLinkPTPTemplateC2P("WebNet (CE) Low Speed Grooming Tail/Head End Access Express Relocation"),
        digiLinkPTPTemplateC2P("WebNet (CE) Overtime"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) VSAT"),
        digiLinkPTPTemplateC2P("DigiPlus Base Network with Exchange Diversity"),
        digiLinkPTPTemplateC2P("DigiPlus (CE) Path Diversity Access"),
        digiLinkPTPTemplateC2P("DigiLink with ILC Tail End With Exchange Diversity (128K to 622M) (SDH Copper)"),
        digiLinkPTPTemplateC2P("DigiLink with ILC Tail End With Exchange Diversity (128K to 622M) (SDH Fibre)"),
        digiLinkPTPTemplateC2P("DigiNet High Speed Grooming Tail End (Nx2M to 45/155/622M) [SDH Fibre]"),
        digiLinkPTPTemplateC2P("DigiLink with ILC Tail End (128K to 622M) (SDH Fibre)"),

        //2c2p
        digiLinkPTPTemplate2C2P("(CE) DigiLink PTP"),
        digiLinkPTPTemplate2C2P("(CE) DigiLink PTP with One-End Exchange Diversity"),
        digiLinkPTPTemplate2C2P("(CE) DigiLink PTP with Both-Ends Exchange Diversity"),
        digiLinkPTPTemplate2C2P("(CE) DigiLink PTP Path Diversity"),
        digiLinkPTPTemplate2C2P("DigiNet Main"),
        digiLinkPTPTemplate2C2P("DigiNet Main (Monthly Contract)"),
        digiLinkPTPTemplate2C2P("DigiNet Main (Temp Svc)"),
        digiLinkPTPTemplate2C2P("DigiNet Main Nx64kbps"),
        digiLinkPTPTemplate2C2P("DigiNet Main Nx64kbps (Temp Svc)"),
        digiLinkPTPTemplate2C2P("DigiNet Main Nx64kbps With Both Ends Exchange Diversity (Temp Svc)"),
        digiLinkPTPTemplate2C2P("DigiNet Main Nx64kbps With One End Exchange Diversity (Temp Svc)"),
        digiLinkPTPTemplate2C2P("DigiNet Main Path Diversity"),
        digiLinkPTPTemplate2C2P("DigiNet Main Path Diversity Nx64kbps"),
        digiLinkPTPTemplate2C2P("DigiNet Main With Both Ends Exchange Diversity"),
        digiLinkPTPTemplate2C2P("DigiNet Main With Both Ends Exchange Diversity (Interface Protection)"),
        digiLinkPTPTemplate2C2P("DigiNet Main With Both Ends Exchange Diversity (Temp Svc)"),
        digiLinkPTPTemplate2C2P("DigiNet Main With Both Ends Exchange Diversity Nx64kbps"),
        digiLinkPTPTemplate2C2P("DigiNet Main with Interface Protection"),
        digiLinkPTPTemplate2C2P("DigiNet Main With One End Exchange Diversity"),
        digiLinkPTPTemplate2C2P("DigiNet Main With One End Exchange Diversity (Temp Svc)"),
        digiLinkPTPTemplate2C2P("DigiNet Main With One End Exchange Diversity Interface Protection"),
        digiLinkPTPTemplate2C2P("DigiNet Main With One End Exchange Diversity Nx64kbps"),
        digiLinkPTPTemplate2C2P("DigiNet Offshore"),
        digiLinkPTPTemplate2C2P("Network Terminating Eqpt External Removal"),



//C2P + P2P(Provider-to-provider) disables will fix later
/*
digiLinkPTPTemplate2C2P("DigiNet ILC Gateway Access"),
digiLinkPTPTemplate2C2P("DigiNet ILC Gateway Access Monthly Contract (384kbps and above)"),
digiLinkPTPTemplate2C2P("DigiNet ILC Gateway Access with Interface Protection"),
digiLinkPTPTemplate2C2P("DigiNet ILC Gway Access With Both Ends Exchange Diversity"),
digiLinkPTPTemplate2C2P("DigiNet ILC Gway Access With Both Ends Exchange Diversity (Interface Protection)"),
digiLinkPTPTemplate2C2P("DigiNet ILC G'way Access With Both Ends Exchange Diversity Monthly Contract"),
digiLinkPTPTemplate2C2P("DigiNet ILC G'way Access With One End Exchange Diversity"),
digiLinkPTPTemplate2C2P("DigiNet ILC G'way Access With One End Exchange Diversity (Interface Protection)"),
digiLinkPTPTemplate2C2P("DigiNet ILC G'way Access With One End Exchange Diversity Monthly Contract"),
*/
     ])
  .endCategory()

  // Disabled in Prod
//   .category("DigiPlus PTMP")
//   .serviceSpecs([
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Customer Node"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Customer Node Express Installation"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Customer Node Installation"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Installation"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Low Speed Grooming Head End Access"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Low Speed Grooming Head End Access (Temp Svc)"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Low Speed Grooming Tail End Access"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Low Speed Grooming Tail End Access (Temp Svc)"),
//     digiPlusPTMPTemplatePTMPCE("DigiNet (DigiPlus PTMP) Terminating Eqpt installation"),
//    ])
// .endCategory()

// Disabled in Prod
// .category("DigiPlus PTP")
//   .serviceSpecs([
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Designated Reroute Access"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Designated Reroute Access (Temp Svc)"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Exchange Diversity Access"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Exchange Diversity Access (Temp Svc)"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Half Access"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Half Access (Temp Svc)"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Half Access Monthly Contract"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) ILC Gateway Access"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) ILC Gateway Access Monthly Contract"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Installation"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Intl Gateway Access (C+IP/STIX)"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Offshore"),
//     digiPlusPTPTemplateC2C("DigiNet (DigiPlus PTP) Offshore (Temp Svc)"),
//     digiPlusPTPTemplateC2C("DigiPlus (DigiPlus PTP) Path Diversity Access"),
    
//    ])
// .endCategory()

  .endCategory());
