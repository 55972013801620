import {
  message,
  Button,
  Upload,
  Divider,
  Row,
  Col,
  Select,
  Modal,
  Input,
  Space,
  Skeleton,
  Empty,
} from "antd";
import {
  UploadOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { fetchText } from "../api/utils";
import { useEffect, useRef, useState } from "react";
import { UploadedDocumentView } from "./UploadedDocumentView";
import {
  DOCUMENT_DELETE_ENDPOINT,
  DOCUMENT_UPLOAD_ENDPOINT,
} from "../api/endpoints";
import { defaultAuthNContext } from "../api/authorization";
export function DocumentPanel(props) {
  const {
    onWizardBack,
    onWizardNext,
    supportingDocuments,
    setSupportingDocuments,
  } = props;

  const token = useRef();

  let dockind = "Unknown";
  let documntNotes = "No Notes";
  const [isModalOpen, setIsModalOpen] = useState(false);

  function updateDocumentList(document) {
    setSupportingDocuments([...supportingDocuments, document]);
  }

  function deleteDocument(document) {
    var tempDocuments = [...supportingDocuments];
    var index = tempDocuments.indexOf(document);
    if (index !== -1) {
      defaultAuthNContext.getToken().then((tkn) => {
        console.log('deleteDocument tkn ---> ', tkn)
        fetchText(
          `${DOCUMENT_DELETE_ENDPOINT}/${document.response.driveItemId}`,
          {
            method: "DELETE",
            Accept: "*/*",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tkn}`,
            }
          }
           
        ).then((data) => {
          if (data?.trim() === "Success") {
            tempDocuments.splice(index, 1);
            console.log(
              "After deleting files " + JSON.stringify(tempDocuments)
            );
            setSupportingDocuments(tempDocuments);
            message.success(`${document.name} deleted successfully`, 4);
          } else {
            message.error(`Error in deleting ${document.name} `, 5);
          }
        });
      });
    }
  }

  function handleDeleteFile(document) {
    const index = supportingDocuments.indexOf(document);
    if (index > -1) {
      console.log("Before removoing item" + supportingDocuments.length);
      const remainingItems = supportingDocuments.splice(index, 1);
      console.log("Aftre removoing item" + supportingDocuments.length);
      updateDocumentList(remainingItems);
    }
  }

  function handleOpenModel() {
    defaultAuthNContext.getToken().then((tkn) => {
      token.current = tkn;
      setIsModalOpen(true);
    });
  }

  const fileUploadProperties = {
    method: "POST",
    showRemoveIcon: true,

    action: DOCUMENT_UPLOAD_ENDPOINT, //FQU
    headers: {
      Authorization: `Bearer ${token.current}`,
      Accept: "*/*",
    },
    itemRender: (originNode, file, currFileList) => {
      return null;
    },
    onRemove: (file) => {
      return null;
    },
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        message.error("Document must smaller than 4MB!");
        return isLt2M;
      } else {
        file.dockind = dockind;
        file.documentNotes = documntNotes;
        setLoadings(true);
      }
    },
    onChange: (data) => {
      console.log(JSON.stringify(data));
      if (data.file.status === "done") {
        message.success(`${data.file.name}  uploaded successfully`, 4);
        handleCancel();
        updateDocumentList?.(data.file);
      } else if (data.file.status === "error") {
        message.error(`Error in uploading ${data.file.name}`, 4);
        handleCancel();
      }
    },
  };

  const handleCancel = () => {
    setLoadings(false);
    setIsModalOpen(false);
  };

  const [isLoading, setLoadings] = useState(false);

  return (
    <>
      <Row align="middle">
        <Col flex="1 1">
          <Divider className="compact-divider">Supporting Documents</Divider>
        </Col>
      </Row>

      <Row gutter={[8, 3]}>
        <Col span="24">
          {
            <UploadedDocumentView
              supportingDocuments={supportingDocuments}
              handleDeleteFile={handleDeleteFile}
              updateDocumentList={updateDocumentList}
              deleteDocument={deleteDocument}
              showDeleteButton={true}
            />
          }


<Empty image={Empty.PRESENTED_IMAGE_DEFAULT} 
          hidden={Object.entries(supportingDocuments)?.length !== 0}
          description={
            <span style={{ fontSize: '20px', color: 'blue', fontWeight: 'bold'}}>
              Uploading documents is not mandatory. This feature will be removed in the future, so you may choose to skip it.
            </span>
          }
          />
        </Col>
      </Row>

      <Row align="middle">
        <Col flex="0 0">
          <Button
            onClick={() => handleOpenModel()}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="large"
          />
        </Col>
        <Col flex="1 1">
          <Divider className="compact-divider"></Divider>
        </Col>
      </Row>

      <Row gutter={[8, 0]}>
        <Col flex="1 1" />
        {/* <Col flex="0 0">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={onWizardBack}
          >
            <DoubleLeftOutlined />
            Return
          </Button>
        </Col> */}
        <Col flex="0 0">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={onWizardNext}
           
          >
            Proceed
            <DoubleRightOutlined />
          </Button>
        </Col>
      </Row>

      <Modal
        title="File Upload"
        open={isModalOpen}
        closable={!isLoading}
        onCancel={handleCancel}
        footer={null}
        keyboard={true}
        width={400}
        destroyOnClose={true}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          <Row>
            <Col flex="5 0">
              <Select
                defaultValue="Select document type"
                onChange={(value) => (dockind = value)}
                options={[
                  {
                    label: "SRCA",
                    value: "SRCA",
                  },
                  {
                    label: "Email",
                    value: "Email",
                  },
                  {
                    label: "Order Form",
                    value: "Order Form",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col flex="5 0">
              <Input
                placeholder="Document remarks"
                maxLength={35}
                onKeyUp={(event) => (documntNotes = event.target.value)}
              />
            </Col>
          </Row>

          <Row justify="center" align="top" flex="5 0">
            <Col>
              <Upload {...fileUploadProperties} data={dockind}>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  icon={<UploadOutlined />}
                >
                  Upload Document
                </Button>
              </Upload>
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
}
