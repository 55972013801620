import { Divider, Row, Col, Button, Input, Form, Switch, Space } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import UenSearch from "./uenSearch";
import React, { useState, useEffect } from "react";
import { Organization } from "./Party";
import {isNumber, isEmail, isUen} from "../common/formValidationUtils";

export function OrganizationPanel(props) {
  const { onWizardNext, onChange, organizationAddressBook, value } = props;

  const [theForm] = Form.useForm();
  const [btndisabled, setbtndisabled] = useState(true);

  useEffect(() => {
    validateInput();
  }, []);

  function validateInput() {
    if (
      isNumber(theForm.getFieldValue("contactNumber")) &&
      isEmail(theForm.getFieldValue("email")) &&
      isUen(theForm.getFieldValue("uenSearch"))
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  }


  const formInitialValues = (org) => ({
    email: org?.getEmail(),
    contactNumber: org?.getPhone(),
    vipCustomer: org?.getVIP() === "Yes",
    sensitiveInfo: org?.getSensitiveInfo() === "Yes",
    uenSearch: org,
  });

  function onFormFieldsChanged(changedFields, allFields) {
    console.debug(
      "OrganizationPanel:onFormFieldsChanged",
      changedFields,
      allFields
    );
    validateInput?.();
    const orgSelectorField = changedFields?.find((v) =>
      v.name.find((n) => n === "uenSearch")
    );
    if (orgSelectorField) {
      //if organization selection changed, update form fields from fields of the selected org
      const formVals = formInitialValues(orgSelectorField.value);
      delete formVals.uenSearch;

      Object.entries(formVals) /*.filter(([n,v])=>v)*/
        .forEach(([n, v]) => theForm.setFieldValue(n, v));
      console.debug("UEN changed");
    }
  }

  const onFinish = (values) => {
    const newOrganizationDetails = new Organization({
      phoneNumber: values?.contactNumber,
      emailAddress: values?.email,
      uen: values?.uenSearch.getuen(),
      vipCustomer: values?.vipCustomer ? "Yes" : "No",
      sensitiveInfo: values?.sensitiveInfo ? "Yes" : "No",
      name: values?.uenSearch.name,
    });
    organizationAddressBook.setPersistent(newOrganizationDetails);
    console.log("Organization details ", newOrganizationDetails);
    onChange(newOrganizationDetails);
    onWizardNext?.();
  };

  return (
    <div className="wizard-page">
      <Row>
        <Col span="24">
          <Divider className="compact-divider">Customer Organization</Divider>
        </Col>
      </Row>
      <Form
        form={theForm}
        labelCol={{ span: 6 }}
        wrapperCol={{
          span: 12,
        }}
        onFinish={onFinish}
        onFieldsChange={onFormFieldsChanged}
        initialValues={formInitialValues(value)}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          <Form.Item
            label="Company"
            name="uenSearch"
            rules={[{ required: true, message: "Company is Required" }]}
          >
            <UenSearch
              organizationAddressBook={organizationAddressBook}
              className="form-field-long"
              edit={true}
            ></UenSearch>
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is Required" },
              {
                type: "email",
                message: "Enter a valid Email",
              },
            ]}
          >
            <Input placeholder="Email" autoComplete="off"></Input>
          </Form.Item>
          <Form.Item
            label="Contact Number"
            name="contactNumber"
            rules={[
              { required: true, message: "Contact Number is Required" },
              {
                pattern: /^\d+$/,
                message: "Phone number must digits",
              },
            ]}
          >
            <Input placeholder="Contact Number" autoComplete="off"></Input>
          </Form.Item>

          <Form.Item
            label="VIP Customer"
            name="vipCustomer"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Sensitive"
            name="sensitiveInfo"
            valuePropName="checked"
            hidden="true"
          >
            <Switch />
          </Form.Item>
        </Space>
        <Divider />
        <Row>
          <Col flex="1 1" />
          <Col flex="0 0">
            <Button
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              disabled={btndisabled}
              className="wizard-navi-button"
            >
              Proceed
              <DoubleRightOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
