import { useRef, useState, Fragment } from "react";
import { Row, Col, Button, Divider, Modal, Empty, Alert,  Descriptions, } from "antd";
import {
  PlusOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  CheckCircleTwoTone,
  CloseOutlined
} from "@ant-design/icons";

import { ServiceView } from "../service/serviceView";
import { ServiceEditModal } from "../service/serviceEditModal";

import {
  ServiceOrderContext,
  createServiceOrder,
} from "../api/ServiceOrderAPI";

export function EditServicesPage(props) {
  const {
    serviceCatalog: catalog,
    initialServices,
    onWizardNext,
    onWizardBack,
    onChange,
    customerOrganization,
    addressBookRef,
    phoneBookRef,
    userName, 
    email
  } = props;

  const { current: services } = useRef(initialServices);
  const [nextButton, setNextButton] = useState(false);
  const [returnButton, setReturnButton] = useState(false);
  const [isError, setError] = useState(undefined);
  const [newService, setNewService] = useState(false);
  const [version, setVersion] = useState(1);

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };


  console.log("EditServicesPage, services", services);

  const onNewServiceClose = () => {
    setNewService(false);
  };

  const onNewServiceClick = () => {
    setNewService(true);
  };

  const onServiceUpdate = (s) => {
    if (!services[s.id]) services[s.id] = new ServiceOrderContext({});
    services[s.id].service = s;
    console.log("EditServicesPage:onServiceUpdate, services", services);
    // setVersion(version+1);
    onChange?.(services);
  
  };

  const onServiceDelete = (s) => {
    delete services[s.id];
    console.log("EditServicesPage:onServiceDelete, services", services);
    setVersion(version + 1);
    onChange?.(services);
  };

  const renderServices = (isEditable) =>
    Object.entries(services).map(([k, s]) => (
      <ServiceView
        key={s.id ?? "42"}
        edit={false}
        value={s.service}
        addressbook={addressBookRef.current}
        phonebook={phoneBookRef.current}
        catalog={catalog}
        onChange={onServiceUpdate}
        onDelete={onServiceDelete}
        isEditable={isEditable ?? isEditable}
      />
    ));


  function onNext() {
    hideModal();
    setError(undefined)
    setNextButton(true)
    setReturnButton(true)
    const orderContext = createServiceOrder(services, customerOrganization, userName, email, []);
    console.log(orderContext.soState)
    setTimeout(function() {
    if(orderContext.soState==='Failed'){
      console.log(orderContext)
      setNextButton(false)
      setReturnButton(false)
      setError(JSON.parse(orderContext?.soStateError?.cause?.response?.body)?.message)
  
      setTimeout(function() { 
          setError(undefined)
        }, 15000);
      
    }else{
      onWizardNext?.(orderContext);
    }
    }, 8000);
  }

  return (
    <div className="page-edit-services-wizard-page">
      <Row>
        <Col flex="1 1">
          <Divider className="compact-divider">Services</Divider>
        </Col>
      </Row>
      <Row>
        <Col span={24}>

        {isError ? (
     <Alert
     message="Error"
     description={isError}
     type="error"
     showIcon
   />
    
      ) : (
        <></>
      )}

          {renderServices()}
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} 
          hidden={Object.entries(services)?.length !== 0}
          description={
            <span>
              No service selected, Please click on  below <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="small"
            onClick={onNewServiceClick}
          /> to select the service
            </span>
          }
          />

        </Col>
      </Row>
      <Row align="middle">
        <Col flex="0 0">
          {" "}
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="large"
            onClick={onNewServiceClick}
          />
        </Col>
        <Col flex="1 1">
          <Divider />
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col flex="1 1" />
        <Col flex="0 0">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={onWizardBack}
            disabled={returnButton}
          >
            <DoubleLeftOutlined />
            Return
          </Button>
        </Col>
        <Col flex="0 0">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={showModal}
           loading={nextButton}
           // disabled={nextButton}
            disabled={Object.entries(services)?.length === 0}
          >
            Proceed
            <DoubleRightOutlined />
          </Button>
        </Col>
      </Row>
      {newService ? (
        <ServiceEditModal
          open={true}
          value={{}}
          afterClose={onNewServiceClose}
          addressbook={addressBookRef.current}
          phonebook={phoneBookRef.current}
          catalog={catalog}
          onChange={onServiceUpdate}
        />
      ) : (
        <Fragment />
      )}

<Modal
        title="Please Verify the details before submitting!"
        open={open}  
        onOk={onNext}
        onCancel={hideModal}
        
        destroyOnClose = {true}
        okButtonProps={{
          icon: <CheckCircleTwoTone />,
        }}
        cancelText="Cancel"
        cancelButtonProps={{
          hidden: false,
          icon: <CloseOutlined />,
        }}

        keyboard={true}
        okType={"default"}
        okText="Proceed with booking"
        width={1000}
      >

        <p>
        <Descriptions bordered size={"middle"}>
            <Descriptions.Item label="Company">
              {customerOrganization?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {customerOrganization?.getEmail()}
            </Descriptions.Item>
            <Descriptions.Item label="Contact Number">
              {customerOrganization?.getPhone()}
            </Descriptions.Item>
            <Descriptions.Item label="VIP Customer">
              {customerOrganization?.getVIP()}
            </Descriptions.Item>
          </Descriptions>
        </p>
        <p >{renderServices(false)}</p>
      </Modal>
    </div>
  );
}
