import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivityC2P, svcspecGenericCableConnectivity } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { SingNet_Generic_Installation, orderTypeValues } from "../common/Constants";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
  esrASiteAddress,
  esrASiteContact,
  esrCurrentSiteAddress,
  esrCurrentSiteContact,
  esrNewSiteAddress,
  esrNewSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery, svcspecCopperAdvanceInstallation, svcspecGenericEquipmentInstallation } from "../catalogs/CatalogFieldServices";

import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";
import { SO, addAppointmentGroupName, getGenericConnectivitysA, getGenericInstallations, getGenericRecoveryA } from "../common/CommonUtils";

export function schemaC2PGpon(props) {
  const { name, installationSpec, cableSpec, genericInstallations,genericConnectivity } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PAddVAS,
    },
    "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PExtRelocationHot,
    },
    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [],
      decompose: (flatInstance) => [],
    },
  };

  function decomposeC2PProvide(flatInstance) {
    const svcCP2Cable = cableSpec?.updateInstance({}, flatInstance, [
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;

    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }


    let genericInstallationServices = [];
    if(genericInstallations){
      genericInstallations?.forEach((key, value) => {
        const genEqptInstlAndRecvry = key ? key?.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ]):null;
        addAppointmentGroupName(genEqptInstlAndRecvry, value)
        genericInstallationServices.push({ action: "add", service: genEqptInstlAndRecvry})
        });
    }

    let genericConnectivitys = [];
    if(genericConnectivity){
      genericConnectivity?.forEach((cableSpec, groupName) => {
      const genericCableSpec = cableSpec?cableSpec?.updateInstance({}, flatInstance, [
        [esrASiteAddress, esrCustomerSiteAddress],
        [esrASiteContact, esrCustomerSiteContact],
      ]):null;
        addAppointmentGroupName(genericCableSpec, groupName)
        genericConnectivitys.push({ action: "add", service: genericCableSpec})
        });
    }

    console.debug(
      "schemaC2PGpon:decompose New Provisioning svcCP2Cable,svcInstallation, genericInstallationServices, genericConnectivitys",
      svcCP2Cable,
      svcInstallation,
      genericInstallationServices,
      genericConnectivitys
    );
    return [
      { action: "add", service: svcCP2Cable },
      { action: "add", service: svcInstallation },
      { action: "add", service: svcWiring },
      ...genericConnectivitys,
      ...genericInstallationServices,
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PAddVAS(flatInstance) {
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    console.debug(
      "schemaC2PGpon:decompose Change Add VAS svcInstallation",
      svcInstallation
    );

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;

    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    return [{ action: "add", service: svcInstallation },
           { action: "add", service: svcWiring },
          ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PExtRelocationHot(flatInstance) {
    const svcCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value; 
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrNewSiteAddress],
          [esrWorkSiteContact,esrNewSiteContact]
        ]);
    }

    const soiCable = svcCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcCable,
        })
      : undefined;
    
      const soiWiring = svcWiring
      ? new ServiceOrderItem({
          action: "add",
          service: svcWiring,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;


      let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
        [
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrNewSiteAddress],
          [esrWorkSiteContact, esrNewSiteContact],

        ], SO);

      
    let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
      [
        [esrASiteAddress, esrNewSiteAddress],
        [esrASiteContact, esrNewSiteContact],
      ],SO);
 

    return [soiInstallation, soiCable, soiWiring,
      ...genericInstallationServicesA, ...genericConnectivitysA,
    ].filter((a) => a);
  }

  function decomposeC2PUpDownGradeHot(flatInstance) {
 
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
      
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
      [
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrNewSiteAddress],
        [esrWorkSiteContact, esrNewSiteContact],
      ], JSON);

    
  let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
    [
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ],JSON);



    return [
      { action: "add", service: svcInstallation },
      { action: "add", service: svcWiring },
      ...genericInstallationServicesA,
      ...genericConnectivitysA  
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PUpDownGradeParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCustomerSiteAddress],
      [esrASiteContact, esrCustomerSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
      
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    const soiNewCable = svcNewCable
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

      const soiWiring = svcWiring
      ? new ServiceOrderItem({
          action: "add",
          service: svcWiring,
        })
      : undefined;


      let genericInstallationServicesA = getGenericInstallations(genericInstallations,flatInstance, 
        [
         [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
        ], SO);

      
    let genericConnectivitysA  = getGenericConnectivitysA(genericConnectivity,flatInstance,
      [
        [esrASiteAddress, esrCustomerSiteAddress],
        [esrASiteContact, esrCustomerSiteContact],
      ],SO);

    return [soiInstallation, soiOldCable, soiNewCable, soiWiring,
      ...genericInstallationServicesA,
     ...genericConnectivitysA, ].filter(
      (a) => a
    );
  }

  function decomposeC2PCeaseEquipmentRecovery(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    return [soiRecovery].filter((a) => a);
  }

  function decomposeC2PExtRelocationParallel(flatInstance) {
    const svcNewCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrNewSiteAddress],
      [esrASiteContact, esrNewSiteContact],
    ]);

    const svcOldCable = cableSpec?.updateInstance({}, flatInstance, [
      //mapping
      [esrASiteAddress, esrCurrentSiteAddress],
      [esrASiteContact, esrCurrentSiteContact],
    ]);
    if (svcOldCable) svcOldCable.state = "terminated";


    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);

    var svcWiring=null;
    const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
      
    if (wiring==="Advance Wiring"){
      svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
        [//mapping
          [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
          [esrWorkSiteAddress,esrCustomerSiteAddress],
          [esrWorkSiteContact,esrCustomerSiteContact]
        ]);
    }

    const soiNewCable = svcNewCable
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCable,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiNewCable]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    const soiOldCable = svcOldCable
      ? new ServiceOrderItem({
          action: "modify",
          service: svcOldCable,
          serviceOrderItemRelationship: [soiInstallation]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;



      const svcNewCableGen = svcspecGenericCableConnectivity?.updateInstance({}, flatInstance, [
        //mapping
        [esrASiteAddress, esrNewSiteAddress],
        [esrASiteContact, esrNewSiteContact],
      ]);

      const soiNewCableGen = svcNewCableGen
      ? new ServiceOrderItem({
          action: "add",
          service: svcNewCableGen,
        })
      : undefined;



      const svcInstallationGen = svcspecGenericEquipmentInstallation?.updateInstance({}, flatInstance, [
        //mapping
        [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
        [esrWorkSiteAddress, esrNewSiteAddress],
        [esrWorkSiteContact, esrNewSiteContact],
      ]);

      const soiInstallationGen = svcInstallationGen
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallationGen,
        
        })
      : undefined;



    const soiWiring = svcWiring
      ? new ServiceOrderItem({
          action: "add",
          service: svcWiring,
        })
      : undefined;


      addAppointmentGroupName(soiInstallationGen, SingNet_Generic_Installation)
      addAppointmentGroupName(soiNewCableGen, SingNet_Generic_Installation)

    return [soiInstallation, soiOldCable, soiNewCable, soiWiring,
      soiInstallationGen, soiNewCableGen
    ].filter(
      (a) => a
    );
  }

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

const chrspecC2POrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});


export function svcspecC2PGpon(props) {
  const { name, installationServiceSpec, cableSpec, characteristics, genericInstallations, genericConnectivity } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  console.debug(
    "svcspecC2P name,props,chrspecCommonSet(name),characteristics",
    name,
    props,
    chrspecCommonSet(name),
    characteristics
  );

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaC2PGpon({
      name: "C2PGpon-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericInstallations,
      genericConnectivity
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderType;

  return spec;
}



