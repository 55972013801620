import {Characteristic} from '../service/Characteristic';
import {GeographicAddress,GeographicSubAddress} from '../address/geographicAddress'
import {Individual,Organization} from '../party/Party'
import { Service } from '../service/Service';
import {RefAsValue} from '../service-catalog/EntitySpecificationRelationship'



export const addressKerrisdale = new GeographicAddress(
  {
    postcode: ""
    , streetName: ""
    , streetNr: ""
    ,geographicSubAddress:[new GeographicSubAddress({
        buildingName:"",
        subUnitNumber: ""
      })] 
  });

export const addressComcentre = new GeographicAddress(
  {
    postcode: ""
    , streetName: ""
    , streetNr: ""
    ,geographicSubAddress:[new GeographicSubAddress({
      buildingName: ""
    })] 
  });

export const addressComcentreUnit = new GeographicAddress(
  {
    postcode: ""
    , streetName: ""
    , streetNr: ""
    ,geographicSubAddress:[new GeographicSubAddress({
        buildingName: ""
      , subUnitNumber: ""
    })]    
  });

export const johnDoe= new Individual({
  givenName: "",
  familyName: "",
  emailAddress: "",
  phoneNumber: ""
});

export const organizationDummy = new Organization({
  name:"DUMMY COMPANY",
  phoneNumber: "12345678",
  emailAddress: "reception@dummy.com",
  uen: "123456830A",
  vipCustomer: "Yes"
});

export const initialService=new Service({
  serviceSpecification:{name:"Meg@POP IPVPN eLite"},
  serviceCharacteristic:[
    new Characteristic({name:"Service No",value:"XO12134"}),
    new Characteristic({name:"Order Type",value:"Relocation Hot"}),
    new Characteristic({name:"Customer Requested Date",value:"2022-12-29T00:00:00"})
  ],
  relatedParty:[
    new RefAsValue({
      role: "Customer Site.Site Contact",
      value:johnDoe
    })
  ]
});
