import { Button, Row, Col, Card } from "antd";
import { React } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { DOCUMENT_DOWNLOAD_URL } from "../api/endpoints";

export function UploadedDocumentView(props) {
  const { supportingDocuments, showDeleteButton, deleteDocument } = props;
  const displayDeleteButton = (item) => {
    if (showDeleteButton) {
      return (
        <Button
          onClick={() => deleteDocument(item)}
          type="dashed"
          danger
          icon={<DeleteFilled />}
        >
          Delete
        </Button>
      );
    }
  };

  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={16}>
          {supportingDocuments.map((doc, index) => {
            const docId = doc?.response?.docId;
            const downloadLink = `${DOCUMENT_DOWNLOAD_URL}\ID=${docId}`;
            return (
              <Col span={6} key={index}>
                <Card
                  key={index}
                  title={doc.name}
                  bordered={true}
                  size="small"
                  // extra={
                  //   <a href={downloadLink} target="_blank">
                  //     View Document
                  //   </a>
                  // }
                  style={{ marginTop: 16, marginBottom: 16 }}
                >
                  <p>Doc Id: {docId}</p>
                  <p>Doc type : {doc.dockind}</p>
                  <p>Remarks : {doc.documentNotes}</p>
                  {displayDeleteButton(doc)}
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}
