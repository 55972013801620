import ServiceCatalog from "../service-catalog/ServiceCatalog"
import {svcspecC2P} from "../schema/schemaC2P"
import {svcspecGenericEquipmentInstallation, svcspecGenericEquipmentInstallationFtthNo, svcspecInstallationeSingtelTV, svcspecInstallationeSingtelTVWithFtth} from '../catalogs/CatalogFieldServices'
import {chrspec} from '../service-catalog/CharacteristicSpecification'
import { chrspecFtthNo } from "./CharacteristicsCatalog";
import { svcspecC2PEvolve } from "../schema/schemaC2PEvolve";
import { svcspecCableConnectivityC2PFtth, svcspecGenericCableConnectivity } from "./CatalogCableConnectivity";
import { IPTV_Generic_Installation } from "../common/Constants";

const chrspecProductTypeSingtelTV=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"SingNet Corporate - SingTel TV"
});

export const catalogSingtelTV=((new ServiceCatalog())
.category("Singtel TV")
    .serviceSpecs([
      svcspecC2PEvolve({
        name:"IPTV Standalone",
        cableSpec: svcspecCableConnectivityC2PFtth,
        installationServiceSpec:svcspecInstallationeSingtelTVWithFtth,
        characteristics:[chrspecProductTypeSingtelTV, chrspecFtthNo],
        genericInstallations: new Map().set(IPTV_Generic_Installation, svcspecGenericEquipmentInstallationFtthNo),

        genericConnectivity: new Map()
        .set(IPTV_Generic_Installation, svcspecGenericCableConnectivity)
      }),
      svcspecC2P({
        name:"IPTV Bundled",
        installationServiceSpec:svcspecInstallationeSingtelTV,
        cableSpec:null,
        characteristics:[chrspecProductTypeSingtelTV]
      })
      ])
.endCategory());