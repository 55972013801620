import { ServiceSchema, mapperConst } from "./ServiceSchema";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {svcspecCopperAdvanceInstallation} from '../catalogs/CatalogFieldServices'
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";

import { ServiceOrderItem } from "../serviceOrder/serviceOrder";

export function schemaFieldService(props) {
  const { name, installationSpec } = props;

  const schema = {
    default: {
      specs: (flatInstance) => [
        //esrCustomerSiteAddress,esrCustomerSiteContact
      ],
      decompose: (flatInstance) => {
        const svcInstallation = installationSpec.updateInstance(
          {},
          flatInstance,
          [
            //mapping
            [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
            [esrWorkSiteAddress, esrCustomerSiteAddress],
            [esrWorkSiteContact, esrCustomerSiteContact],
          ]
        );

        var svcWiring=null;
        const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;

        if (wiring==="Advance Wiring"){
          svcWiring=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
            [//mapping
              [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
              [esrWorkSiteAddress,esrCustomerSiteAddress],
              [esrWorkSiteContact,esrCustomerSiteContact]
            ]);
          
        }

        console.debug("schemaFieldService:decompose ", svcInstallation);

        return [
          { action: "add", service: svcInstallation },
          { action: "add", service: svcWiring },
        ]
          .filter((a) => a.service)
          .map((a) => new ServiceOrderItem(a));
      },
    },
  };

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

export function svcspecFieldService(props) {
  const { name, installationServiceSpec, characteristics } = props;
  return new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    entitySpecRelationship: [esrCustomerSiteAddress, esrCustomerSiteContact],
    schema: schemaFieldService({
      name: "FS-" + name,
      installationSpec: installationServiceSpec,
    }),
  });
}
