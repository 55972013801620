import ServiceCatalog from "../service-catalog/ServiceCatalog";

import { catalogMegaPOP } from "./CatalogMegaPOP";
import { CatalogVoice } from "./CatalogVoice";
import { catalogMetroE } from "./CatalogMetroE";
import { catalogCableConnectivity } from "./CatalogCableConnectivity";
import { catalogFieldServices } from "./CatalogFieldServices";
import { catalogSingNet } from "./CatalogSingnet";
import { catalogALLC } from "./CatalogALLC";
import { catalogDEL } from "./CatalogDEL";
import { catalogDarkFiber } from "./CatalogDarkFiber";
import { catalogSingtelTV } from "./CatalogSingtelTV";
import { catalogDigiNet } from "./CatalogDiginet";
import { CatalogBAccess } from "./CatalogBAccess";
import { CatalogGigawave } from "./CatalogGigawave";

import { CatalogIsdn } from "./CatalogIsdn";
import { catalogPhonenet } from "./CatalogPhonenet";
import { CatalogBizVoice } from "./CatalogBizVoice";
import { CatalogTelephony } from "./CatalogTelephony";

export const masterServiceCatalog = new ServiceCatalog([
  //For Relese 4.0
 // catalogDarkFiber,
  
  


   //catalogCableConnectivity, //Removed temp fix the issue and deploy again
   //catalogFieldServices, //Removed temp fix the issue and deploy again


  //Production Only
  catalogALLC,
  CatalogBAccess,
  CatalogTelephony,
  CatalogBizVoice,
  catalogDEL,
  catalogDigiNet,
  CatalogGigawave,
  CatalogIsdn,
  catalogMegaPOP,
  catalogMetroE,
  catalogPhonenet,
  catalogSingNet,
  catalogSingtelTV,
  CatalogVoice
]);
