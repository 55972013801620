//TMF633 Service Catalog Management 4.0.0

export const  SCOPE_RELATED_PLACE="place";
export const  SCOPE_RELATED_PARTY="relatedParty";
export const  ESR_REQUIRED="requires";
export const  ESR_OPTIONAL="depends";

export class RefAsValue{
  role=null;
  value=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("refAsValue unknown property",e);
      });
    }
  //we will flatten the refvalue on serialization only 
  //as a workaround for dff implementation deviating from TMF
  toJSON=(index)=>({...this.value,role:this.role});
}
export class EntitySpecificationRelationship{
  //A string. The association role for this entity specification.
  //role value format SCOPE:Role_Group.Role Name e.g. place:Site-A.Adress
  role=null;
  //A string. Name of the related entity.
  name=null;
  //A string. Type of relationship such as migration, substitution, dependency, exclusivity.
  relationshipType=null;
  //A string. The actual type of the target instance when needed for disambiguation.
  "@referredType"=null;
  //An uri (Uri). Hyperlink reference.
  href=null;
  //A string. unique identifier.
  id=null;
  //A string. When sub-classing, this defines the super-class.
  "@baseType"=null; 
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation"=null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type"="EntitySpecificationRelationship"
  //An association specification reference (AssociationSpecificationRef). A specification for an association used by this relationship.
  associationSpec=null;
  //A time period. The period for which the entitySpecRelationship is valid.
  validFor=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("EntitySpecificationRelationship unknown property",e);
      });
      if (!this.id) this.id=this.role;
  }

  getScope=()=>this.role?.split(":")?.[0];
  getUnscopedRole=()=>this.role?.split(":")?.[1];
  getGroup=()=>this.getUnscopedRole()?.split(".")?.[0];
  getName=()=>this.getUnscopedRole()?.split(".")?.[1];
  getFullName=()=>this.role;
  isRequired=()=>this.relationshipType===ESR_REQUIRED;
  isReadOnly=()=>false;
  //isChoiseList=()=>false;
  getEffectiveValue=(val)=>(val);
  instanceToValue=(instance)=>instance?.value;
  instanceFromValue=(val)=>new RefAsValue({
                                role:this.getUnscopedRole(),
                                value:val
                              });
  effectiveInstance=(instance)=>instance ?? new RefAsValue({role:this.role});
}

export const placeRole=(role)=>SCOPE_RELATED_PLACE+":"+role;
export const partyRole=(role)=>SCOPE_RELATED_PARTY+":"+role;

export function esrAddress(relationRole,relationType=ESR_REQUIRED){
  return new EntitySpecificationRelationship({
    name:"GeographicAddressSpec",
    relationshipType:relationType,
    role:placeRole(relationRole)
  })
}

export function esrIndividual(relationRole,relationType=ESR_REQUIRED){
  return new EntitySpecificationRelationship({
    name:"IndividualSpec",
    relationshipType:relationType,
    role:partyRole(relationRole)
  })
}