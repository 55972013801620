import {CharacteristicValueSpecification} from './CharacteristicValueSpecification'
import { Characteristic } from '../service/Characteristic';
export class CharacteristicSpecification{
  // A string. A word, term, or phrase by which this characteristic specification is known and distinguished from other characteristic specifications.
  name=null;
  // A string. A kind of value that the characteristic can take on, such as numeric, text and so forth.
  valueType=null;
  // A string. Unique ID for the characteristic.
  id=null;
  // A string. This (optional) field provides a link to the schema describing the value type.
  "@valueSchemaLocation"=null;
  // A boolean. If true, the Boolean indicates that the target Characteristic is configurable.
  configurable=true;
  // A string. A narrative that explains the CharacteristicSpecification.
  description=null;
  // A boolean. An indicator that specifies that the values for the characteristic can be extended by adding new values when instantiating a characteristic for a resource.
  extensible=null;
  // A boolean. An indicator that specifies if a value is unique for the specification. Possible values are; "unique while value is in effect" and "unique whether value is in effect or not".
  isUnique=null;
  // An integer. The maximum number of instances a CharacteristicValue can take on. For example, zero to five phone numbers in a group calling plan, where five is the value for the maxCardinality.
  maxCardinality=1;
  // An integer. The minimum number of instances a CharacteristicValue can take on. For example, zero to five phone numbers in a group calling plan, where zero is the value for the minCardinality.
  minCardinality=0;
  // A string. A rule or principle represented in regular expression used to derive the value of a characteristic value.
  regex=null;
  // A string. When sub-classing, this defines the super-class.
  "@baseType"=null;
  // An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation"=null;
  // A string. When sub-classing, this defines the sub-class Extensible name.
  "@type"="CharacteristicSpecification";
  // A list of characteristic specification relationships (CharacteristicSpecificationRelationship [*]). 
  //  aggregation, migration, substitution, dependency or exclusivity relationship between/among Specification Characteristics.
  charSpecRelationship=[];
  // Alist of characteristic value specifications (CharacteristicValueSpecification [*]). 
  // A CharacteristicValueSpecification object is used to define a set of attributes, 
  // each of which can be assigned to a corresponding set of attributes in a CharacteristicSpecification object. 
  // The values of the attributes in the CharacteristicValueSpecification object describe 
  // the values of the attributes that a corresponding Characteristic object can take on.
  characteristicValueSpecification=[];
  //A time period. The period of time for which a characteristic is applicable.
  validFor=null;

  constructor(pojo){
      Object.entries(pojo).forEach(e => {
          if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
          else console.error("CharacteristicSpecification unknown property",e);
        });
      if(!this.id) this.id="serviceCharacteristic:"+this.name;
    }

  isRequired=()=>this.minCardinality>0;
  isReadOnly=()=>(!this.configurable);
  isChoiseList=()=>(this.characteristicValueSpecification?.length>1)?true:false;
  getGroup=()=>(this.name?.includes("."))?this.name.split(".")[0]:null;
  getName=()=>(this.name?.includes("."))?this.name.split(".")[1]:this.name;
  getFullName=()=>"serviceCharacteristic:"+this.name;
  getDefault=()=>this.characteristicValueSpecification?.find(val=>val.isDefault)?.value;
  getEffectiveValue=(val)=>(val && !this.isReadOnly())?val:this.getDefault();
  instanceToValue=(charInstance)=>this.getEffectiveValue(charInstance?.value);
  instanceFromValue=(val)=>new Characteristic({
                                name:this.name,
                                value:this.getEffectiveValue(val),
                                valueType:this.valueType
                              });
  effectiveInstance=(instance)=>new Characteristic({
    ...instance,
    name:this.name,
    value:this.getEffectiveValue(instance?.value),
    valueType:this.valueType
  });
}

export const CHR_MANDATORY=true;
export const CHR_OPTIONAL=false;
export const CHR_RO=false;
export const CHR_RW=true;

export function chrspec(props){
  const {id,name,type,isMandatory=CHR_OPTIONAL,defaultVal=undefined,isReadOnly=false}=props;
  return new CharacteristicSpecification({
    id:id,
    name: name,
    valueType: type,
    configurable:(isReadOnly)?false:true,
    minCardinality:(isMandatory)?1:0,
    characteristicValueSpecification:(!defaultVal)?[]:[
        new CharacteristicValueSpecification({
          value:defaultVal,
          valueType:type,
          isDefault:true
        })
      ]
  })
}

