export class Characteristic {
  //A string. When sub-classing, this defines the super-class.
  "@baseType" = null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation" = null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type" = "Characteristic";
  //A list of characteristic relationships (CharacteristicRelationship [*]). Another Characteristic that is related to the current Characteristic;.
  characteristicRelationship = null;
  //A string. Unique identifier of the characteristic.
  id = null;
  //A string. Name of the characteristic.
  name = null;
  //An any (Any). The value of the characteristic.
  value = null;
  //A string. Data type of the value of the characteristic.
  valueType = null;

  constructor(pojo) {
    Object.entries(pojo).forEach((e) => {
      if (this.hasOwnProperty(e[0])) this[e[0]] = e[1];
      else console.error("Characteristic unknown property", e);
    });
    //if(!this.id) this.id=uuid();
  }
}
