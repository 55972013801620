import { PublicClientApplication } from "@azure/msal-browser";

//configuration, binding the app to specific app registration record in Azure Active Directory

export const msalConfig = {
  auth: {
    //AAD app registration record ID. Must be different for prod and non-prod deployments
    clientId: window.STABEnvironment.msalConfig.clientId,
    //SingtelDFF Azure AD. Same value for all environments
    authority: "https://login.microsoftonline.com/b7fc10e3-889b-4f34-a122-f3135b0a43c6",
    //OAuth redirect URL. localhost for local development, real deployment URL for deployed apps.
    redirectUri: window.location.href
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

//Singleton instance of AAD client
export const aad = new PublicClientApplication(msalConfig);

//Shorthand method to retrieve currently logged in user.
//We do have one logged in user, so we can simply take a first element in client accounts array
export const aadLoggedInAccount = () => aad?.getAllAccounts()?.[0];

//Shorthand method to initiate AAD token request
export function aadToken() {
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  const authRequest = {
	scopes: [window.STABEnvironment.msalConfig.scope],
    account: aadLoggedInAccount(),
  };

  const response = aad.acquireTokenSilent(authRequest).catch((error) => {
    console.error(
      "AuthenticationContext:getMicrositeToken failed.\n%o\nCause:%o",
      error,
      error?.cause
    );
    return Promise.reject(
      new Error("AAD token request failed", { cause: error })
    );
  });

  return response;
}
