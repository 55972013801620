import "antd/dist/antd.min.css";
import logo from "./logo.png";
import "./App.css";
import { Row, Col, Alert, Typography } from "antd";
import { AlertOutlined } from "@ant-design/icons";
import React, { useState } from "react";

import { UserPanel } from "./user/userPanel";
import { BookingWizard } from "./appBookingWizard/BookingWizard";

import { defaultAuthNContext } from "./api/authorization";
import { NavigationLayout } from "./navigation/NavigationLayout";

const { Text, Link } = Typography;
const SINGTEL_EMAIL = "singtel.com";

function App() {
  //test of auth context
  console.log(JSON.stringify(defaultAuthNContext, null, 2));
  const [userName, setuserName] = useState();
  const [email, setemail] = useState();
  const version = window.STABEnvironment.buildInfo.version;
  const commit = window.STABEnvironment.buildInfo.commit;

  defaultAuthNContext.getToken().then((token) => {
    console.log("GetToken :", token);
    setuserName(defaultAuthNContext.user.name);
    setemail(defaultAuthNContext.user.email);
  });

  defaultAuthNContext
    .getMicrositeToken({
      serviceOrderItemIDs: ["123", "456"],
      appointmentIDs: ["789"],
    })
    .then((token) => console.log("GetMicrositeToken :", token));

  return (
    <>
      {email?.split("@")?.length > 1 &&
        email.split("@")[1] === SINGTEL_EMAIL && (
          <Row>
            <Col flex="1 1">
              <Alert
                description={
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "58px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AlertOutlined
                        style={{ color: "red", fontSize: "20px" }}
                      />
                    </div>
                    <div>
                      <Text>
                        This portal will be replaced by the{" "}
                        <Text strong>
                          MyEmpower Customer Appointment module
                        </Text>
                        . Please book appointments for customers on
                        <Link
                          href="https://myempower.singtel.com/"
                          target="_blank"
                        >
                          {" "}
                          MyEmpower
                        </Link>{" "}
                        today onwards to avoid any disruptions. You need to be
                        on Singtel network or VPN. We will progressively
                        deactivate STAB portal accounts from mid-October, with a
                        full shutdown by end November 2024.
                      </Text>
                      <p />
                      <Text>
                        If you face any technical issues, please log a ticket
                        via IT Assist:{" "}
                        <Link
                          href="https://singtel.sharepoint.com/:b:/s/UnifiedDigitalFrontend-BTO/EStoiH0gnnVOu6qkhbB43mwBQSSWRHsEddNMMMCHwDgjlA?e=VKVdbM"
                          target="_blank"
                          underline
                        >
                          Guide to raising an IT Assist Ticket
                        </Link>{" "}
                        <p />
                      </Text>
                      <Text>Thank you.</Text>
                    </div>
                  </div>
                }
                type="error"
                closable
                showIcon={false}
                banner
              />
            </Col>
          </Row>
        )}
      <div className="create-order-screen">
        {/* HEADER */}
        <Row gutter={[8, 8]} align="bottom" flex="0 0 1000px">
          <Col flex="0 0">
            <img className="header-logo" src={logo} alt="Singtel logo" />
          </Col>
          <Col flex="1 1">
            <h1>Standalone Appointment Booking</h1>
          </Col>

          <Col flex="0 1 200px">
            <UserPanel userName={userName} email={email} />
          </Col>
        </Row>
        {/* WIZARD */}
        <NavigationLayout userName={userName} email={email} />
        {
          <div>
            <span style={{ color: "white" }}>
              version : {version} - commit: {commit}
            </span>
          </div>
        }
      </div>
    </>
  );
}

export default App;

//TODO: Scroll to added service view
//TODO: Focus on spec selector in edit service