import {
  placeRole,
  partyRole,
} from "../service-catalog/EntitySpecificationRelationship";

export class Service {
  //A string. Unique identifier of the service.
  id = null;
  // A string. When sub-classing, this defines the sub-class Extensible name.
  "@type" = "Service";
  //A string. Is it a customer facing or resource facing service.
  category = null;
  //A service specification reference (ServiceSpecificationRef).
  //The specification from which this service was instantiated.
  serviceSpecification = {};
  //A string. Name of the service.
  name = null;
  //A list of characteristics (Characteristic [*]). A list of characteristics that characterize this service (ServiceCharacteristic [*]).
  serviceCharacteristic = [];
  //A list of related parties (RelatedParty [*]). A list of related party references (RelatedParty [*]).
  //A related party defines party or party role linked to a specific entity.
  relatedParty = [];
  //A list of related place ref or values (RelatedPlaceRefOrValue [*]).
  //A list of places (Place[*]). Used to define a place useful for the service
  //(for example a geographical place whre the service is installed).
  place = [];

  //******** NOT USED IN DFF *********/
  // A string. Free-text description of the service.
  description = null;
  //A date time (DateTime). Date when the service ends.
  endDate = null;
  //A list of features (Feature [*]). A list of feature associated with this service.
  feature = null;
  //A boolean. If TRUE, this Service has already been started.
  hasStarted = null;
  //A string. Reference of the service.
  href = null;

  //A boolean. If true, the service is a ServiceBundle which regroup a service hierachy. If false, the service is an 'atomic' service (hierachy leaf).
  isBundle = false;
  // A boolean. If FALSE and hasStarted is FALSE, this particular Service has NOT been
  // enabled for use - if FALSE and hasStarted is TRUE then the service has failed.
  isServiceEnabled = null;
  //A boolean. If TRUE, this Service can be changed without affecting any other services.
  isStateful = true;
  //A list of notes (Note [*]). A list of notes made on this service.
  note = null;
  //A list of related entity ref or values (RelatedEntityRefOrValue [*]).
  //A list of related entity in relationship with this service.
  relatedEntity = null;
  //A string. Date when the service was created (whatever its status).
  serviceDate = null;
  // A list of related service order items (RelatedServiceOrderItem [*]).
  //A list of service order items related to this service.
  serviceOrderItem = null;
  //A list of service relationships (ServiceRelationship [*]).
  //Describes links with other service(s) in the inventory.
  serviceRelationship = null;

  // A string. Business type of the service.
  serviceType = null;
  // A date time (DateTime). Date when the service starts.
  startDate = null;
  // A string. This attribute is an enumerated integer that indicates how the Service is started,
  // such as:
  // 0: Unknown;
  // 1: Automatically by the managed environment;
  // 2: Automatically by the owning device;
  // 3: Manually by the Provider of the Service;
  // 4: Manually by a Customer of the Provider;
  // 5: Any of the above.
  startMode = 1;
  //A service state type (ServiceStateType). The life cycle state of the service, such as   designed, reserved, active, etc...
  state = null;
  //A list of resource references (ResourceRef [*]).
  //A list of supporting resources  (SupportingResource [*]).
  //Note: only Service of type RFS can be associated with Resources
  supportingResource = null;
  //A list of service ref or values (ServiceRefOrValue [*]).
  //A list of supporting services (SupportingService [*]).
  //A collection of services that support this service (bundling, link CFS to RFS).
  supportingService = null;

  constructor(pojo) {
    Object.entries(pojo).forEach((e) => {
      if (this.hasOwnProperty(e[0])) this[e[0]] = e[1];
      else console.error("Service unknown property", e);
    });
    //if(!this.id) this.id=uuid();
  }

  asFlatEntries() {
    console.debug("Service.asFlatEntries", this);
    return [
      ...this.serviceCharacteristic?.map((c) => [
        "serviceCharacteristic:" + c.name,
        c,
      ]),
      ...this.relatedParty?.map((c) => [partyRole(c.role), c]),
      ...this.place?.map((c) => [placeRole(c.role), c]),
    ];
  }

  asFlatObject() {
    return Object.fromEntries(this.asFlatEntries());
  }
}
