import jose from "node-jose";

export function decodeJWT(jwtToken) {
  try {
    const decodedToken = jose.util.base64url.decode(jwtToken.split(".")[1]);
    const decodedPayload = JSON.parse(decodedToken.toString("utf8"));
    let userEmail = "";
    const eMail = decodedPayload.preferred_username;
    if (eMail !== undefined && eMail.endsWith("@singteldff.onmicrosoft.com")) {
      userEmail = decodedPayload.otherEmail ? decodedPayload.otherEmail : "";
    } else userEmail = eMail;

    return userEmail;
  } catch (error) {
    console.error("Error decoding JWT:", error);
  }
}
