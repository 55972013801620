
import ServiceCatalog from "../service-catalog/ServiceCatalog";
import { svcspecC2P, svcspecC2PNoHotUpgradeDowngrade } from "../schema/schemaC2P";
import {
  svcspecGenericEquipmentInstallation,
  svcspecInstallationElite,
  svcspecMegPOPEVPNEquipmentInstallation,
  svcspecMegPOPEthernetLinkEquipmentInstallation,
  svcspecMegPOPeAccessEquipmentInstallation,
} from "../catalogs/CatalogFieldServices";
import { chrspec } from "../service-catalog/CharacteristicSpecification";
import { serviceSpec2C2P } from "../schema/schema2C2P";
import { svcspecC2PGpon } from "../schema/schemaC2PGpon";
import { svcspecGenericCableConnectivity } from "./CatalogCableConnectivity";
import { Megpop_Generic_Installation } from "../common/Constants";

const chrspecProductTypeMegaPOPeLite = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Meg@POP - eLite",
});

function eLiteTemplate(name) {
  return svcspecC2PGpon({
    name: name,
    installationServiceSpec: svcspecInstallationElite,
    characteristics: [chrspecProductTypeMegaPOPeLite],
    genericInstallations: new Map().set(Megpop_Generic_Installation, svcspecGenericEquipmentInstallation),
    genericConnectivity: new Map().set(Megpop_Generic_Installation,svcspecGenericCableConnectivity)
  });
}

const chrspecProductTypeMegaPOPEthernetLine = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Meg@POP - Ethernet Line",
});

function ethernetLineTemplate(name) {
  return svcspecC2PNoHotUpgradeDowngrade({
    name: name,
    installationServiceSpec: svcspecMegPOPEthernetLinkEquipmentInstallation,
    genericEquipmentInstallation: svcspecGenericEquipmentInstallation,
    genericEquipmentInstallationName: "default",
    characteristics: [chrspecProductTypeMegaPOPEthernetLine],
  });
}

function ethernetLineTemplate2C2P(name) {
  return serviceSpec2C2P({
    name: name,
    installationServiceSpec: svcspecMegPOPEthernetLinkEquipmentInstallation,
    characteristics: [chrspecProductTypeMegaPOPEthernetLine],
  });
}

const chrspecProductTypeMegaPOPEthernetLink = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Meg@POP - EthernetLink",
});

function ethernetLinkTemplate(name) {
  return svcspecC2PNoHotUpgradeDowngrade({
    name: name,
    installationServiceSpec: svcspecMegPOPEthernetLinkEquipmentInstallation,
    characteristics: [chrspecProductTypeMegaPOPEthernetLink],
    
  });
}

function ethernetLinkTemplate2C2P(name) {
  return serviceSpec2C2P({
    name: name,
    installationServiceSpec: svcspecMegPOPEthernetLinkEquipmentInstallation,
    characteristics: [chrspecProductTypeMegaPOPEthernetLink],
  });
}

const chrspecProductTypeMegaPOPEVPNEthernetLink = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Meg@POP - EVPN EthernetLink",
});

function eVPNEthernetLinkTemplate(name) {
  return svcspecC2PNoHotUpgradeDowngrade({
    name: name,
    installationServiceSpec: svcspecMegPOPEVPNEquipmentInstallation,
    characteristics: [chrspecProductTypeMegaPOPEVPNEthernetLink],
  });
}

function eVPNEthernetLinkTemplate2C2P(name) {
  return serviceSpec2C2P({
    name: name,
    installationServiceSpec: svcspecMegPOPEVPNEquipmentInstallation,
    characteristics: [chrspecProductTypeMegaPOPEVPNEthernetLink],
  });
}

const chrspecProductTypeMegaPOPEAccess = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Meg@POP - IPVPN E-Access",
});

function eaccessTemplate(name) {
  return svcspecC2P({
    name: name,
    installationServiceSpec: svcspecMegPOPeAccessEquipmentInstallation,
    characteristics: [chrspecProductTypeMegaPOPEAccess],
  });
}

export const catalogMegaPOP = new ServiceCatalog()
  .category("Meg@POP")

  .category("eLite")
  .serviceSpecs([
    eLiteTemplate("eLite Access"),
    eLiteTemplate("WS-eLite 100 Mbps CIR (SingNet Only)"),
    eLiteTemplate("WS-eLite 30 Mbps CIR (SingNet Only)"),
    eLiteTemplate("WS-eLite 50 Mbps CIR (SingNet Only)"),
    eLiteTemplate("eLite Access - XGPON"),
    eLiteTemplate("Meg@POP eLite Plus"),
    eLiteTemplate("(Customised) Fibre Services"),
    eLiteTemplate("eLite Access with Exch Diversity"),
    eLiteTemplate("eLite Access with Exch Diversity - XGPON"),
    eLiteTemplate("(Meg@POP2)eLite Access with Exch Diversity"),
    eLiteTemplate("Meg@POP IPVPN WS-eLite Virtual Circuit (EVC)"),
    eLiteTemplate("(Dummy) Remote Location Site Survey"),
    eLiteTemplate("Remote Location Site Survey"),
    eLiteTemplate("eLite BOD"),
    eLiteTemplate("EVPL eLite (Headend)"),
    eLiteTemplate("EVPL eLite (Tailend)"),
    eLiteTemplate("EVPN eLite"),
    eLiteTemplate("eLite (half-access)-DLC"),
    eLiteTemplate("Meg@POP IPVPN eLite"),
    eLiteTemplate("Meg@POP IPVPN eLite (Temp)"),
    eLiteTemplate("Meg@POP IPVPN eLite (XGPON)"),
    eLiteTemplate("Meg@POP IPVPN eLite Exch Div"),
    eLiteTemplate("Meg@POP IPVPN eLite Exch Div (XGPON)"),
    eLiteTemplate("Meg@POP IPVPN WS eLite with Exch Div"),
    eLiteTemplate("Meg@POP IPVPN WS eLite with Exch Div (XGPON)"),
    eLiteTemplate("Meg@POP iPVPN WS-eLite (XGPON)"),
  ])
  .endCategory()

  .category("Ethernet Line")
  .serviceSpecs([
    ethernetLineTemplate("Ethernet Line PTMP Head-End"),
    ethernetLineTemplate("Ethernet Line PTMP Head-End (Temp)"),
    ethernetLineTemplate("Ethernet Line PTMP Tail-End"),
    ethernetLineTemplate("Ethernet Line PTMP Tail-End (Temp)"),
    ethernetLineTemplate("PTMP Meg@POP EVPL EthernetLink(Head-End)"),
    ethernetLineTemplate("PTMP Meg@POP EVPL EthernetLink(Head-End) (Temp Service)"),
    ethernetLineTemplate("PTMP Meg@POP EVPL EthernetLink(Tail-End)"),
    ethernetLineTemplate("PTMP Meg@POP EVPL EthernetLink(Tail-End) (Temp Service)"),
    ethernetLineTemplate("Ethernet Line PTMP  (Head-End)"),
    ethernetLineTemplate("Ethernet Line PTMP (Tail-End)"),
    ethernetLineTemplate("Ethernet Line PTP (Dummy)"),
    ethernetLineTemplate("VSI(Virtual Security Interface) number"),
    ethernetLineTemplate("EVPL PTMP Secondary Virtual Circuit"),
    ethernetLineTemplate("(CE) Pt-to-MultiPt Meg@POP EVPL Ethernetlink (Head-End)"),
    ethernetLineTemplate("(CE) EthernetLine PTP"),
    ethernetLineTemplate("(CE) Ethernet Line PTMP Head-End"),
    ethernetLineTemplate("(CE) Ethernet Line PTMP Tail-End"),
    ethernetLineTemplate("(CE) (Temp) Ethernet Line PTP"),
    ethernetLineTemplate("(CE) (Temp) Ethernet Line PTMP Head-End"),
    ethernetLineTemplate("(CE) (Temp) Ethernet Line PTMP Tail-End"),

    ethernetLineTemplate2C2P("Ethernet Line PTP"),
    ethernetLineTemplate2C2P("Ethernet Line PTP (Temp)"),

  ])
  .endCategory()

  .category("EthernetLink")
  .serviceSpecs([
    ethernetLinkTemplate("(CE) Ethernetlink"),
    ethernetLinkTemplate("(CE) Ethernetlink AutoBackup"),
    ethernetLinkTemplate("(CE) Ethernetlink AutoBackup w Exch Div"),
    ethernetLinkTemplate("(CE) Ethernetlink Enhanced"),
    ethernetLinkTemplate("(CE) Ethernetlink Enhanced w Exch Div"),
    ethernetLinkTemplate("(CE) Ethernetlink Exch Div"),
    ethernetLinkTemplate("(CE) EthernetLink Headend - Auto Backup with ED"),
    ethernetLinkTemplate("(CE) EthernetLink Headend - Main EthernetLink"),
    ethernetLinkTemplate("(CE) Ethernetlink IGA"),
    ethernetLinkTemplate("(CE) Ethernetlink Outdoor"),
    ethernetLinkTemplate("(CE) Meg@POP IPVPN Ethernetlink with Path Diversity"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink AutoBackup"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink AutoBackup w Exch Div"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink Enhanced"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink Enhanced w Exch Div"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink Exch Div"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink Outdoor"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink Path Diversity"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink Secondary EVC"),
    ethernetLinkTemplate("(MegaPOP2)(CE) Ethernetlink w Router"),
    ethernetLinkTemplate("(Comb WF) Meg@POP IPVPN Ethernetlink (2M-Fiber customised)"),
    ethernetLinkTemplate("(Combined WF) Ethernetlink Outdoor with Exchange Diversity"),
    ethernetLinkTemplate("(Combined WF) Ethernetlink Outdoor with Path Diversity"),
    ethernetLinkTemplate("(Meg@POP2)Meg@POP IPVPN Ethernetlink with Path Diversity"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Ethernetlink"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Ethernetlink AutoBackup"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Ethernetlink Enhanced"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Ethernetlink Outdoor"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Ethernetlink Secondary Virtual Circuit"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Meg@POP IPVPN Ethernetlink - AutoBackup with Exchange Diversity"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Meg@POP IPVPN Ethernetlink - Enhanced with Exchange Diversity"),
    ethernetLinkTemplate("(MegaPOP2)(Combine WF) Meg@POP IPVPN Ethernetlink - Exchange Diversity"),
    ethernetLinkTemplate("CE SDH Meg@POP Ethernetlink - BizVoice (Infra) 3M-100M"),
    ethernetLinkTemplate("Customised Meg@POP IPVPN EthernetLink OD with PD (1M-1G)"),
    ethernetLinkTemplate("Customised Meg@POP IPVPN EthernetLink with PD (1M-1G)"),
    ethernetLinkTemplate("Enhanced EthernetLink"),
    ethernetLinkTemplate("Enhanced EthernetLink - Diginet"),
    ethernetLinkTemplate("Enhanced EthernetLink with Exchange Diversity"),
    ethernetLinkTemplate("EthernetLink - 100 Mbps IP Building LAN Switching"),
    ethernetLinkTemplate("EthernetLink (Low Speed)"),
    ethernetLinkTemplate("EthernetLink Auto Backup Port"),
    ethernetLinkTemplate("EthernetLink Auto Backup Port with Exchange Diversity"),
    ethernetLinkTemplate("EthernetLink IGA Port"),
    ethernetLinkTemplate("EthernetLink IGA Port (High Speed)"),
    ethernetLinkTemplate("EthernetLink IGA Port (High Speed))"),
    ethernetLinkTemplate("Headend - Auto-Backup w ED"),
    ethernetLinkTemplate("Headend - Main Ethernetlink"),
    ethernetLinkTemplate("High Speed Auto Backup EthernetLink - Diginet"),
    ethernetLinkTemplate("High Speed EthernetLink"),
    ethernetLinkTemplate("High Speed EthernetLink - Diginet"),
    ethernetLinkTemplate("High Speed EthernetLink for EduICT"),
    ethernetLinkTemplate("High Speed EthernetLink with Exchange Diversity"),
    ethernetLinkTemplate("High Speed EthernetLink with Path Diversity"),
    ethernetLinkTemplate("i-PN over CE-EthernetLink infra link"),
    ethernetLinkTemplate("i-PN over EthernetLink infra link"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink with Path Diversity"),
    ethernetLinkTemplate("MegaPOP Cloud Gateway Access"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Standby"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink AutoBackup"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink AutoBackup w Exchange Diversity (>100M)"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink AutoBackup with Exchange Diversity"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink DR"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Enhanced"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Enhanced with Exchange Diversity"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Exchange Diversity"),
    ethernetLinkTemplate("Meg@POP IPVPN EthernetLink Headend Scheme"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink International Gateway Access"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink International Gateway Access (>2M)"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Outdoor"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Outdoor (Temp)"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Satellite Access"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Satellite Access (>100M)"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Satellite Access Exch Div"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Satellite Access Exch Div (>100M)"),
    ethernetLinkTemplate("Meg@POP IPVPN Ethernetlink Temporary service"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink AutoBackup"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink AutoBackup with Exchange Diversity"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink Enhanced"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink Enhanced with Exchange Diversity"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink Exchange Diversity"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink Outdoor"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink Outdoor (Temp)"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink Temporary service"),
    ethernetLinkTemplate("Meg@POP_2 IPVPN Ethernetlink with Path Diversity"),

    
    ethernetLinkTemplate2C2P("PTP Meg@POP EVPL EthernetLink"),
    ethernetLinkTemplate2C2P("PTP Meg@POP EVPL EthernetLink (Temp Service)"),
  ])
  .endCategory()

  .category("EVPN EthernetLink")
  .serviceSpecs([
    eVPNEthernetLinkTemplate("EVPN Enhanced Ethernetlink"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink - Temp Express Installation"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink - Temp Express Installation (>2M)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink - Temp Installation"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink - Temp Installation (>2M)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink (Temp)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink Express Installation (>2Mbps)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink Express Installation (2Mbps)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink Installation (>2Mbps)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink Installation (2Mbps)"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink International Gateway Access"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink with Disaster Recovery"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink with Enhanced Exchange Diversity"),
    eVPNEthernetLinkTemplate("EVPN Ethernetlink with Exchange Diversity"),
    eVPNEthernetLinkTemplate("Meg@POP - EVPN Ethernetlink"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Enhanced Ethernetlink"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Enhanced Ethernetlink with Exchange Diversity"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Ethernetlink"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Ethernetlink International Gateway Access"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Ethernetlink Temp Service (15 Days)"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Ethernetlink with Disaster Recovery"),
    eVPNEthernetLinkTemplate("Meg@POP EVPN Ethernetlink with Exchange Diversity"),

    eVPNEthernetLinkTemplate2C2P("EVPN Ethernetlink Intl Gateway Access"),
    eVPNEthernetLinkTemplate2C2P("EVPN Ethernetlink (Singnet Wholesales)"),


    eVPNEthernetLinkTemplate2C2P("WS-EVPN EthernetLink (For SD-Connect)"),
    eVPNEthernetLinkTemplate2C2P("WS-EVPN EthernetLink Path Diversity (For SD-Connect)"),
    //eVPNEthernetLinkTemplate2C2P("EVPN Ethernetlink with Exchange Diversity"),
    eVPNEthernetLinkTemplate2C2P("(CE) EVPN Ethernetlink"),
    eVPNEthernetLinkTemplate2C2P("(CE) EVPN Enhanced Ethernetlink"),
    eVPNEthernetLinkTemplate2C2P("(CE) EVPN Ethernetlink with Exchange Diversity")
  ])
  .endCategory()

  .category("IPVPN E-Access")
  .serviceSpecs([
    eaccessTemplate("Meg@POP IPVPN E-Access Main"),
    eaccessTemplate("Meg@POP IPVPN E-Access (AE)"),
    eaccessTemplate("Meg@POP IPVPN E-Access (GPON)"),
    eaccessTemplate("Meg@POP IPVPN E-Access Outdoor"),
    eaccessTemplate("MPTMP Meg@POP EVPL E-Access (<1G)"),
    eaccessTemplate("OE Access (Infra) QP-EVPL Port"),
    eaccessTemplate("OE Access GPON"),
    eaccessTemplate("OE Access Service Port"),
    eaccessTemplate("PTMP Meg@POP Headend EVPL E-Access (<1G)"),
    eaccessTemplate("PTMP Meg@POP Tailend EVPL E-Access (<1G)"),
    eaccessTemplate("PTP Meg@POP EVPL E-Access (<1G)")
  ])
  .endCategory()

  .endCategory();



