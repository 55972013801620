import React, { useState} from 'react';
import {Modal} from 'antd'
import ServiceEdit from './serviceEdit.js'

export function ServiceEditModal(props){
  const {open:propOpen,onChange,onCancel,...otherProps}=props;
  const [open,setOpen]=useState(propOpen);

  const onModalCancel=()=>{setOpen(false); if (onCancel) onCancel(); }
  const onModalChange=(s)=>{setOpen(false); if (onChange) onChange(s)}
  

  return (
    <Modal open={open} wrapClassName="service-edit-modal" width={"100%"} afterClose={props.afterClose} closable={false} footer={null}>
       <ServiceEdit {...otherProps} edit={true} onChange={onModalChange} onCancel={onModalCancel}/>
    </Modal>
  );
}