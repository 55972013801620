import { chrspecCommonSet } from "../catalogs/CharacteristicsCatalog";
import {
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";
import { esrAddress, esrIndividual } from "./EntitySpecificationRelationship";
import { chrspec, CHR_OPTIONAL, CHR_RO } from "./CharacteristicSpecification";
import { Service } from "../service/Service";

export class ServiceSpecSchema {
  specs = () => [];
  decompose = () => [];

  constructor(props) {
    this.specs = props.specs;
    this.decompose = props.decompose;
  }
}
export default class ServiceSpecification {
  //A string. Name given to the specification.
  name = null;
  //A list of characteristic specifications (CharacteristicSpecification [*]).
  //List of characteristics that the entity can take.
  specCharacteristic = [];
  //A list of entity specification relationships (EntitySpecificationRelationship [*]).
  //Relationship to another specification.
  entitySpecRelationship = [];
  //A string. Description of the specification.
  description = null;
  //A boolean. isBundle determines whether specification represents
  //a single specification (false), or a bundle of specifications (true).
  isBundle = false;
  //A date time (DateTime). Date and time of the last update of the specification.
  lastUpdate = null;
  //A string. Used to indicate the current lifecycle status of this catalog item.
  lifecycleStatus = "Active";

  //A string. specification version.
  version = "1.0.0";
  //An uri (Uri). Hyperlink reference.
  href = null;
  //A string. unique identifier.
  id = null;
  //A string. When sub-classing, this defines the super-class.
  "@baseType" = null;
  //An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation" = null;
  //A string. When sub-classing, this defines the sub-class Extensible name.
  "@type" = "ServiceSpecification";
  //A list of service feature specifications (ServiceFeatureSpecification [*]).
  //A list of Features for this specification.
  featureSpecification = [];
  //A list of resource specification references (ResourceSpecificationRef [*]).
  //A list of resource specification references (ResourceSpecificationRef [*]).
  //The ResourceSpecification is required for a service specification with type ResourceFacingServiceSpecification (RFSS).
  resourceSpecification = [];
  //A list of service level specification references (ServiceLevelSpecificationRef [*]).
  //A list of service level specifications related to this service specification,
  //and which will need to be satisifiable for corresponding service instances; e.g. Gold, Platinum.
  serviceLevelSpecification = [];
  //A list of service spec relationships (ServiceSpecRelationship [*]).
  //A list of service specifications related to this specification,
  //e.g. migration, substitution, dependency or exclusivity relationship.
  serviceSpecRelationship = [];
  //A list of attachment ref or values (AttachmentRefOrValue [*]).
  //Attachments that may be of relevance to this specification, such as picture, document, media.
  attachment = [];
  //A list of constraint references (ConstraintRef [*]).
  //This is a list of constraint references applied to this specification.
  constraint = [];
  //A list of related parties (RelatedParty [*]).
  //Parties who manage or otherwise have an interest in this specification.
  relatedParty = [];
  //A target entity schema (TargetEntitySchema).
  //Pointer to a schema that defines the target entity.
  targetEntitySchema = [];
  //A time period. The period for which this REST resource is valid
  validFor = {};

  //NON-TMF

  //Service Decomposition Schema , instance of ServiceSchema class
  schema = null;

  //TODO: DFF INCOMPLIANCE . Service category must not be mandatory.
  //category A string. Is it a customer facing or resource facing service.
  category = null;

  constructor(pojo) {
    Object.entries(pojo).forEach((e) => {
      if (this.hasOwnProperty(e[0])) this[e[0]] = e[1];
      else console.error("ServiceSpecification unknown property", e);
    });
    if (!this.id) this.id = this.name;
  }

  specs = (flatVals) => [
    ...this.specCharacteristic,
    ...this.entitySpecRelationship,
    ...(this.schema?.specs(flatVals) ?? []),
  ];

  effectiveValues = (flatVals) =>
    Object.fromEntries(
      this.specs()
        .map((s) => [
          s.getFullName(),
          s.effectiveInstance(flatVals?.[s.getFullName()]),
        ])
        .filter((e) => e[1]?.value)
    );

  defaultValues = () =>
    Object.fromEntries(
      this.specs()
        .map((s) => [s.getFullName(), s.instanceFromValue(null)])
        .filter((e) => e[1]?.value)
    );

  onAddToCategory(categoryName, parentCategoryName) {
    const productType =
      (parentCategoryName ? parentCategoryName + " - " : "") + categoryName;
    this.specCharacteristic.push(
      chrspec("Product Type", "string", CHR_OPTIONAL, productType, CHR_RO)
    );
  }

  valuesByScope(values, scope) {
    return Object.entries(values ?? {})
      .filter(([name, inst]) => name.startsWith(scope) && inst)
      .map(([name, inst]) => inst)
      .filter((i) => i?.value);
  }

  updateInstance(instance, values, mapping) {
    const mapper = Object.fromEntries(
      this.specs(values).map((spec) => [
        spec.getFullName(),
        { getter: spec, setter: spec },
      ])
    );
    mapping?.forEach(([setterSpec, getterSpec]) => {
      const mapperRule = mapper[setterSpec.getFullName()];
      if (mapperRule) mapperRule.getter = getterSpec;
    });

    const mappedValues = Object.fromEntries(
      Object.entries(mapper).map(([name, mapperRule]) => {
        const { getter, setter } = mapperRule;
        const val = values[getter.getFullName()];
        return getter.id === setter.id
          ? [name, val]
          : [name, setter.instanceFromValue(getter.instanceToValue(val))];
      })
    );

    return new Service({
      ...instance,
      category: this.category,
      serviceSpecification: {
        id: this.id,
        name: this.name,
      },
      serviceCharacteristic: this.valuesByScope(
        mappedValues,
        "serviceCharacteristic"
      ),
      place: this.valuesByScope(mappedValues, "place"),
      relatedParty: this.valuesByScope(mappedValues, "relatedParty"),
    });
  }

  decompose(instance) {
    return this.schema.decompose(instance.asFlatObject());
  }
}
/*
export function svcspecOneSite(name) {
  return new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name)],
    entitySpecRelationship: [esrCustomerSiteAddress, esrCustomerSiteContact],
  });
}

export function svcspecTwoSites(name) {
  return new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name)],
    entitySpecRelationship: [
      esrAddress("A-Site.Address"),
      esrIndividual("A-Site.Site Contact"),
      esrAddress("B-Site.Address"),
      esrIndividual("B-Site.Site Contact"),
    ],
  });
}
*/
