import React, { useState, useRef } from 'react';
import {GeographicAddress} from './geographicAddress'
import AddressForm from './addressForm';
import NamedCache from '../common/namedCache';
import CustomSelectBase from '../common/CustomSelectBase';

function AddressInput(props) {

  const {value,onChange,addressbook:addressBook,edit,...otherProps}=props;
  /*local address book*/
  const localAddressBookRef = useRef(new NamedCache(addressBook));
  const { current: localAddressBook } = localAddressBookRef;
  /* select state */
  const [selectOptions, setSelectOptions] = useState(localAddressBook.asLabeledValues());
  const [open, setOpen] = useState(false);
  const [dropdownMode, setDropdownMode] = useState("select");
  const [selectValue, setSelectValue] = useState(value?.name);

  async function onDebouncedSearch(searchTerm,abortController) {
    console.debug("AddressInput:onDebouncedSearch", searchTerm,abortController);
    const uriEncodedSearchTerm=encodeURIComponent(searchTerm);
    const response =await fetch("https://developers.onemap.sg/commonapi/search?returnGeom=N&getAddrDetails=Y&searchVal="+uriEncodedSearchTerm
                                ,{signal:abortController.signal});
    const data=await response.json();
    console.debug("AddressInput:onDebouncedSearch onemap response",response,data);
    const addresses=data.results.filter(r=>r.POSTAL!=="NIL").map(a=>GeographicAddress.fromOneMapAddress(a));
    localAddressBook.setTransient(addresses);
    setSelectOptions(localAddressBook.asLabeledValues());
  }

  function onSelectChange(value, option) {
    console.debug("AddressInput:onChange", value, option);
    setSelectValue(value);
    if (onChange) onChange(localAddressBook.get(option.label));
  }

  function onOptionSelected(option) {
    console.debug("AddressInput:onOptionSelected", option);
    const selectedAddress = localAddressBook.get(option.label);
    console.debug("AddressInput:onOptionSelected sectedAddress", selectedAddress);
    if (selectedAddress?.hasUnit()) return null;
    //if selected addres has no unit - open unit entry form
    setDropdownMode("unit");
    return "unit";
  }



  function onFormFinish(newAddress) {

    console.debug("AddressInput:onFormFinish newAddress:", newAddress);
    localAddressBook.setPersistent(newAddress);
    setSelectOptions(localAddressBook.asLabeledValues());
    setOpen(false);
    onSelectChange(newAddress.name,{label:newAddress.name,value:newAddress.name});
  }

  function onFormCancel() {
    setDropdownMode("select");
  }

  function onClickManualEditMode(e) {
    console.debug("AddressInput:onClickManualEditMode", e);
    setDropdownMode("address");
  }

  function renderForm(option, mode) {
    console.debug("AddressInput:renderForm",option,mode);
    if (mode==="select") return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <span>Start typing to search or <a onClick={onClickManualEditMode}>click here to enter address manually</a></span>
      );
    
    return (
        <AddressForm
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          address={localAddressBook.get(option?.label)}
          mode={mode}
          onFinish={onFormFinish}
          onCancel={onFormCancel}
        />
      );
  }

  return (edit)?(
    <CustomSelectBase
      {...otherProps}
      className="form-field-long"
      options={selectOptions}
      value={selectValue}
      mode={dropdownMode}
      onDropdownModeChange={setDropdownMode}
      open={open}
      onDropdownVisibleChange={setOpen}
      dropdownMatchSelectWidth={450}
      onChange={onSelectChange}
      onDebouncedSearch={onDebouncedSearch}
      onOptionSelected={onOptionSelected}
      footerRender={renderForm}
      placeholder="Select or enter an address"
    />
  )
  :(
    <div className="form-field-long">{props.value?.name ?? "N/A"}</div>
    );
}

export default AddressInput;