import { useRef, useState, useEffect } from "react";

import { FTTH_RESOURCE_ORDER_URL } from "../api/endpoints";
import { fetchText } from "../api/utils";
import { defaultAuthNContext } from "../api/authorization";
import { v4 as uuid } from "uuid";
import { Button, Input, Space, Popconfirm, message } from 'antd';

import {
  DownCircleTwoTone
} from "@ant-design/icons";




function FtthNumberInput(props) {

  const { value, defaultVal, serviceSpec, onFtthGeneration, edit, ...otherProps } = props;
  const [ftthNo, setFtthNo] = useState(value);
  const category = serviceSpec?.name?.includes('NLT') ? "FTH0102" : "FTH0103";
  const disabledRef = useRef(false)
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    onFtthGeneration(inputValue);
    setFtthNo(inputValue)
  };

  const cancel = (e) => {
    console.log(e);
    message.error('Click on No');
  };


  const generateFTTH = (e) => {
    setLoading(true)
    defaultAuthNContext.getToken().then((tkn) => {
      fetchText(FTTH_RESOURCE_ORDER_URL, {
        method: "POST",
        cache: "no-cache",
        headers: {
          FTTH_REQ_ID: uuid().toString(),
          "Content-Type": "application/json",
          Authorization: `Bearer ${tkn}`,
        },
        body: JSON.stringify({
          category: "D365",
          id: "com.singtel.numbering.ftthno",
          orderItem: [
            {
              action: "modify",
              resource: {
                category: category,
                resourceStatus: "reserved",
                resourceSpecification: {
                  id: "com.singtel.numbering.ftthno",
                  name: "FTTH Number",
                },
              },
            },
          ],
        }),
      })
        .then((data) => {
          const parsedData = JSON.parse(data)
          if (parsedData.state === "completed") {
            setFtthNo(parsedData.orderItem[0].resource.name);
            onFtthGeneration(parsedData.orderItem[0].resource.name);
            disabledRef.current = true
          }
          else {
          setFtthNo("Error, Please regenerate")
          onFtthGeneration("Error, Please regenerate");
          disabledRef.current = false
          setLoading(false)
          }
        })
        .catch((error) => {
          setFtthNo("Error, Please regenerate")
          onFtthGeneration("Error, Please regenerate");
          disabledRef.current = false
          setLoading(false)

        });
    });

  };

  // useEffect(() => {
  //   if (value) {
  //     setFtthNo(value);
  //     onFtthGeneration(value);
  //     return;
  //   }
  // }, []);



 

  return edit ? (
    <>
      <Space.Compact className="form-field-long">
        <Input 
        defaultValue={value}
        allowClear
        placeholder="Enter Existing FTTH number or generate new"
        value={ftthNo}
        onChange={handleChange}
        disabled={disabledRef.current}
        />
        <Popconfirm
        disabled={disabledRef.current}
          title="Are you sure you want to generate new FTTH number ?"
          description="asdasdasdas"
          onConfirm={generateFTTH}
       //   onCancel={cancel}
          okText="Yes"
          cancelText="No"
          
        >
          <Button type="primary" loading={loading}  hidden={disabledRef.current} disabled={disabledRef.current}>Generate </Button>
        </Popconfirm>
      </Space.Compact>
    </>
  ) : (
    <>
      <div className="form-field-short">{value}</div>
    </>
  );
}

export default FtthNumberInput;
