import {ServiceSchema,mapperConst} from './ServiceSchema'
import ServiceSpecification from '../service-catalog/ServiceSpecification'
import {chrspecCommonSet,chrspecFieldServiceAddressRole} from '../catalogs/CharacteristicsCatalog'
import {esrWorkSiteAddress,esrWorkSiteContact,esrASiteAddress,esrASiteContact,esrBSiteAddress,esrBSiteContact} from '../catalogs/EntitySpecRelationsCatalog'
import {svcspecCopperAdvanceInstallation} from '../catalogs/CatalogFieldServices'

import {ServiceOrderItem} from '../serviceOrder/serviceOrder'

export function schemaC2CFieldService(props){

  const {name,installationSpec}=props;

  const schema={
  default:{
      specs:(flatInstance)=>[
        //esrCustomerSiteAddress,esrCustomerSiteContact
      ],
      decompose:(flatInstance)=>{
        const svcInstallationA=installationSpec.updateInstance({},flatInstance,
          [//mapping
            [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
            [esrWorkSiteAddress,esrASiteAddress],
            [esrWorkSiteContact,esrASiteContact]
          ]);


          const svcInstallationB=installationSpec.updateInstance({},flatInstance,
            [//mapping
              [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
              [esrWorkSiteAddress,esrBSiteAddress],
              [esrWorkSiteContact,esrBSiteContact]
            ]);

        
            var svcWiringA=null;
            var svcWiringB=null;
            const wiring=flatInstance["serviceCharacteristic:Wiring"]?.value;
  
            if (wiring==="Advance Wiring"){
              svcWiringA=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
                [//mapping
                  [chrspecFieldServiceAddressRole,mapperConst("A-Site")],
                  [esrWorkSiteAddress,esrASiteAddress],
                  [esrWorkSiteContact,esrASiteContact]
                ]);
              
                svcWiringB=svcspecCopperAdvanceInstallation.updateInstance({},flatInstance,
                  [//mapping
                    [chrspecFieldServiceAddressRole,mapperConst("B-Site")],
                    [esrWorkSiteAddress,esrBSiteAddress],
                    [esrWorkSiteContact,esrBSiteContact]
                  ]);
            }

        console.debug("schemaC2CFieldService:decompose default",svcInstallationA,svcInstallationB);


        return [
          { action: "add", service: svcInstallationA },
          { action: "add", service: svcInstallationB },
          { action: "add", service: svcWiringA },
          { action: "add", service: svcWiringB }
        ]
          .filter((a) => a.service)
          .map((a) => new ServiceOrderItem(a));
      }
    }
  };

  return new ServiceSchema({
    name: name,
    schema:schema
  });

}

export function svcspecC2CFieldService(props){
  const {name,installationServiceSpec,characteristics}=props;
  return new ServiceSpecification({
    name:name,
    specCharacteristic:[...chrspecCommonSet(name),...characteristics],
    entitySpecRelationship:[
      esrASiteAddress,esrASiteContact,esrBSiteAddress,esrBSiteContact
    ],
    schema:schemaC2CFieldService({name:"C2CFS-"+name,installationSpec:installationServiceSpec})
  })
}