import { getDateAndTime } from "../common/DateTimeUtils";
import { svcspecC2CCableOnly } from "../schema/schemaC2CCableOnly";
import { chrspec } from "../service-catalog/CharacteristicSpecification";
import { Characteristic } from "../service/Characteristic";
import { ServiceOrderItem, soiRelationship } from "../serviceOrder/serviceOrder";

export const getOrderItemByJobId = (serviceOrder, jobId) => {
  return serviceOrder?.orderItem?.filter((oi) => oi?.service?.appointment?.id === jobId
  );
};

export const getAppointmentDetails = (appointmentDetails, retriveServiceOrder ) => {

  console.log('getAppointmentDetails: appointmentDetails', appointmentDetails);
  console.log('getAppointmentDetails: serviceOrder', retriveServiceOrder);

  const appointmentDetailsMap = new Map();
  appointmentDetails?.map((appointment) => {
    const orderItems =       getOrderItemByJobId(retriveServiceOrder, appointment?.id);
   
   orderItems?.forEach((orderItem) => {
      console.log("Order ITEM --- > ", orderItem);
      const category = orderItem?.service?.serviceSpecification?.name?orderItem?.service?.serviceSpecification?.name: orderItem?.service?.category;
      const state = orderItem?.service?.state;
      const appointmentDate = getDateAndTime(appointment);
      const jobId = orderItem?.service?.appointment.id;
      const appointmentGroupId = orderItem?.service?.serviceCharacteristic?.find((ac) => ac?.name === "Appointment Group ID")?.value;
      const site = orderItem?.service?.serviceCharacteristic?.find((ac) => ac?.name === "Field Service Address Role")?.value;
      const cfs = orderItem?.service?.serviceCharacteristic?.find((ac) => ac?.name === "Site ID")?.value;

      if (appointmentDetailsMap.has(cfs)) {
        const existingRecord = appointmentDetailsMap.get(cfs);
        existingRecord.push({
          appointmentGroupId,
          site,
          state,
          status: appointment.status,
          category,
          appointmentDate,
          jobId,
          cfs,
        });
        appointmentDetailsMap.set(cfs, existingRecord);
      } else {
        appointmentDetailsMap.set(cfs, [
          {
            appointmentGroupId,
            site,
            state,
            status: appointment.status,
            category,
            appointmentDate,
            jobId,
            cfs,
          },
        ]);
      }
     
    });
  });

  console.log('Final appointmentDetailsMap ',appointmentDetailsMap)
  
  return appointmentDetailsMap;
};



export const getAppointmentGroupDetails = (serviceOrderResponse) => {
  console.log('getAppointmentGroupDetails: serviceOrderResponse', serviceOrderResponse);
  let appointmentGroupDetails = new Map();
  serviceOrderResponse?.orderItem?.map(oi => {
    let agn =  oi?.service?.serviceCharacteristic?.find((ac) => ac?.name === "Appointment Group Name")?.value
    let agi =  oi?.service?.serviceCharacteristic?.find((ac) => ac?.name === "Appointment Group ID")?.value
    const cfs = oi?.service?.serviceCharacteristic?.find((ac) => ac?.name === "Site ID")?.value;
   if (appointmentGroupDetails.has(cfs)) {
    appointmentGroupDetails.get(cfs).set(agn, agi)
   }else{
    appointmentGroupDetails.set(cfs,new Map().set(agn, agi))
   }
  });

  console.log('getAppointmentGroupDetails: serviceOrderResponse:cfsIds', appointmentGroupDetails);
  return  appointmentGroupDetails

};



export const validateEmails = (string) => {
  var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  var result = string.replace(/\s/g, "").split(/,|;/);
  for (var i = 0; i < result.length; i++) {
    if (!regex.test(result[i])) {
      return false;
    }
  }
  return true;
};


export const getProductTypeCharSpec = (productType) => {
  return chrspec({
    name:"Product Type",
    type:"string",
    isMandatory:false,
    isReadOnly:true,
    defaultVal:productType
  })
};

export function getC2CTemplate (_name, _installationServiceSpec, _characteristics){
  return  svcspecC2CCableOnly({
    name:_name,
    installationServiceSpec:_installationServiceSpec,
    characteristics:_characteristics
  })
};



export function addAppointmentGroupName (specification, appointmentGroupName = "default"){
  if(specification && specification.serviceCharacteristic){
    specification.serviceCharacteristic.push( new Characteristic({
      "@type": "Characteristic",
      name: "Appointment Group Name",
      value: appointmentGroupName,
      valueType: "string",
    }))
  }else{
    specification.service.serviceCharacteristic.push( new Characteristic({
      "@type": "Characteristic",
      name: "Appointment Group Name",
      value: appointmentGroupName,
      valueType: "string",
    }))
  }
};

export function adGenericSpecification (specification, appointmentGroupname, 
  id, category, name,  ){
    specification.service.serviceSpecification.id = id
    specification.service.serviceSpecification.name = name
    specification.service.category = category
    addAppointmentGroupName(specification, appointmentGroupname)
};



export const SO = "SO";
export const OBJ = "OBJ";
export const JSON = "JSON";

export function getGenericInstallations(genericInstallationsMap,flatInstance, addressAndContacts, whatObj){
  let genericInstallations = [];
  genericInstallationsMap?.forEach((key, value) => {
    const genEqptInstl = key ? key?.updateInstance({}, flatInstance, addressAndContacts):null;
    addAppointmentGroupName(genEqptInstl, value)
    switch (whatObj) {
      case 'SO':
        genericInstallations.push(new ServiceOrderItem({
          action: "add",
          service: genEqptInstl
        }))
        break;
      case 'OBJ':
        genericInstallations.push(genEqptInstl)
        break;
      case 'JSON':
        genericInstallations.push({ action: "add", service: genEqptInstl})
          break;
      default:
        genericInstallations.push({ action: "add", service: genEqptInstl})
    }
    });
    return genericInstallations;
}




export function getGenericConnectivitysA(genericConnectivity,flatInstance, addressAndContacts, whatObj){
  let genericConnectivitys = [];
  if(genericConnectivity){
    genericConnectivity?.forEach((cableSpec, groupName) => {
    const genericCableSpec = cableSpec?cableSpec?.updateInstance({}, flatInstance, addressAndContacts):null;
      addAppointmentGroupName(genericCableSpec, groupName)
      switch (whatObj) {
        case 'SO':
          genericConnectivitys.push(new ServiceOrderItem({
            action: "add",
            service: genericCableSpec
          }))
          break;
        case 'OBJ':
          genericConnectivitys.push(genericCableSpec)
          break;
        case 'JSON':
          genericConnectivitys.push({ action: "add", service: genericCableSpec})
            break;
        default:
          genericConnectivitys.push({ action: "add", service: genericCableSpec})
      }
      });
  }
  return genericConnectivitys;
}



export function getGenericRecoveryA(genericRecovery,flatInstance, addressAndContacts, whatObj){
  let genericRecoveries = [];
  genericRecovery?.forEach((key, value) => {
    const recovery = key ? key?.updateInstance({}, flatInstance, addressAndContacts):null;
    addAppointmentGroupName(recovery, value)

    switch (whatObj) {
      case 'SO':
        genericRecoveries.push(new ServiceOrderItem({
          action: "add",
          service: recovery
        }))
        break;
      case 'OBJ':
        genericRecoveries.push(recovery)
        break;
      case 'JSON':
        genericRecoveries.push({ action: "add", service: recovery})
          break;
      default:
        genericRecoveries.push({ action: "add", service: recovery})
    }


    
    });
    return genericRecoveries;
}




export function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj; // If obj is not an object, return it as is
  }
  
  // Create a new object or array to hold the cloned properties
  const clone = Array.isArray(obj) ? [] : {};
  
  // Iterate over each property in the original object or array
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Recursively clone nested objects or arrays
      clone[key] = deepClone(obj[key]);
    }
  }
  
  return clone;
}
















