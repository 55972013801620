import ServiceCatalog from '../service-catalog/ServiceCatalog'
import {svcspecC2PCopper} from '../schema/schemaC2P'

import { svcspecPhoneNetEquipmentInstallation} from './CatalogFieldServices'
import { chrspecProductTypeOf,chrspecPremiseWiring } from './CharacteristicsCatalog'


const chrSpecProductTypePhoneNet = chrspecProductTypeOf("PhoneNet - PhoneNet");

function phoneNetTemplate(name) {
  return svcspecC2PCopper({
    name: name,
    characteristics: [chrSpecProductTypePhoneNet,chrspecPremiseWiring],
    installationServiceSpec: svcspecPhoneNetEquipmentInstallation,
  });
}

export const catalogPhonenet=((new ServiceCatalog())
  .category("Phonenet")
    .serviceSpecs([
      phoneNetTemplate("PhoneNet - PhoneNet"),
      phoneNetTemplate("External Relocation Charge"),
      phoneNetTemplate("PhoneNet Cancellation Charges"),
      phoneNetTemplate("PhoneNet Line Charge"),
      phoneNetTemplate("PhoneNet Mini System"),
      phoneNetTemplate("PhoneNet Other One Time Charges"),
      phoneNetTemplate("PhoneNet Temporary Line"),
      phoneNetTemplate("PhoneNet Terminal Equipment"),
      phoneNetTemplate("PhoneNet Visit, Test, Standby Charges"),
      phoneNetTemplate("Mobile PhoneNet Subscription"),
      phoneNetTemplate("PhoneMail Service"),
      phoneNetTemplate("PhoneNet Wiring Charges"),
      phoneNetTemplate("PhoneNet Optional Features"),
      phoneNetTemplate("PhoneNet Value Added Service"),
      phoneNetTemplate("PhoneNet Exchange Line"),
      phoneNetTemplate("PhoneNet Auxiliary Line"),
      phoneNetTemplate("Dummy Product for Local Exch"),
      phoneNetTemplate("Wiring reqd from TSO"),
      phoneNetTemplate("Internal Wiring"),
      phoneNetTemplate("Internal Wiring for CSP"),
      phoneNetTemplate("Overhead Wiring Required "),
      phoneNetTemplate("Overhead Wire (Span) "),
      phoneNetTemplate("CSP Line")     
     ])
  .endCategory());
