import React from "react";
import { Input, DatePicker, Select } from "antd";
import moment from "moment";
import AddressInput from "../address/addressInput";
import ContactInput from "../party/contactInput";
import FtthNumberInput from "../common/FtthNumberInput";
export function CharacteristicInput(props) {
  const { spec, value: instance, edit, serviceSpec, onChange } = props;

  function onInputChange(value) {
    onChange?.(spec.instanceFromValue(value));
  }

  function onStringInputChange(e) {
    onInputChange(e.target.value);
  }

  function onDateInputChange(dateAsMoment, dateAsString) {
    console.debug("onDateInputChange", dateAsMoment, dateAsString);
    onInputChange(dateAsMoment?.format());
  }



  const displayValue = (value) => value ?? "N/A";

  const DATE_FORMAT = "DD/MM/YYYY";

  const renderers = {
    render_date: (spec, value) =>
      edit ? (
        <DatePicker
          value={value ? moment(value) : undefined}
          className="form-field-short"
          format={DATE_FORMAT}
          disabled={spec.isReadOnly()}
          disabledDate={(current) => {
            return moment().add(-1, "days") >= current;
          }}
          onChange={onDateInputChange}
        />
      ) : (
        <div className="form-field-short">
          {value ? moment(value).format(DATE_FORMAT) : ""}
        </div>
      ),
    render_GeographicAddressSpec: (spec, value) => (
      <AddressInput {...props} value={value} onChange={onInputChange} />
    ),
    render_IndividualSpec: (spec, value) => (
      <ContactInput
        {...props}
        className="form-field-long"
        value={value}
        onChange={onInputChange}
      />
    ),
    render_string: (spec, value) => {
      if (!edit) return <div className="form-field-short">{value}</div>;
      if (spec.isChoiseList()) {
        const selectOptions = spec.characteristicValueSpecification.map(
          (m) => ({ value: m.value, label: m.value })
        );
        return (
          <Select
            value={value}
            className="form-field-short"
            placeholder={"Select " + spec.getName()}
            options={selectOptions}
            disabled={spec.isReadOnly()}
            onChange={onInputChange}
          />
        );
      } else
        return (
          <Input
            value={value}
            className="form-field-short"
            disabled={spec.isReadOnly()}
            onChange={onStringInputChange}
          />
        );
    },
    render_ftthno: (spec, value) => {
      if (!edit) return <div className="form-field-short">{value}</div>;
        return (
         <FtthNumberInput  disabled={spec.isReadOnly()} {...props} edit={edit} value={value} onFtthGeneration={onInputChange} serviceSpec={serviceSpec}  />
        );
    },
    fallback: (spec, value) => (
      <div className="value form-field-short">
        {displayValue(value?.toString())}
      </div>
    ),
  };

  const instanceValue = spec.instanceToValue(instance);
  const valueType = spec.valueType ?? spec.name;

  const renderer = renderers["render_" + valueType] ?? renderers.fallback;
  return renderer(spec, instanceValue);
}

export default CharacteristicInput;
