import {aad} from './auth'
import { MsalProvider, MsalAuthenticationTemplate} from "@azure/msal-react";
import {InteractionType } from "@azure/msal-browser";


//Shorthand component to initialize AAD authentication for app
export function AadAuthenticated(props){
  return (<>
            <MsalProvider instance={aad}>
                <MsalAuthenticationTemplate {...props} interactionType={InteractionType.Redirect}/>
            </MsalProvider>
          </>);
}


