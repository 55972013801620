//Utility functions to patch API requests/responses to workaround DFF non-compliances to TMF Open API specs

export function dffPatchCreateSORequest(originalJSON){
  const request=JSON.parse(originalJSON);

  console.debug("patchRequestDFFRel3:original request",request);
  

  request.relatedParty.forEach(orderParty=>{
  
    if (orderParty?.["@type"]==="Organization") {

    //BUG: DFF Organization organizationIdentification is array as per TMF spec but is single object in DFF
      orderParty.organizationIdentification=orderParty.organizationIdentification[0];
        //BUG: Unrecognized field "identificationType" (class com.singtel.dataObjects.TMF641.OrganizationIdentification)
    delete orderParty.organizationIdentification.identificationType;
    delete orderParty.organizationIdentification["@type"];
    delete orderParty.organizationIdentification["@baseType"];
    delete orderParty.organizationIdentification["@schemaLocation"];
    }

    if (orderParty?.["@type"]==="Individual") {
         //BUG:Unrecognized field "fullName" 
         delete orderParty.fullName;
    }

    orderParty?.partyCharacteristic?.forEach(c=>{
      //BUG: Unrecognized field "valueType" (class com.singtel.dataObjects.TMF641.PartyCharacteristic)
      delete c.valueType
       //BUG: Unrecognized field "@type" (class com.singtel.dataObjects.TMF641.PartyCharacteristic
      delete c["@type"];
    })
  });

  //patch characteristics
  request.serviceOrderItem.forEach((soi)=>{

    //Unrecognized field "serviceOrderItem"
    delete soi.serviceOrderItem;

    //Patching characteristics
    soi.service.serviceCharacteristic.forEach(characteristic=>{
      //TODO: Rename "Order Type" to "Standalone AB: Order Type" . Standartize characteristic names and values. Source system should not be embedded
      if (characteristic.name==="Order Type") {
        characteristic.name="Standalone AB: Order Type"
        characteristic.value="Standalone AB: "+characteristic.value;
      };

      //BUG: Product type should match DFF catalogue. Prefixing with source system should not be required
      if (characteristic.name==="Product Type") {
        characteristic.value="Standalone AB: "+characteristic.value;
      }
    })

    //Patching characteristics parties
 

    soi.service.relatedParty.forEach(party=>{
      //Unrecognized field "fullName" 
      delete party.fullName;

      //TODO: Unify site-related namings
      if (party.role==="Work Site.Site Contact") party.role="Site";
      else if (party.role==="A-Site.Site Contact") party.role="A-Site: Site";
      else if (party.role==="B-Site.Site Contact") party.role="B-Site: Site";
    });

    //patching place
    soi.service.place.forEach(place=>{
     //TMF INCOMPLIANCE "geographicSubAddress" must be array . in DFF - object
     place.geographicSubAddress=place.geographicSubAddress?.[0];

     //TMF INCOMPLIANCE buildingName in DFF is on address level, must be in subaddres
     place.buildingName=place.geographicSubAddress.buildingName;
     delete place.geographicSubAddress.buildingName;

     //TMF INCOMPLIANCE Unrecognized field "subAddressType"
     delete place.geographicSubAddress.subAddressType;

     //Unrecognized field "subUnitType"
     delete place.geographicSubAddress.subUnitType;

     //TODO: Unify site-related namings
    
     if (place.role==="Work Site.Address") place.role="Work Address";
     else if (place.role==="A-Site.Address") place.role="A-Site";
     else if (place.role==="B-Site.Address") place.role="B-Site";

    });
  })

  //Rename serviceOrderItem to orderItem
  request.orderItem=request.serviceOrderItem;
  delete  request.serviceOrderItem;

  return JSON.stringify(request);
}
