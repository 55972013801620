import React from "react";

import { DashboardOutlined, BookOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { BookingWizard } from "../appBookingWizard/BookingWizard";
import { SearchServiceOrder } from "../searchServiceOrder/SearchServiceOrder";
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
  Link,
} from "react-router-dom";

const { Sider, Content } = Layout;

export function NavigationLayout(props) {

  const { userName, email} = props;

  let navigate = useNavigate();
  const refreshPage = () => {
    navigate(1);
  };
  const selectedKey = useLocation().pathname;

  const highlight = () => {
    if (selectedKey === "/dashboard") {
      return ["Dashboard"];
    } else if (selectedKey === "/newBooking") {
      return ["New Booking"];
    }
  };

  return (
    <div
    >
      <Layout style={{ minHeight: "100vh", width: "100%" }}>
        <Sider
          breakpoint={"lg"}
          collapsedWidth={50}
          theme="light"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className="logo" />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={highlight()}
            items={[
              {
                label: <Link to="/dashboard">Dashboard</Link>,
                icon: <DashboardOutlined />,
                key: "1",
                onClick: () => {
                  navigate("/dashboard");
                },
              },
              {
                label: <Link to="/newBooking">New Booking</Link>,
                icon: <BookOutlined />,
                key: "2",
                onClick: () => {
                  navigate("/newBooking");
                },
              },
            ]}
          />
        </Sider>
        <Layout
         style={{
        
          background: "#fff"
          }}
        >
          <Content
           
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: "#fff"
            }}
          >
            <Routes>
              <Route path="/dashboard" element={<SearchServiceOrder />} />
              <Route
                path="/login"
                action={refreshPage}
                element={<SearchServiceOrder />}
              />
              <Route
                path="/"
                action={refreshPage}
                element={<SearchServiceOrder />}
              />
              <Route path="/newBooking" element={<BookingWizard userName={userName} email={email}/>} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
