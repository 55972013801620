export async function fetchText(resource, props) {
  console.log('resource:',  resource , '    props:' ,props)
  const response = await fetch(resource, props);
  return await new Promise((resolve, reject) => {
    response.text().then((data) => {
      const summary = {
        ok: response.ok,
        response: {
          status: response.status,
          statusText: response.statusText,
          body: data ?? null,
        },
        request: { url: response.url, ...props },
      };
      console.debug("fetchText response:\n%o", summary);
      if (response.ok) resolve(data);
      else reject(new Error("Request failed", { cause: summary }));
    });
  });
}

export async function fetchJson(resource, props) {
  const response = await fetch(resource, props);
  return await new Promise((resolve, reject) => {
    response.json().then((data) => {
      const summary = {
        ok: response.ok,
        response: {
          status: response.status,
          statusText: response.statusText,
          body: data ?? null,
        },
        request: { url: response.url, ...props },
      };

      console.debug("fetchJson response:\n%o", summary);

      if (response.ok)
        resolve(data);
      else
        reject(new Error("Request failed", { cause: summary }));
    });
  });
}


export async function fetchJsonRes(resource, props) {
  const response = await fetch(resource, props);
  return await new Promise((resolve, reject) => {
    response.text().then((data) => {
      const summary = {
        ok: response.ok,
        response: {
          status: response.status,
          statusText: response.statusText,
          body: data ?? null,
        },
        request: { url: response.url, ...props },
      };
      console.log("fetchText response:\n%o", summary);
      if (response.ok) resolve(summary);
      else if(!response.ok)resolve(summary);
      else reject(new Error("Request failed", { cause: summary }));
    });
  });
}
