
function oneMapValue(oneMapValue){
  return (oneMapValue && oneMapValue!=="NIL")?oneMapValue:"";
}

export class GeographicSubAddress{
  //Representation of a GeographicSubAddress 
  //It is used for addressing within a property in an urban area (country properties are often defined differently). It may refer to a building, a building cluster, or a floor of a multistory building.

  //A string. allows for buildings that have well-known names.
  buildingName=null;
  //A string. Link to the subAddress.
  href=null;
  //A string. Unique Identifier of the subAddress.
  id=null;
  //A string. used where a level type may be repeated e.g. BASEMENT 1, BASEMENT 2.
  levelNumber=null;
  //A string. describes level types within a building.
  levelType=null;
  //A string. Name of the subAddress to identify it with a meaningful identification.
  name=null;
  //A string. private streets internal to a property (e.g. a university) may have internal names that are not recorded by the land title office.
  privateStreetName=null;
  //A string. private streets numbers internal to a private street.
  privateStreetNumber=null;
  //A string. Type of subAddress : it can be a subunit or a private street.
  subAddressType=null;
  //A string. the discriminator used for the subunit often just a simple number e.g. FLAT 5, may also be a range.
  subUnitNumber=null;
  //A string. the type of subunit e.g.BERTH, FLAT, PIER, SUITE, SHOP, TOWER, UNIT, WHARF.
  subUnitType="unit";

  constructor(pojo){
    Object.entries(pojo).forEach(e => {if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]});
    if (this.subUnitNumber!=="") {
      this.subUnitType='unit';
      this.subAddressType='unit';
    }
  }
}

export class GeographicAddress{

  ["@type"]="GeographicAddress"
  //A string. Unique reference of the place.
  href="";
  //A string. Unique identifier of the place.
  id="";
  //A string. A user-friendly name for the place, such as [Paris Store], [London Store], [Main Home].
  name="";
  //A string. City that the address is in.
  city="Singapore";
  //A string. Country that the address is in.
  country="Singapore";
  //A geographic location ref or value (GeographicLocationRefOrValue).
  geographicLocation=null;

  //A list of geographic sub addresses (GeographicSubAddress [*]). Representation of a GeographicSubAddress 
  //It is used for addressing within a property in an urban area (country properties are often defined differently). 
  //It may refer to a building, a building cluster, or a floor of a multistory building.
  geographicSubAddress=[];

  //A string. An area of defined or undefined boundaries within a local authority or other legislatively defined area,
  //usually rural or semi-rural in nature. [ANZLIC-STREET], or a suburb, a bounded locality within a city, 
  //town or shire principally of urban character [ANZLICSTREET].
  locality=null;

  //A string. descriptor for a postal delivery area, used to speed and simplify the delivery of mail (also known as zipcode).
  postcode=null;
  //A string. the State or Province that the address is in.
  stateOrProvince=null;
  //A string. Name of the street or other street type.
  streetName=null;
  //A string. Number identifying a specific property on a public street. It may be combined with streetNrLast for ranged addresses.
  streetNr=null;
  //A string. Last number in a range of street numbers allocated to a property.
  streetNrLast=null;
  //A string. Last street number suffix for a ranged address.
  streetNrLastSuffix=null;
  //A string. the first street number suffix.
  streetNrSuffix=null;
  //A string. A modifier denoting a relative direction.
  streetSuffix=null;
  //A string. alley, avenue, boulevard, brae, crescent, drive, highway, lane, terrace, parade, place, tarn, way, wharf.
  streetType=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]});
    
   this.name=this.generateDescriptor();
  }

  static fromOneMapAddress(oneMapAddress){
    /* OneMap address structure sample
    {
      "SEARCHVAL": "KERRISDALE",
      "BLK_NO": "34",
      "ROAD_NAME": "STURDEE ROAD",
      "BUILDING": "KERRISDALE",
      "ADDRESS": "34 STURDEE ROAD KERRISDALE SINGAPORE 207854",
      "POSTAL": "207854"
    }*/
    return new GeographicAddress({
        streetNr:oneMapValue(oneMapAddress.BLK_NO),
        streetName:oneMapValue(oneMapAddress.ROAD_NAME),
        postcode: oneMapValue(oneMapAddress.POSTAL),
        geographicSubAddress:[new GeographicSubAddress({buildingName:oneMapValue(oneMapAddress.BUILDING)})]
      }
    );
  }

  generateDescriptor(){
    const streetAddr=((this.streetNr!=="")?" "+this.streetNr:"")
                     +((this.streetName!=="")?" "+this.streetName:"");

    return ""
       +((this.postcode!=="")?this.postcode:"")
       +((streetAddr!=="")?","+streetAddr:"")
       +((this?.geographicSubAddress[0].buildingName && this?.geographicSubAddress[0].buildingName!=="")?", "+this.geographicSubAddress[0].buildingName:"")
       +((this?.geographicSubAddress[0].subUnitNumber && this?.geographicSubAddress[0].subUnitNumber!=="")?", "+this?.geographicSubAddress[0].subUnitNumber:"")
       ;

  }

  doesMatch(searchString){
    return Object.entries(this).find(([key,val])=>val.includes(searchString))!==undefined 
                          || this.geographicSubAddress?.find(su=>Object.entries(su).find(([key,val])=>val.includes(searchString)));
  }

  setUnit(unitNumber){
    const unitSubAddress=new GeographicSubAddress({
      ...this?.geographicSubAddress[0],
      subUnitNumber:unitNumber
    });
    this.geographicSubAddress=[unitSubAddress];
 
    this.name=this.generateDescriptor();
  }

  hasPostcode(){
    return (this.postcode && this.postcode!=="");
  }

  hasUnit(){
    return (this?.geographicSubAddress?.[0].subUnitNumber && this?.geographicSubAddress?.[0].subUnitNumber!=="");
  }
}