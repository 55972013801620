import { useState, useEffect,useRef,useMemo} from 'react';
import {Progress,Result} from 'antd';

export function Waiter(props){

  const {initialMessage, expectedDuration}=props;
  const [elapsed,setElapsed]=useState(0);
  const [phrase,setPhrase]=useState(initialMessage);
  const elapsedRef=useRef(0);
  const iPhrase=useRef(0);


  const phrs=useMemo(()=>shuffledPhrases(),[]);

  const period=1;


  
  useEffect(() => {
    const interval = setInterval(() => {
          elapsedRef.current=elapsedRef.current+period;
          setElapsed(elapsedRef.current);
        }
        , period*1000);
      
      return () => clearInterval(interval);
    }
    ,[]
  );

   
  useEffect(() => {
    const interval = setInterval(() => {
          setPhrase(phrs[iPhrase.current]);
          iPhrase.current+=1;
          if (iPhrase.current>phrs.length)  iPhrase.current=0;
        }
        , 10000);
      
      return () => clearInterval(interval);
    }
    ,[]
  );

  return(
    <Result
    icon={<Progress size={150} type="dashboard" percent={Math.round(100*elapsed/expectedDuration)} />}
    title="We apologize for keeping you waiting"
    subTitle= {phrase}
    />
  )

}

function shuffledPhrases() {
  const shuffledArray = phrases.slice(); // make a copy of the original array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const phrases=[
  "Don't worry, we'll be with you faster than you can say supercalifragilisticexpialidocious.",
"Our computer hamsters are spinning their wheels as fast as they can.",
"Our system is currently in brainstorm mode, working to come up with the best possible response. We appreciate your patience as we put our electronic brains together.",
"Our system is like a chef in the kitchen, taking a coffee break before putting the finishing touches on the perfect response for you. It may take some coffee, but the end result will be worth it.",
"Our system is like a marathon runner, taking a quick coffee break before crossing the finish line with the perfect response for you. We appreciate your patience as we catch our breath.",
"Our system is like a symphony orchestra, working together in perfect harmony to provide you with a beautiful and seamless response. It may take some practice, but we'll hit all the right notes.",
"Our system is working diligently to solve the puzzle and provide you with the best possible response. It's like a Rubik's cube, but we're up for the challenge.",
"Our team is currently brainstorming the best way to deliver your request without using a carrier pigeon. It's harder than it sounds.",
"Our team is currently decoding ancient scrolls to find the information you need. We appreciate your patience as we delve into history.",
"Our team is currently in a deep dive into the ocean of information to find the pearl you need. We appreciate your patience as we explore the depths.",
"Our team is currently sifting through a pile of information taller than a skyscraper, but we're determined to find what you need.",
"Our team is working diligently to solve the puzzle and provide you with the best possible solution. It's like a game of chess, but we're up for the challenge.",
"Our team is working hard to deliver your request on a silver platter. Unfortunately, they're having trouble finding the silverware.",
"Our team is working on finding the needle in the haystack of information. Thank you for your patience as we search.",
"Our team of ninjas is currently working on your request. They're just taking a quick break to sharpen their swords.",
"Please bear with us while we juggle chainsaws and try to keep our systems running smoothly.",
"Please remain seated while we search for the nearest unicorn to expedite your request.",
"Please wait patiently while our highly trained hamsters power up our servers.",
"Thank you for your patience. Our system is currently taking a coffee break to provide you with a fresh and energized response.",
"Thank you for your patience. Our system is currently taking a meditation break to provide you with a calm and centered response.",
"Thank you for your patience. Our system is currently working on its yoga poses to provide you with a smooth and flexible response.",
"Thank you for your patience. Our team is currently digging through mountains of information, but we're confident we'll strike gold soon.",
"Thank you for your patience. Our team is currently polishing our magic wands to provide you with the best possible response.",
"Thanks for your patience! Our team is currently doing a rain dance to speed up our systems.",
"The suspense is killing us too. Hang in there!",
"We apologize for the delay, our team is currently exploring the universe to find your requested information.",
"We know waiting isn't fun, but just think of it as practicing your patience muscles. You'll be an Olympian in no time.",
"We promise we haven't forgotten about you! We're just making a cup of coffee to help us think better.",
"We're currently exploring the depths of the system to find the answer you seek. Thank you for your patience as we dive in.",
"We're currently in the kitchen, cooking up the perfect response for you. Thank you for your patience as we mix and stir our digital ingredients.",
"We're currently in the laboratory, experimenting with different combinations to find the perfect response for you. Thank you for your patience as we mix and match.",
"We're currently on a journey to the center of the system, searching for the answer you seek. Thank you for your patience as we explore.",
"We're currently on a mission impossible to provide you with the response you need. It may take some time, but we won't give up.",
"We're currently on a mission to find the answer you seek, fueled by caffeine and determination. Thank you for your patience as we sip our way to success.",
"We're currently on a mission to provide you with a response that's out of this world. It may take some time, but we'll make it worth the wait.",
"We're currently on a treasure hunt for the answer you seek. Thank you for your patience as we follow the map.",
"We're like a team of detectives, working to uncover the clues that will lead us to the perfect response for you. It may take some investigation, but we won't rest until we solve the case.",
"We're navigating through a maze of information to find the answer you seek. Thank you for your patience as we work our way through.",
];