import React, { useState } from "react";
import {
  Select,
  Space,
  Input,
  Row,
  Divider,
  Spin,
  Col,
  Form,
  Button,
  message,
} from "antd";
import { defaultAuthNContext } from "../api/authorization";
import { fetchJsonRes } from "../api/utils";
import { SERVICE_ORDER_API_ENDPOINT } from "../api/endpoints";
import { ServiceOrderContext } from "../api/ServiceOrderAPI";
import { ServiceOrderDetailsView } from "./serviceOrderDetailsView";

export function SearchServiceOrder(props) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [theForm] = Form.useForm();

  const options = [
    { value: "select", label: "Select", disabled: true },
    { value: "apptgroupId", label: "Appointment Group Id" },
     { value: "email", label: "Site Contact (Email)" },
    //  { value: "so", label: "Service Order"},
    //  { value: "postcode", label: "Postal Code" },
   
    // { value: "product_type", label: "Product Type" },
  ];
  const handleChange = (value) => {
    setShouldRender(false);
    theForm.setFieldsValue({ inputText: '' });
  };

  function renderTable(value) {
    if (value === true) return <ServiceOrderDetailsView state={state} />;
  }

  const onFinish = (values) => {
    const queryResult = createQueryParamForSearch(values);
    OnsearchSOWithParam(queryResult);
  };

  function createQueryParamForSearch(values) {
    const selectValue = values.select;

    switch (selectValue) {
      case "email":
        const emailQuery = `?relatedParty.contactMedium.characteristic.emailAddress=${encodeURIComponent(
          values.inputText
        )}`;
        return emailQuery;
        case "so":
          const soQuery = `/${encodeURIComponent(values.inputText)}`;
          return soQuery;
      case "postcode":
        const postCodeQuery = `?orderItem.service.place.postcode=${encodeURIComponent(
          values.inputText
        )}`;
        return postCodeQuery;
      case "apptgroupId":
        const apptGroupIdQuery = `?orderItem.service.serviceCharacteristic.value=${encodeURIComponent(
          values.inputText
        )}`;
        return apptGroupIdQuery;
      case "company_name":
        const companyNameQuery = `?relatedParty.name=${encodeURIComponent(
          values.inputText
        )}`;
        return companyNameQuery;
      case "company_number":
        const companyNumberQuery = `?relatedParty.organizationIdentification.identificationId=${encodeURIComponent(
          values.inputText
        )}`;
        return companyNumberQuery;
      case "product_type":
        const productTypeQuery = `?orderItem.service.serviceCharacteristic.productype=${encodeURIComponent(
          values.inputText
        )}`;
        return productTypeQuery;

      default:
        return "foo";
    }
  }
  const OnsearchSOWithParam = (searchInput) => {
    setLoading(true);
    setShouldRender(false);
    const orderContext = new ServiceOrderContext();
    const SERVICE_ORDER_API_ENDPOINT_WITH_QUERY_PARAM =
      SERVICE_ORDER_API_ENDPOINT + searchInput;

    defaultAuthNContext
      .getToken()
      .then((token) => {
        fetchJsonRes(SERVICE_ORDER_API_ENDPOINT_WITH_QUERY_PARAM, {
          method: "GET",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            username: "svc-app-dffd365-nprd",
            Authorization: "Bearer " + token,
          },
        }).then((data) => {
          if (data.response.status === 200) {
            orderContext.responseAsObject =
              data && data !== "" ? JSON.parse(data.response.body) : null;
            const filteredResponse = orderContext.responseAsObject.filter(
              (item) => item.state !== "cancelled"
            );
            setState(filteredResponse);
            setLoading(false);
            setShouldRender(true);
          } else if(data.response.status === 404){
            setState(JSON.parse("[]"));
            setLoading(false);
            setShouldRender(false);
            message.error("Service Order not found");
          }
          else {
            setState(JSON.parse("[]"));
            setLoading(false);
            setShouldRender(true);
            message.error("Error retrieving Service Orders List");
          }
          
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(
          "searchServiceOrder:ERROR\n%o,\nCause:%o",
          error,
          error?.cause
        );
      });

    return orderContext;
  };

  return (
    <div>
      <Row>
        <Divider></Divider>
      </Row>
      <Row className="form-center">
        <Form
          form={theForm}
          name="horizontal_login"
          layout="inline"
          initialValues={{inputText: ''}}
          onFinish={onFinish}
        >
          <Space
            direction="horizantal"
            style={{
              display: "flex",
            }}
          >
            <Form.Item
              name="select"
              rules={[{ required: true, message: "value is Required" }]}
            >
              <Select
               
                placeholder="Select"
                style={{ width: 400 }}
                onChange={handleChange}
                options={options}
              ></Select>
            </Form.Item>
            <Form.Item
              name="inputText"
              rules={[{ required: true, message: "value is Required" }]}
            >
              <Input
                style={{ width: 400 }}
                placeholder="input search text"
                autoComplete="off"
              ></Input>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Row>
      <Row
        style={{
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <Col span="24">
          <Spin
            tip="Please wait while we load the data"
            spinning={loading}
            size="large"
            style={{ textAlign: "center", marginTop: "50px" }}
          ></Spin>
          {renderTable(shouldRender)}
        </Col>
      </Row>
    </div>
  );
}
