import React, { useState, Fragment } from "react";
import { Divider, Row, Col, Button } from "antd";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";

import CharacteristicInput from "./characteristicInput";
import { ServiceEditModal } from "./serviceEditModal";

export function ServiceView(props) {
  console.debug("ServiceView:props", props);
  const {
    isEditable = true,
    value: instanceProp,
    addressbook: addressBook,
    phonebook: phoneBook,
    catalog,
    onDelete,
    onChange,
    appontment,
    // appointmentDetails,
    appointmentGroupDetails
  } = props;

  console.log("appontment  ---> ", appontment);
  // console.log("appointmentDetails  ---> ", appointmentDetails);
  console.log("appointmentGroupDetails  ---> ", appointmentGroupDetails);


  const [instance, setInstance] = useState(instanceProp);
  const [serviceSpec, setServiceSpec] = useState(
    catalog.getServiceSpecByInstance(instance)
  );
  const [editService, setEditService] = useState(false);
  console.debug("ServiceView:serviceSpec", serviceSpec);
  console.debug("ServiceView:Field instance", instance);

  const calcullateFieldValues = (serviceInstance, spec) => {
    console.debug(
      "ServiceView:calcullateFieldValues serviceInstance",
      serviceInstance
    );

    console.debug("ServiceView:calcullateFieldValues spec", spec);

    const instanceValues = serviceInstance?.asFlatObject();
    const effectiveValues = spec?.effectiveValues(instanceValues);

    return {
      ...instanceValues,
      ...effectiveValues,
    };
  };

  const fieldInstances = calcullateFieldValues(instance, serviceSpec);
  /*{
          ...serviceSpec?.defaultValues(),
          ...(instance?.asFlatObject?.())
  };*/
  console.debug("ServiceView:Field instances", fieldInstances);

  const fieldGroups =
    serviceSpec?.specs(fieldInstances).reduce((m, spec) => {
      const group = spec.getGroup() ?? "default";
      m[group] = m[group] ?? [];
      m[group].push(spec);
      return m;
    }, {}) ?? {};

  console.debug("ServiceView:Field groups", fieldGroups);

  const hasFields = fieldGroups?.default ? true : false;

  const onDeleteInternal = () => {
    onDelete?.(instance);
  };

  const onEdit = () => {
    setEditService(true);
  };

  const onInstanceChange = (newInstance) => {
    console.debug("ServiceView:onInstanceChange newInstance", newInstance);
    setInstance(newInstance);
    setServiceSpec(catalog.getServiceSpecByInstance(newInstance));
    onChange?.(newInstance);
  };

  function renderFieldGroup(name, fields) {
    const separator = name === "default" ? (<></>) : (
      <Divider key={"char-divider-" + name} orientation="left">
        {name} {/* {getAppointmentGroupId(name)} */}
      </Divider>
    );
    return (
      <>
        <Fragment key={"fragment-" + name}>
          {separator}
          <Row key={"form-row-" + name} gutter={8}>
            {fields.map((spec) => {
              return (
                <>
                  <Col key={"view-col-" + spec.id}>
                    <div className="ant-form-item-label">{spec.getName()}:</div>
                    <CharacteristicInput
                      edit={false}
                      addressbook={addressBook}
                      phonebook={phoneBook}
                      spec={spec}
                      value={fieldInstances?.[spec.getFullName()]}
                    />
                  </Col>
                </>
              );
            })}
            <Col>{renderApptDetailsForServices(name)}</Col>
          </Row>
        </Fragment>
      </>
    );
  }

  function getAppointmentGroupId(name) {
    if (appointmentGroupDetails && name)
      return (
        <b>
          - Appointment Group Id (
          <span style={{ color: "blue" }}>
            {appointmentGroupDetails?.get('default') ? appointmentGroupDetails.get('default') : 
             (appointmentGroupDetails)?  appointmentGroupDetails?.entries()?.next()?.value[1] : '#'}
          </span>
          )
        </b>
      )
  }

  function renderApptDetailsForServices(name) {
    if (appontment && name && name !== "default") {
      const siteDisplay =
        name === "Customer Site" || name === "A-Site" || name === "Current Customer Site" || name === "Work Site" ? "A-Site" : "B-Site";
      let addedItem = []
      return (
        <>
          <Fragment>
          <Divider key={"char-divider-" + name} orientation="left">Appointment Details</Divider>
            {appontment
              ?.filter((filter) => filter?.site === siteDisplay)
              ?.filter(filter => !addedItem.includes(filter?.jobId))
              ?.map((a, ai) => {
                addedItem.push(a?.jobId)
                return (
                  <>
                    <div key={ai}>
                      <b>{`${a?.category} (${a?.jobId})`} : </b>
                      {a?.appointmentDate}
                    </div>
                  </>
                );
              })}
          </Fragment>
          <Fragment>
          <Divider key={"char-divider-" + name} orientation="left" style={{ color: "#eb2f96", fontWeight:"900" }}>Appointment Group Id's</Divider>
            {
              appointmentGroupDetails && Array?.from(appointmentGroupDetails?.entries())?.map(([key, value]) => {
                return (
                  <>
                    <div key={key} style={{ color: "#1958e3" }}>
                    <b> {`${key} : `}  <i> {value} </i> </b>
                    </div>
                  </>
                );
              })}
          </Fragment>
        </>

      );
    }
  }



  return (
    <div className="ServiceView">
      <Row
        key="ServiceView-header"
        align="middle"
        name="header"
        className="PanelHeader"
      >
        <Col flex="1 1">
          <Divider orientation="left" className="compact-divider">
            <h2>{serviceSpec?.name}</h2>
          </Divider>
        </Col>
        {isEditable && (
          <Col flex="0 0 50px">
            <Button
              type="ghost"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
              onClick={onEdit}
            />
            <Button
              type="ghost"
              shape="circle"
              icon={<CloseCircleOutlined />}
              size="small"
              onClick={onDeleteInternal}
            />
          </Col>
        )}
      </Row>
      {Object.entries(fieldGroups)?.map(([group, fields]) =>
        renderFieldGroup(group, fields)
      )}

      {editService ? (
        <ServiceEditModal
          open={true}
          value={instance}
          afterClose={() => setEditService(false)}
          onChange={onInstanceChange}
          addressbook={addressBook}
          phonebook={phoneBook}
          catalog={catalog}
        />
      ) : (
        <Fragment />
      )}
    </div>
  );
}
