import ServiceCatalog from "../service-catalog/ServiceCatalog";
import {  svcspecC2PIsdn } from "../schema/schemaC2P";
import {  svcspecGenericEquipmentInstallation, svcspecGenericEqupmentRecovery, svcspecISDNEquipmentInstallation } from "./CatalogFieldServices";
import { chrspecProductTypeOf } from "./CharacteristicsCatalog";
import { svcspecGenericCableConnectivity } from "./CatalogCableConnectivity";
import { ISDN_Generic_Equipment_Installation, ISDN_Generic_Equipment_Recovery } from "../common/Constants";


const chrspecProductTypeIsdn10 = chrspecProductTypeOf("ISDN - ISDN 10");

function isdnTemplate10(name) {
  return svcspecC2PIsdn({
    name: name,
    characteristics: [chrspecProductTypeIsdn10],
    installationServiceSpec: svcspecISDNEquipmentInstallation,
    genericInstallations: new Map().set(ISDN_Generic_Equipment_Installation, svcspecGenericEquipmentInstallation),
    genericConnectivity: new Map().set(ISDN_Generic_Equipment_Installation, svcspecGenericCableConnectivity),
    genericRecovery: new Map().set(ISDN_Generic_Equipment_Installation,svcspecGenericEqupmentRecovery),
    genericName: ISDN_Generic_Equipment_Installation
    
  });
}

const chrspecProductTypeIsdn30 = chrspecProductTypeOf("ISDN - ISDN 30");

function isdnTemplate30(name) {
  return svcspecC2PIsdn({
    name: name,
    characteristics: [chrspecProductTypeIsdn30],
    installationServiceSpec: svcspecISDNEquipmentInstallation,
    genericInstallations: new Map().set(ISDN_Generic_Equipment_Installation, svcspecGenericEquipmentInstallation),
    genericConnectivity: new Map().set(ISDN_Generic_Equipment_Installation, svcspecGenericCableConnectivity),
    genericRecovery: new Map().set(ISDN_Generic_Equipment_Installation,svcspecGenericEqupmentRecovery),
    genericName: ISDN_Generic_Equipment_Installation
  });
}


export const CatalogIsdn = new ServiceCatalog()
  .category("ISDN")
  .category("ISDN 10")
  .serviceSpecs([
    isdnTemplate10("ISDN-10 DDI"),
    isdnTemplate10("Caller ID ISDN-10"),
    isdnTemplate10("Caller Number Non-Display ISDN-10"),
    isdnTemplate10("ISDN-10 Switch Standalone Line"),
    isdnTemplate10("TOS For ISDN-10"),
    isdnTemplate10("ISDN-10 DDI Partial Activation"),
    isdnTemplate10("ISDN-10 1900 Fixed Barring"),
    isdnTemplate10("ISDN-10 Unlisted Number Service"),
    isdnTemplate10("ISDN-10 Call Block"),
    isdnTemplate10("ISDN-10 Internal Relocation"),
    isdnTemplate10("ISDN-10 DDI Reservation"),
    isdnTemplate10("ISDN-10 External Relocation"),
    isdnTemplate10("ISDN-10 DDI Deactivation"),
    isdnTemplate10("ISDN-10 Renumbering"),
    isdnTemplate10("ISDN10"),
    isdnTemplate10("NAISDN10"),
    isdnTemplate10("ISDN10 CNND"),
    isdnTemplate10("ISDN10 - DDI Regroup (Charge in Blk of 10 Numbers)"),
    isdnTemplate10("ISDN10 1900 Barring Cancel Fee"),
    isdnTemplate10("ISDN10 1900 Barring Deactivation Fee"),
    isdnTemplate10("ISDN10 1900 Barring Fee"),
    isdnTemplate10("ISDN10 1900 Barring Fee for first request"),
    isdnTemplate10("ISDN10 1900 Barring Fee for subsequent request"),
    isdnTemplate10("ISDN10 Addition 5 Channels"),
    isdnTemplate10("ISDN10 Alpha Number - Choice Number"),
    isdnTemplate10("ISDN10 Alpha Number - EZ Recall Number"),
    isdnTemplate10("ISDN10 Call Blk Cancel Fee"),
    isdnTemplate10("ISDN10 Call Blk Deactivation Cancel Fee"),
    isdnTemplate10("ISDN10 Call Blk Deactivation Fee"),
    isdnTemplate10("ISDN10 Call Blk Fee"),
    isdnTemplate10("ISDN10 Call Completion NCA (1st 60 days)"),
    isdnTemplate10("ISDN10 Call Completion NCA (Subs 30 days)"),
    isdnTemplate10("ISDN10 Caller ID"),
    isdnTemplate10("ISDN10 Caller-ID Reg Cancel Fee"),
    isdnTemplate10("ISDN10 Cancel Fee"),
    isdnTemplate10("ISDN10 Change Number"),
    isdnTemplate10("ISDN10 Choice Number"),
    isdnTemplate10("ISDN10 CNND Reg Cancel Fee"),
    isdnTemplate10("ISDN10 Customised Number Change Announcement (CNA) (1st 60 days) - Prerecorded"),
    isdnTemplate10("ISDN10 Customised Number Change Announcement (CNA) (1st 60 days) - Voice recording"),
    isdnTemplate10("ISDN10 Customised Number Change Announcement (CNA) (1st 60 days) Connection"),
    isdnTemplate10("ISDN10 Customised Number Change Announcement (CNA) (Subs 30 days) - Prerecorded"),
    isdnTemplate10("ISDN10 Customised Number Change Announcement (CNA) (Subs 30 days) - Voice recording"),
    isdnTemplate10("ISDN10 DDI : Block of 10"),
    isdnTemplate10("ISDN10 DDI Number Reg Blk of 10 Cancel Fee"),
    isdnTemplate10("ISDN10 DDI Number Reservation Fee : Block of 10"),
    isdnTemplate10("ISDN10 DDI Number Selection Fee Blk of 10"),
    isdnTemplate10("ISDN10 Diamond Number"),
    isdnTemplate10("ISDN10 DID to DDI Conversion Cancel Fee"),
    isdnTemplate10("ISDN10 DID to DDI Conversion Fee"),
    isdnTemplate10("ISDN10 External Relocation (applicable to presto only)"),
    isdnTemplate10("ISDN10 External Relocation Cancel Fee"),
    isdnTemplate10("ISDN10 External Relocation Fee"),
    isdnTemplate10("ISDN10 Golden Number (GN1)"),
    isdnTemplate10("ISDN10 Golden Number (GN1) - A-C"),
    isdnTemplate10("ISDN10 Golden Number (GN2)"),
    isdnTemplate10("ISDN10 Golden Number (GN2) - A-C"),
    isdnTemplate10("ISDN10 Golden Number (GN3)"),
    isdnTemplate10("ISDN10 Golden Number (GN3) - A-C"),
    isdnTemplate10("ISDN10 IDD"),
    isdnTemplate10("ISDN10 Incoming Call Barring Cancel Fee"),
    isdnTemplate10("ISDN10 Incoming Call Barring Deactivation Cancel Fee"),
    isdnTemplate10("ISDN10 Incoming Call Barring Deactivation Fee"),
    isdnTemplate10("ISDN10 Incoming Call Barring Fee"),
    isdnTemplate10("ISDN10 Installation Cancel Fee (Express)"),
    isdnTemplate10("ISDN10 Internal Relocation Cancel Fee"),
    isdnTemplate10("ISDN10 Internal Relocation Fee"),
    isdnTemplate10("ISDN10 Listed Number Service for first request"),
    isdnTemplate10("ISDN10 Listed Number Service for subsequent request"),
    isdnTemplate10("ISDN10 Loading of Customised Announcement"),
    isdnTemplate10("ISDN10 Number Change Blk of 10"),
    isdnTemplate10("ISDN10 Number Change Blk of 10 Cancel Fee"),
    isdnTemplate10("ISDN10 Number Change Cancel Fee"),
    isdnTemplate10("ISDN10 Number Change Fee"),
    isdnTemplate10("ISDN10 Number Port Overtime Charge - A-C"),
    isdnTemplate10("ISDN10 Number Retention Service (NRS) - MRC-OTC chargeable"),
    isdnTemplate10("ISDN10 Number Retention Service (NRS) - MRC-OTC non-chargeable"),
    isdnTemplate10("ISDN10 Number Retention Service (NRS) - OTC chargeable"),
    isdnTemplate10("ISDN10 Outgoing Call Barring Cancel Fee"),
    isdnTemplate10("ISDN10 Outgoing Call Barring Deactivation Cancel Fee"),
    isdnTemplate10("ISDN10 Outgoing Call Barring Deactivation Fee"),
    isdnTemplate10("ISDN10 Outgoing Call Barring Fee"),
    isdnTemplate10("ISDN10 Reservation of Telephone Number"),
    isdnTemplate10("ISDN10 Routing on Demand"),
    isdnTemplate10("ISDN10 Routing on Demand Activation Fee (Aft Ofc Hrs)"),
    isdnTemplate10("ISDN10 Routing on Demand Activation Fee (Ofc Hrs)"),
    isdnTemplate10("ISDN10 Routing on Demand Deactivation Fee (Aft Ofc Hrs)"),
    isdnTemplate10("ISDN10 Routing on Demand Reg Cancel Fee"),
    isdnTemplate10("ISDN10 Routing on Demand Standby Fee (Aft Ofc Hrs)"),
    isdnTemplate10("ISDN10 Routing on Demand Standby Fee (Sun and Pub Hol)"),
    isdnTemplate10("ISDN10 Short Codes Mapping Charge"),
    isdnTemplate10("ISDN10 Site Setup Fee"),
    isdnTemplate10("ISDN10 Site Survey Fee"),
    isdnTemplate10("ISDN10 Standard Number Change Announcement (CNA) (1st 60 days)"),
    isdnTemplate10("ISDN10 Standard Number Change Announcement (CNA) (1st 60 days) Connection"),
    isdnTemplate10("ISDN10 Standard Number Change Announcement (CNA) (Subs 30 days)"),
    isdnTemplate10("ISDN10 Traffic Measurement"),
    isdnTemplate10("ISDN10 Transfer of Subscribership Fee"),
    isdnTemplate10("ISDN10 Unlisted Number Service Cancel Fee"),
    isdnTemplate10("ISDN10 Unlisted Number Service for first request"),
    isdnTemplate10("ISDN10 Unlisted Number Service for subsequent request"),
    isdnTemplate10("ISDN10 Visitation Charge - Min 2hrs (Sun & PH)"),
    isdnTemplate10("ISDN10 Visitation Charge - Subsequent 1hr (Sun & PH)"),
    isdnTemplate10("ISDN10 Weekday Visitation Charge - Min 2hrs (After Office Hrs)"),
    isdnTemplate10("ISDN10 Weekday Visitation Charge - Min 2hrs (During Office Hrs)"),
    isdnTemplate10("ISDN10 Weekday Visitation Charge - Subsequent 1hr (After Office Hrs)"),
    isdnTemplate10("ISDN10 Weekday Visitation Charge - Subsequent 1hr (During Office Hrs))")
  ])
  .endCategory()
  .category("ISDN 30")
  .serviceSpecs([
    isdnTemplate30("ISDN-30 IDD"),
    isdnTemplate30("ISDN-30"),
    isdnTemplate30("ISDN-30 Change of Multi-Line Hunting Mode"),
    isdnTemplate30("ISDN-30 - DDI Regroup (Charge in Blk of 10 Numbers)"),
    isdnTemplate30("ISDN-30 1900 Barring Cancellation"),
    isdnTemplate30("ISDN-30 1900 Barring for first request"),
    isdnTemplate30("ISDN-30 1900 Barring for subsequent request"),
    isdnTemplate30("ISDN-30 Alpha Number - Choice Number"),
    isdnTemplate30("ISDN-30 Alpha Number - EZ Recall Number"),
    isdnTemplate30("ISDN-30 Call Block"),
    isdnTemplate30("ISDN-30 Call Block (Temp Svc)"),
    isdnTemplate30("ISDN-30 Call Block Cancellation"),
    isdnTemplate30("ISDN-30 Call Block De-activation"),
    isdnTemplate30("ISDN-30 Call Block De-activation Cancellation"),
    isdnTemplate30("ISDN-30 Call Completion NCA (1st 60 days)"),
    isdnTemplate30("ISDN-30 Call Completion NCA (Subs 30 days)"),
    isdnTemplate30("ISDN-30 Caller Number Non-Display (Temp Svc)"),
    isdnTemplate30("ISDN-30 Caller Number Non-Display Registration Cancellation"),
    isdnTemplate30("ISDN-30 Caller-ID"),
    isdnTemplate30("ISDN-30 Caller-ID (Temp Svc)"),
    isdnTemplate30("ISDN-30 Calling Number Non-Display"),
    isdnTemplate30("ISDN-30 Cancellation"),
    isdnTemplate30("ISDN-30 Change Number"),
    isdnTemplate30("ISDN-30 Double Overflow (to different exchange & switch) (Temp Svc)"),
    isdnTemplate30("ISDN-30 Change of Multi-Line Hunting Mode Cancellation"),
    isdnTemplate30("ISDN-30 Channel Blocking Fee"),
    isdnTemplate30("ISDN-30 Channel Unblocking Fee"),
    isdnTemplate30("ISDN-30 Choice Number"),
    isdnTemplate30("ISDN-30 Customised Number Change Announcement (CNA) (1st 60 days) - Prerecorded"),
    isdnTemplate30("ISDN-30 Customised Number Change Announcement (CNA) (1st 60 days) - Voice recording"),
    isdnTemplate30("ISDN-30 Customised Number Change Announcement (CNA) (1st 60 days) Connection"),
    isdnTemplate30("ISDN-30 Customised Number Change Announcement (CNA) (Subs 30 days) - Prerecorded"),
    isdnTemplate30("ISDN-30 Customised Number Change Announcement (CNA) (Subs 30 days) - Voice recording"),
    isdnTemplate30("ISDN-30 DDI (Temp Svc)"),
    isdnTemplate30("ISDN-30 DDI : Block of 10"),
    isdnTemplate30("ISDN-30 DDI Choice Number (block of 1000)"),
    isdnTemplate30("ISDN-30 DDI Number Registration: Block of 10 Cancellation"),
    isdnTemplate30("ISDN-30 DDI Number Reservation Fee : Block of 10"),
    isdnTemplate30("ISDN-30 DDI Number Reservation Fee : Block of 10 (Account Level)"),
    isdnTemplate30("ISDN-30 Diamond Number"),
    isdnTemplate30("ISDN-30 DID to DDI conversion per conversion per switch"),
    isdnTemplate30("ISDN-30 DID to DDI conversion per conversion per switch Cancellation"),
    isdnTemplate30("ISDN-30 Double Overflow (to same exchange & same EWSD switch) (temp svc)"),
    isdnTemplate30("ISDN-30 Express Installation Cancellation (Central zone)"),
    isdnTemplate30("ISDN-30 Express Installation Cancellation (Non-Central zone)"),
    isdnTemplate30("ISDN-30 External Relocation"),
    isdnTemplate30("ISDN-30 External Relocation Cancellation"),
    isdnTemplate30("ISDN-30 Golden Number (GN1)"),
    isdnTemplate30("ISDN-30 Golden Number (GN1) - A-C"),
    isdnTemplate30("ISDN-30 Golden Number (GN2)"),
    isdnTemplate30("ISDN-30 Golden Number (GN2) - A-C"),
    isdnTemplate30("ISDN-30 Golden Number (GN3)"),
    isdnTemplate30("ISDN-30 Golden Number (GN3) - A-C"),
    isdnTemplate30("ISDN-30 Incoming Call Barring"),
    isdnTemplate30("ISDN-30 Incoming Call Barring Cancellation"),
    isdnTemplate30("ISDN-30 Internal Relocation"),
    isdnTemplate30("ISDN-30 Internal Relocation Cancellation"),
    isdnTemplate30("ISDN-30 Line Hunting Installation Cancellation"),
    isdnTemplate30("ISDN-30 Listed Number Service for first request"),
    isdnTemplate30("ISDN-30 Listed Number Service for subsequent request"),
    isdnTemplate30("ISDN-30 Multi Line Hunting"),
    isdnTemplate30("ISDN-30 Loading of Customised Announcement"),
    isdnTemplate30("ISDN-30 Multi Line Hunting Re-Grouping"),
    isdnTemplate30("ISDN-30 Over STM1"),
    isdnTemplate30("ISDN-30 Multi Line Hunting Re-grouping Cancellation"),
    isdnTemplate30("ISDN-30 Multi-Line Hunting Re-ranking"),
    isdnTemplate30("ISDN-30 Multi-Line Hunting Re-ranking Cancellation"),
    isdnTemplate30("ISDN-30 Number Change"),
    isdnTemplate30("ISDN-30 Number Change Cancellation"),
    isdnTemplate30("ISDN-30 Number Change: Block of 10"),
    isdnTemplate30("ISDN-30 Number Change: Block of 10 Cancellation"),
    isdnTemplate30("ISDN-30 Number Port Overtime Charge - A-C"),
    isdnTemplate30("ISDN-30 Number Retention Service (NRS) - MRC-OTC chargeable"),
    isdnTemplate30("ISDN-30 Number Retention Service (NRS) - MRC-OTC non-chargeable"),
    isdnTemplate30("ISDN-30 Number Retention Service (NRS) - OTC chargeable"),
    isdnTemplate30("ISDN-30 Outgoing Call Barring"),
    isdnTemplate30("ISDN-30 Outgoing Call Barring Cancellation"),
    isdnTemplate30("ISDN-30 Over STM1 HSG 155Mbps Headend (MSP 1+1)"),
    isdnTemplate30("ISDN-30 Single Overflow (to different exchange & switch)"),
    isdnTemplate30("ISDN-30 Reservation of Telephone Number"),
    isdnTemplate30("ISDN-30 Routing on Demand"),
    isdnTemplate30("ISDN-30 Routing on Demand (Temp Svc)"),
    isdnTemplate30("ISDN-30 Routing on Demand Activation After Office Hours"),
    isdnTemplate30("ISDN-30 Routing on Demand Activation During Office Hours"),
    isdnTemplate30("ISDN-30 Routing on Demand Deactivation After Office Hours"),
    isdnTemplate30("ISDN-30 Routing on Demand Registration Cancellation"),
    isdnTemplate30("ISDN-30 Routing on Demand Standby After Office Hours"),
    isdnTemplate30("ISDN-30 Routing on Demand Standby on Sunday and Public Holidays"),
    isdnTemplate30("ISDN-30 Select Number"),
    isdnTemplate30("ISDN-30 Short Codes Mapping Charge"),
    isdnTemplate30("ISDN-30 Single Overflow (to different exchange & switch) (Temp Svc)"),
    isdnTemplate30("ISDN-30 Single Overflow (to different exchange & switch) Registration Cancellation"),
    isdnTemplate30("ISDN-30 Single Overflow (to same exchange & same EWSD switch)"),
    isdnTemplate30("ISDN-30 Single Overflow (to same exchange & same EWSD switch) (Temp Svc)"),
    isdnTemplate30("ISDN-30 Single Overflow (to same exchange & same EWSD switch) Registration Cancellation)"),
    isdnTemplate30("DN to ISDN 30 (CE-SDH Fibre - Exchange Diversity)"),
    isdnTemplate30("DN to ISDN 30 (CE-SDH Fibre - Path Diversity)"),
    isdnTemplate30("ISDN 30 Fetex Main-Single Line"),
    isdnTemplate30("ISDN 30 Fetex Auxiliary Line"),
    isdnTemplate30("Caller ID - ISDN 30"),
    isdnTemplate30("Caller Number Non-Display - ISDN 30"),
    isdnTemplate30("ISDN 30 Single"),
    isdnTemplate30("ISDN 30 Master"),
    isdnTemplate30("ISDN 30 Slave (Auxiliary Line)"),
    isdnTemplate30("TOS For ISDN 30"),
    isdnTemplate30("ISDN 30 Diversity"),
    isdnTemplate30("Connected Number - ISDN 30"),
    isdnTemplate30("Caller Number Non-Display For ISDN 30 (Controllable) "),
    isdnTemplate30("Caller Number Non-Display For ISDN 30 (Per Call Activation)"),
  ])
  .endCategory()

  .endCategory();
