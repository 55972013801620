import { useRef, useState, useEffect } from "react";
import { Row, Col, Button, Divider, Alert } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined ,ReloadOutlined, HomeTwoTone} from "@ant-design/icons";
import { defaultAuthNContext } from "../api/authorization";
import { Waiter } from "../common/Waiter";
import {
  useNavigate
} from "react-router-dom";


import { MICROSITE_URL } from "../api/endpoints";

export function BookingPage(props) {
  const { orderContext, onWizardNext, onWizardBack } = props;

  const [loading, setLoading] = useState(true);
  const [_mode, _setMode] = useState("blank");
  const appointmentIDsRef = useRef();
  const micrositeTokenRef = useRef();
  const errorRef = useRef();
  const effectRun = useRef(false);
  const modeRef = useRef("blank");
  let navigate = useNavigate();


  function setMode(mode) {
    modeRef.current = mode;
    _setMode(mode);
  }

  console.debug("BookingPage:render", modeRef.current, effectRun);

  //Initialization: construct chain of promises to wait for createServiceOrder completion then retrieve microsite token
  useEffect(() => {
    let cancelled = false;

    orderContext.promise
      .then(() =>
        cancelled
          ? Promise.reject(new Error("Cancelled", { cause: "cancelled" }))
          : requestMicrositeToken()
      )
      .then((token) => {
        micrositeTokenRef.current = token;
        setMode("microsite");
      })
      .catch((error) => {
        if (error?.cause === "cancelled") {
          console.debug("BookingPage:initialization cancelled.");
        } else {
          console.error(
            "BookingPage:initialization failed.\n%o\nCause:%o",
            error,
            error?.cause
          );
          errorRef.current = error;
          setMode("error");
        }
      });

    return () => {
      cancelled = true;
    };
  }, []);

  function requestMicrositeToken() {
    const claims = {
      serviceOrderItemIDs: orderContext.soiWithAppointment.map((soi) => soi.id),
      appointmentIDs: orderContext.soiWithAppointment.map(
        (soi) => soi.service.appointment.id
      ),
    };
    appointmentIDsRef.current = claims.appointmentIDs;
    return defaultAuthNContext.getMicrositeToken(claims);
  }

  const reloadMicrosite  = () => {
    requestMicrositeToken()
      .then((token) => {
        micrositeTokenRef.current = token;
        if (modeRef.current === "microsite" && micrositeTokenRef.current) {
          document.getElementById("micrositeform").submit();
        }
      })
  }

  //delay selection of initial rendering mode to avoid screen flickering between waiting and microsite/error
  //in case when service order promise already fullfilled

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (modeRef.current === "blank") setMode("waiting");
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  //As soon as token updated - trigger form submit
  useEffect(() => {
    if (modeRef.current === "microsite" && micrositeTokenRef.current) {
      setLoading(false)
      document.getElementById("micrositeform").submit();
    }
  }, [micrositeTokenRef.current]);

  function renderWaiting() {
    return (
      <>
        <Row>
          <Col span={24} />
        </Row>
        <Row>
          <Col span={4}></Col>
          <Col span={16}>
            <Waiter
              initialMessage="We're working to get your appointments ready. It won't take longer then a couple of minutes. Please stay with us."
              expectedDuration={120}
            />
          </Col>
          <Col span={4}></Col>
        </Row>
        <Row>
          <Col span={24} />
        </Row>
      </>
    );
  }

  function renderMicrosite() {
    return (
      <Row>
        <Col span={24}>
          <iframe
            id="micrositeiframe"
            name="micrositeiframe"
            className="microsite-iframe"
            title="Microsite Container"
          />
          <form
            id="micrositeform"
            target="micrositeiframe"
            action={MICROSITE_URL}
            method="post"
          >
            <input
              type="hidden"
              name="SO"
              key="micrositeform.SO"
              value={orderContext.responseAsObject.id}
            />

            {appointmentIDsRef.current?.map((apptId) => (
              <input
                type="hidden"
                name="apptId"
                value={apptId}
                key={"micrositeform.apptId:" + apptId}
              />
            ))}
            <input
              type="hidden"
              name="access_token"
              value={micrositeTokenRef.current}
              key="micrositeform.token"
            />
          </form>
        </Col>
      </Row>
    );
  }

  function renderError() {
    return (
      <Row>
        <Col span={24}>
          <h1> </h1>

          <Alert
     message="Error"
     description={`${JSON.parse(orderContext?.soStateError?.cause?.response?.body)?.message} `} 
     type="error"
     showIcon
   />

<br />

<Button
                type="primary"
                shape="round"
                size="small"
                className="wizard-navi-button"
                onClick={() => navigate("/")}
              >
                <HomeTwoTone /> Return to Home
              </Button>


        </Col>
      </Row>
    );
  }

  function renderBody() {
    switch (modeRef.current) {
      case "waiting":
        return renderWaiting();
      case "microsite":
        return renderMicrosite();
      case "error":
        return renderError();
      default:
        return <></>;
    }
  }

  return (
    <div className="appointments-wizard-page">
      <Row>
        <Col flex="1 1">
          <Divider className="compact-divider">Booking</Divider>
        </Col>
      </Row>

      {renderBody()}

      <Row align="middle">
        <Col flex="1 1">
          <Divider />
        </Col>
      </Row>
      <Row gutter={[8, 0]}>

      

        <Col flex="1 0">
           <ReloadOutlined  onClick={reloadMicrosite} style={{ fontSize: '23px' }} />
        </Col>

       
        <Col flex="0 0">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={onWizardBack}
            
          >
            <DoubleLeftOutlined />
            Return
          </Button>
        </Col>

        <Col flex="0 0">
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={onWizardNext}
            >
            Proceed
            <DoubleRightOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
}
