import { ServiceSchema, mapperConst } from "./ServiceSchema";
import { svcspecCableConnectivityC2P } from "../catalogs/CatalogCableConnectivity";
import ServiceSpecification from "../service-catalog/ServiceSpecification";
import {
  chrspecCommonSet,
  chrspecFieldServiceAddressRole,
} from "../catalogs/CharacteristicsCatalog";
import { orderTypeValues } from "../common/Constants";
import {
  esrWorkSiteAddress,
  esrWorkSiteContact,
  esrCustomerSiteAddress,
  esrCustomerSiteContact,
  esrCurrentSiteAddress,
  esrCurrentSiteContact,
  esrNewSiteAddress,
  esrNewSiteContact,
  esrASiteAddress,
  esrASiteContact,
} from "../catalogs/EntitySpecRelationsCatalog";
import { CharacteristicSpecification } from "../service-catalog/CharacteristicSpecification";
import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";
import { svcspecEqupmentRecovery, svcspecCopperAdvanceInstallation } from "../catalogs/CatalogFieldServices";

import {
  ServiceOrderItem,
  soiRelationship,
} from "../serviceOrder/serviceOrder";
import { addAppointmentGroupName } from "../common/CommonUtils";

export function schemaWireless(props) {
  const { name, installationSpec, cableSpec, genericInstallations,
    genericConnectivity } = props;
  const schema = {
    "New Provisioning": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PProvide,
    },
    "Change Add VAS": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PAddVAS,
    },
    "Change External Relocation Hot": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PExtRelocationHot,
    },
    "Change Upgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeHot,
    },
    "Change Downgrade Hot": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeHot,
    },
    "New Upgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeParallel,
    },
    "New Downgrade Parallel": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PUpDownGradeParallel,
    },
    "New External Relocation Parallel": {
      specs: (flatInstance) => [
        esrCurrentSiteAddress,
        esrCurrentSiteContact,
        esrNewSiteAddress,
        esrNewSiteContact,
      ],
      decompose: decomposeC2PExtRelocationParallel,
    },
    "Cease with equipment recovery": {
      specs: (flatInstance) => [esrCustomerSiteAddress, esrCustomerSiteContact],
      decompose: decomposeC2PCeaseEquipmentRecovery,
    },
    default: {
      specs: (flatInstance) => [],
      decompose: (flatInstance) => [],
    },
  };

  function decomposeC2PProvide(flatInstance) {
  
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);

    console.debug(
      "schemaC2P:decompose New Provisioning ,svcInstallation",
          svcInstallation
    );

    let genericInstallationServices = [];
    if(genericInstallations){
      genericInstallationServices = []
      genericInstallations?.forEach((key, value) => {
        const genEqptInstlAndRecvry = key ? key?.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ]):null;
        addAppointmentGroupName(genEqptInstlAndRecvry, value)
        genericInstallationServices.push({ action: "add", service: genEqptInstlAndRecvry})
        });
      }


      let genericConnectivitys = [];
      if(genericConnectivity){
        genericConnectivity?.forEach((cableSpec, productName) => {
        const genericCableSpec = cableSpec?cableSpec?.updateInstance({}, flatInstance, [
          [esrASiteAddress, esrCustomerSiteAddress],
          [esrASiteContact, esrCustomerSiteContact],
        ]):null;
          addAppointmentGroupName(genericCableSpec, productName)
          genericConnectivitys.push({ action: "add", service: genericCableSpec})
          });
      }


    return [
      { action: "add", service: svcInstallation },
      ...genericInstallationServices,
      ...genericConnectivitys
    ]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PAddVAS(flatInstance) {
    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);


    console.debug(
      "schemaC2P:decompose Change Add VAS svcInstallation",
      svcInstallation
    );


    let genericConnectivitys = [];
    if(genericConnectivity){
      
      genericConnectivity?.forEach((cableSpec, groupName) => {
      const genericCableSpec = cableSpec?cableSpec?.updateInstance({}, flatInstance, [
        [esrASiteAddress, esrCustomerSiteAddress],
        [esrASiteContact, esrCustomerSiteContact],
      ]):null;
        addAppointmentGroupName(genericCableSpec, groupName)
        addAppointmentGroupName(svcInstallation, groupName)
        genericConnectivitys.push({ action: "add", service: genericCableSpec})
        });
    }

    



    return [{ action: "add", service: svcInstallation },...genericConnectivitys]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PExtRelocationHot(flatInstance) {

    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);


    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation,
          serviceOrderItemRelationship: [soiRecovery]
            .filter((a) => a)
            .map((a) => soiRelationship(a, "Dependent On")),
        })
      : undefined;

    return [soiRecovery, soiInstallation].filter((a) => a);
  }

  function decomposeC2PUpDownGradeHot(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);


    return [
      { action: "add", service: svcInstallation },
      { action: "add", service: svcRecovery }]
      .filter((a) => a.service)
      .map((a) => new ServiceOrderItem(a));
  }

  function decomposeC2PUpDownGradeParallel(flatInstance) {
 
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrCustomerSiteAddress],
      [esrWorkSiteContact, esrCustomerSiteContact],
    ]);


    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation
        })
      : undefined;

    //In parallel upgrade/downgrade scenarios equipment recovery should be done in 1 field job with installation.
    //So we will put any dependency for it hoping DFF will merge the field services into a single appointment
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;


    return [soiRecovery, soiInstallation].filter(
      (a) => a
    );
  }

  function decomposeC2PCeaseEquipmentRecovery(flatInstance) {
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCustomerSiteAddress],
          [esrWorkSiteContact, esrCustomerSiteContact],
        ])
      : undefined;

    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery,
        })
      : undefined;

    return [soiRecovery].filter((a) => a);
  }

  function decomposeC2PExtRelocationParallel(flatInstance) {
   
    const svcRecovery = installationSpec
      ? svcspecEqupmentRecovery.updateInstance({}, flatInstance, [
          //mapping
          [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
          [esrWorkSiteAddress, esrCurrentSiteAddress],
          [esrWorkSiteContact, esrCurrentSiteContact],
        ])
      : undefined;

    const svcInstallation = installationSpec?.updateInstance({}, flatInstance, [
      //mapping
      [chrspecFieldServiceAddressRole, mapperConst("A-Site")],
      [esrWorkSiteAddress, esrNewSiteAddress],
      [esrWorkSiteContact, esrNewSiteContact],
    ]);


    const soiInstallation = svcInstallation
      ? new ServiceOrderItem({
          action: "add",
          service: svcInstallation
        })
      : undefined;

    //recovery of the equpment from old site can only be done after new installation completed
    const soiRecovery = svcRecovery
      ? new ServiceOrderItem({
          action: "add",
          service: svcRecovery
        })
      : undefined;

    return [soiRecovery, soiInstallation].filter(
      (a) => a
    );
  }

  return new ServiceSchema({
    name: name,
    schema: schema,
  });
}

//temporary limit list of Order types until all order types supported
const chrspecC2POrderType = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE), 
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});




export function svcspecC2PWireless(props) {
  const { name, installationServiceSpec, cableSpec, characteristics, 
    genericInstallations,genericConnectivity } = props;
  const effectiveCableSpec =
    cableSpec === undefined ? svcspecCableConnectivityC2P : cableSpec;

  console.debug(
    "svcspecC2PWireless name,props,chrspecCommonSet(name),characteristics",
    name,
    props,
    chrspecCommonSet(name),
    characteristics
  );

  const spec = new ServiceSpecification({
    name: name,
    specCharacteristic: [...chrspecCommonSet(name), ...characteristics],
    schema: schemaWireless({
      name: "SchemaWireless-" + name,
      installationSpec: installationServiceSpec,
      cableSpec: effectiveCableSpec,
      genericInstallations,
      genericConnectivity
    }),
  });

  const i = spec.specCharacteristic.findIndex((e) => e.name === "Order Type");
  if (i > -1) spec.specCharacteristic[i] = chrspecC2POrderType;

  return spec;
}


