import ServiceCatalog from '../service-catalog/ServiceCatalog'
import {svcspecC2P, svcspecC2PNoHotUpgradeDowngrade} from '../schema/schemaC2P'
import {svcspecGenericEquipmentInstallation, svcspecGenericEquipmentInstallationFtthNo, svcspecGenericEqupmentRecovery, svcspecInstallationeVolve, svcspecSingnetBizVoiceEquipmentInstallation, svcspecSingnetEthernetEquipmentInstallation, svcspecSingnetGigawaveLiteEquipmentInstallation, svcspecSingnetMetroEEquipmentInstallation, svcspecSingnetWirelessEquipmentInstallation, svcspecSingneteLiteEquipmentInstallation} from '../catalogs/CatalogFieldServices'
import {chrspec} from '../service-catalog/CharacteristicSpecification'
import { chrspecFtthNo, chrspecProductTypeOf } from './CharacteristicsCatalog'
import { svcspecC2PX2 } from '../schema/schemaC2PX2'
import { svcspecC2PGpon } from '../schema/schemaC2PGpon'
import { svcspecC2PWireless } from '../schema/schemaC2PWireless'
import { svcspecC2PEvolve, svcspecC2PEvolveOnly } from '../schema/schemaC2PEvolve'
import { svcspecCableConnectivityC2PEvolveOnly, svcspecCableConnectivityC2PFtth, svcspecGenericCableConnectivity } from './CatalogCableConnectivity'
import { SingNet_Generic_Installation, SingNet_Wireless_Generic_Installation } from '../common/Constants'



const chrspecProductTypeSingNetSingNetSingNeteLite=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"SingNet Corporate - SingNet eLite"
});

function SingNeteLiteEtherneTemplate(name){
  return  svcspecC2PGpon({
                      name:name,
                      installationServiceSpec:svcspecSingneteLiteEquipmentInstallation,
                      characteristics:[chrspecProductTypeSingNetSingNetSingNeteLite],
                      genericInstallations: new Map().set(SingNet_Generic_Installation, svcspecGenericEquipmentInstallation)
                      .set("Meg@pop Generic Installation", svcspecGenericEquipmentInstallation),
                      
                      genericConnectivity: new Map()
                      .set(SingNet_Generic_Installation, svcspecGenericCableConnectivity)
                      .set("Meg@pop Generic Installation", svcspecGenericCableConnectivity)
                     

                    })
}


//SingNet Ethernet
const chrspecProductTypeSingnetEthernet=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"SingNet Corporate - SingNet Ethernet"
});

function ethernetTemplate(name){
  return  svcspecC2PNoHotUpgradeDowngrade({
                      name:name,
                      installationServiceSpec:svcspecSingnetEthernetEquipmentInstallation,
                      characteristics:[chrspecProductTypeSingnetEthernet],
                      genericInstallations: new Map().set("SingNet Ethernetlink Generic Installation", svcspecGenericEquipmentInstallation),
                      genericConnectivity: new Map().set("SingNet Ethernetlink Generic Installation",svcspecGenericCableConnectivity)
                    })
}



//SingNet MetroEthernet
const chrspecProductTypeSingNetSingNetMetroEtherne=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"SingNet Corporate - SingNet MetroEthernet"
});


function singNetMetroEtherneTemplate(name){
  return  svcspecC2PNoHotUpgradeDowngrade({
                      name:name,
                      installationServiceSpec:svcspecSingnetMetroEEquipmentInstallation,
                      characteristics:[chrspecProductTypeSingNetSingNetMetroEtherne],
                      genericInstallations: new Map().set("SingNet MetroE Generic Installation", svcspecGenericEquipmentInstallation),
                      genericConnectivity: new Map().set("SingNet MetroE Generic Installation",svcspecGenericCableConnectivity)
                    })
}



const chrspecProductTypeSingNetWireless = chrspec({
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "SingNet Corporate - SingNet Wireless",
});


function singNetWirelessTemplate(name) {
  return svcspecC2PWireless({
    name: name,
    characteristics: [chrspecProductTypeSingNetWireless],
    installationServiceSpec: svcspecSingnetWirelessEquipmentInstallation,
    genericInstallations: new Map().set(SingNet_Wireless_Generic_Installation, svcspecGenericEquipmentInstallationFtthNo),

    genericConnectivity: new Map()
    .set(SingNet_Wireless_Generic_Installation, svcspecGenericCableConnectivity)
  });
}



const chrspecProductTypeSingNetCorporateGigawaveLite=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"SingNet Corporate - SingNet GigawaveLite"
});

function singNetCorporateGigawaveLiteTemplate(name){
  return  svcspecC2PX2({
                      name:name,
                      installationServiceSpec:svcspecSingnetGigawaveLiteEquipmentInstallation,
                      characteristics:[chrspecProductTypeSingNetCorporateGigawaveLite],
                      genericInstallations: new Map()
                      .set("SingNet Gigawave Generic Equipment Installation", svcspecGenericEquipmentInstallation)
                      .set("Gigawave Generic Equipment Installation - Monitoring", svcspecGenericEquipmentInstallation)
                      .set("Gigawave Generic Equipment Installation - DWDM Fibre Main", svcspecGenericEquipmentInstallation),

                      genericConnectivity: new Map()
                      .set("default", svcspecGenericCableConnectivity)
                      .set("SingNet Gigawave Generic Equipment Installation", svcspecGenericCableConnectivity),
                      // .set("Gigawave Generic Equipment Installation - Monitoring", svcspecGenericCableConnectivity)
                      // .set("Gigawave Generic Equipment Installation - DWDM Fibre Main", svcspecGenericCableConnectivity),
                      
                      genericRecovery: new Map()
                      .set("SingNet Gigawave Generic Equipment Installation", svcspecGenericEqupmentRecovery)
                      .set("Gigawave Generic Equipment Installation - Monitoring", svcspecGenericEqupmentRecovery)
                      .set("Gigawave Generic Equipment Installation - DWDM Fibre Main", svcspecGenericEqupmentRecovery),
                    })
}


const chrspecProductTypeSingNetBizVoice=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"SingNet Corporate - SingNet BizVoice"
});


function singNetBizVoiceTemplate(name){
  return  svcspecC2PNoHotUpgradeDowngrade({
                      name:name,
                      installationServiceSpec:svcspecSingnetBizVoiceEquipmentInstallation,
                      characteristics:[chrspecProductTypeSingNetBizVoice]
                    })
}

const chrspecProductTypeEVolve = chrspecProductTypeOf("SingNet Corporate - SingNet eVolve");
function singnetEvolveTemplate(name) {
  return svcspecC2PEvolve({
    name: name,
    cableSpec: svcspecCableConnectivityC2PFtth,
    characteristics: [chrspecProductTypeEVolve, chrspecFtthNo],
    installationServiceSpec: svcspecInstallationeVolve,
    genericInstallations: new Map().set("SingNet Generic Installation", svcspecGenericEquipmentInstallationFtthNo),
    genericConnectivity: new Map().set("SingNet Generic Installation",svcspecGenericCableConnectivity)
  });
}

function singnetEvolveTemplateEvolveOnly(name) {
  return svcspecC2PEvolveOnly({
    name: name,
    cableSpec: svcspecCableConnectivityC2PEvolveOnly,
    characteristics: [chrspecProductTypeEVolve],
    installationServiceSpec: svcspecInstallationeVolve,
    genericInstallations: new Map().set("SingNet Generic Installation", svcspecGenericEquipmentInstallationFtthNo),
    genericConnectivity: new Map().set("SingNet Generic Installation",svcspecGenericCableConnectivity)
  });
}


const chrspecProductTypeSingNetPlus = chrspecProductTypeOf("SingNet Corporate - SingNet Plus");

function singnetPlusTemplate(name) {
  return svcspecC2PEvolve({
    name: name,
    cableSpec: svcspecCableConnectivityC2PFtth,
    characteristics: [chrspecProductTypeSingNetPlus, chrspecFtthNo],
    installationServiceSpec: svcspecInstallationeVolve,
    genericInstallations: new Map().set("SingNet Generic Installation", svcspecGenericEquipmentInstallationFtthNo),
    genericConnectivity: new Map().set("SingNet Generic Installation",svcspecGenericCableConnectivity)
  });
}





export const catalogSingNet=((new ServiceCatalog())
  .category("SingNet Corporate")
    
    .category("SingNet Ethernet")
    .serviceSpecs([
      ethernetTemplate("SingNet Ethernet"),
      ethernetTemplate("SingNet Ethernet Access "),
      ethernetTemplate("SingNet EVPL Ethernet Access"),
      ethernetTemplate("SingNet Ethernet Access (Path Diversity)"),
      ethernetTemplate("Ethernet 100 Mbps"),
      ethernetTemplate("Ethernet 20 Mbps"),
      ethernetTemplate("Ethernet 200 Mbps"),
      ethernetTemplate("Ethernet 300 Mbps"),
      ethernetTemplate("Ethernet 50 Mbps"),
   
      ethernetTemplate("SingNet Ethernet (Temp Svc)"),
      ethernetTemplate("SingNet Ethernet Active Backup"),
      ethernetTemplate("SingNet Ethernet Exchange Diversity"),
      ethernetTemplate("SingNet Ethernet External Relocation Charge (256Kbps - 2Mbps)"),
      ethernetTemplate("SingNet Ethernet External Relocation Charge (3Mbps & Above)"),
      ethernetTemplate("SingNet Ethernet Internal Relocation Charge (256Kbps - 2Mbps)"),
      ethernetTemplate("SingNet Ethernet Internal Relocation Charge (3Mbps & Above)"),
      ethernetTemplate("SingNet Ethernet Path Diversity"),
     ])
  .endCategory()

  .category("SingNet MetroEthernet")
  .serviceSpecs([

singNetMetroEtherneTemplate("SingNet MetroEthernet"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Burstable"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Access"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Access Combo Bandwidth"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Access with Exchange Diversity"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Access (Path Diversity)"),
singNetMetroEtherneTemplate("MetroEthernet 100 Mbps"),
singNetMetroEtherneTemplate("MetroEthernet 20 Mbps"),
singNetMetroEtherneTemplate("MetroEthernet 200 Mbps"),
singNetMetroEtherneTemplate("MetroEthernet 300 Mbps"),
singNetMetroEtherneTemplate("MetroEthernet 50 Mbps"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 100 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 100 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 20 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 20 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 200 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 200 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 300 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 300 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 50 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet - 50 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Access with 1-end Exchange Diversity"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Disaster Recovery"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Disaster Recovery (Exch Div)"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 100 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 100 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 20 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 20 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 200 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 200 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 300 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 300 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 50 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Exchange Diversity - 50 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 100 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 100 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 20 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 20 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 200 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 200 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 300 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 300 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 50 Mbps - 2 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Path Diversity - 50 Mbps - 3 Years Subscription"),
singNetMetroEtherneTemplate("SingNet MetroEthernet Temp Svc"),

   ])
.endCategory()


.category("SingNet eLite")
.serviceSpecs([
  SingNeteLiteEtherneTemplate("SingNet eLite"),
  SingNeteLiteEtherneTemplate("Internal Relocation"),
  SingNeteLiteEtherneTemplate("External Relocation"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access (XGSPON)"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access Cross Connect Charge"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access with Exchange Diversity"),
  SingNeteLiteEtherneTemplate("SingNet Cloud Connect (Microsoft Direct)"),
  SingNeteLiteEtherneTemplate("SingNet Cloud Connect Reconfiguration Charge"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access 2.0"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access - XGPON"),
  SingNeteLiteEtherneTemplate("SingNet eLite Plus 2.0"),
  SingNeteLiteEtherneTemplate("SingNet eLite Plus"),
  SingNeteLiteEtherneTemplate("SingNet eLite Virtual Circuit"),
  SingNeteLiteEtherneTemplate("SingNet eLite Access CIR/PIR"),
  SingNeteLiteEtherneTemplate("Pre-Order Site Survey"), 
  SingNeteLiteEtherneTemplate("eLite 100 Mbps"),
  SingNeteLiteEtherneTemplate("eLite 20 Mbps"),
  SingNeteLiteEtherneTemplate("eLite 200 Mbps"),
  SingNeteLiteEtherneTemplate("eLite 300 Mbps"),
  SingNeteLiteEtherneTemplate("eLite 50 Mbps"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 100 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 100 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 20 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 20 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 200 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 200 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 300 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 300 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 50 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite - 50 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 100 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 100 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 20 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 20 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 200 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 200 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 300 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 300 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 50 Mbps - 2 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite Exchange Diversity - 50 Mbps - 3 Years Subscription"),
  SingNeteLiteEtherneTemplate("SingNet eLite flexi Access (1Gbps PIR/ 100Mbps CIR)"),
  SingNeteLiteEtherneTemplate("SingNet eLite flexi Access (200Mbps PIR/ 50Mbps CIR)"),
  SingNeteLiteEtherneTemplate("SingNet eLite flexi Access (500Mbps PIR/ 100Mbps CIR)"),
 ])
.endCategory()

.category("SingNet eVolve(Corporate/Enterprise)")
.serviceSpecs([
  singnetEvolveTemplate("Singnet Evolve"),
  singnetEvolveTemplate("Singnet Evolve NLT"),
  singnetEvolveTemplate("SingNet eVolve Fibre Broadband"),
  singnetEvolveTemplate("SingNet eVolve Fibre Broadband (XGSPON)"),
  singnetEvolveTemplate("SingNet eVolve Fibre Broadband w 1 Static IP"),
  singnetEvolveTemplate("SingNet eVolve Fibre Broadband w 1 Static IP (XGSPON)"),
  singnetEvolveTemplate("SingNet eVolve Assured Internet"),
  singnetEvolveTemplate("SingNet eVolve WiFi Mesh  Monthly subscription (Twin)"),
  singnetEvolveTemplate("SingNet eVolve WiFi Mesh (Single)"),
  singnetEvolveTemplate("SingNet eVolve WiFi Mesh (Twin)"),
  singnetEvolveTemplate("SingNet eVolve WiFi Mesh Delivery Charge"),
  singnetEvolveTemplate("SingNet eVolve WiFi Mesh Installation & Delivery"),
  singnetEvolveTemplate("SingNet eVolve WiFi Mesh Monthly subscription (Single)"),
  
])
.endCategory()

.category("Singnet eVolve Only")
.serviceSpecs([
  singnetEvolveTemplateEvolveOnly("Singnet Evolve - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("Singnet Evolve NLT - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve Fibre Broadband - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve Fibre Broadband (XGSPON) - Evolve Only "),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve Fibre Broadband w 1 Static IP - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve Fibre Broadband w 1 Static IP (XGSPON) - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve Assured Internet - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve WiFi Mesh  Monthly subscription (Twin) - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve WiFi Mesh (Single) - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve WiFi Mesh (Twin) - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve WiFi Mesh Delivery Charge - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve WiFi Mesh Installation & Delivery - Evolve Only"),
  singnetEvolveTemplateEvolveOnly("SingNet eVolve WiFi Mesh Monthly subscription (Single) - Evolve Only"),
])
.endCategory()


//Disabled in Prod
.category("SingNet Wireless")
.serviceSpecs([

  singNetWirelessTemplate("SingNet Wireless (Back Up)"),
  singNetWirelessTemplate("SingNet Wireless (Standalone)"),
  singNetWirelessTemplate("SingNet Wireless@SG with SingNet eVolve Fibre Broadband (Customised)"),
  singNetWirelessTemplate("SingNet Wireless@SG with SingNet E-Access Dynamic (Standard)"),
  singNetWirelessTemplate("SingNet Wireless Installation"),
  singNetWirelessTemplate("SingNet Wireless APN"),
  singNetWirelessTemplate("SingNet Wireless Static Link"),
  singNetWirelessTemplate("SingNet Wireless Dynamic Link"),
])
.endCategory()


// Disabled in Prod
.category("SingNet BizVoice")
.serviceSpecs([
  singNetBizVoiceTemplate("SingNet BizVoice"),
  singNetBizVoiceTemplate("SingNet BizVoice Starter Plan"),
  singNetBizVoiceTemplate("Start Plan VAS Pack 1"),
  singNetBizVoiceTemplate("Start Plan VAS Pack 2"),
  singNetBizVoiceTemplate("Start Plan VAS Pack 3"),
 ])
.endCategory()

.category("SingNet Plus")
.serviceSpecs([

  singnetPlusTemplate("SingNet Plus"),
  singnetPlusTemplate("SingNet Broadband Plus - 2 Year Subscription"),
  singnetPlusTemplate("SingNet Broadband Plus - 3 Year Subscription"),
  singnetPlusTemplate("SingNet Broadband Plus Service Activation Charge"),
  singnetPlusTemplate("SingNet eVolve Plus (Dynamic)"),
 ])
.endCategory()



.category("SingNet GigawaveLite")
.serviceSpecs([
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite Access Unlimited"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite Access Combo Bandwidth"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite Access Burstable"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite Access Internal Relocation"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite Access External Relocation"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite Access (Path Diversity)"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite via GWL (Unprotected)"),
  singNetCorporateGigawaveLiteTemplate("SingNet GigawaveLite via GWL (Protected)"),
 
 ])
.endCategory()





  .endCategory());

  /*
  External Relocation Charge	New Provision	#N/A	Irene Ng	Y	FTH0102 /FTH103	C2P	Fibre / ONT / Router installation	Fibre/ONT/Router	Installation of new eVolve 
FTP - Repair, Relocation, Replacement	New Provision	#N/A	Irene Ng	Y		C2P	TP-RRR	Fibre	Maintenance
SingNet eVolve Fibre Broadband	New Provision	#N/A	Irene Ng	Y	FTH0102 /FTH103	C2P	Fibre / ONT / Router installation	Fibre/ONT/Router	Installation of new eVolve 
ONT Charge	New Provision	#N/A	Irene Ng	Y		C2P		ONT	Maintenance 
Refit Charge	New Provision	#N/A	Irene Ng	Y		C2P			Maintenance 
Remote Location Infra Charge	New Provision	#N/A	Irene Ng	Y		C2P		Underground fibre installation by IE	Maintenance 
Remote Location Pre-Order Survey Charge	New Provision	#N/A	Irene Ng	Y		C2P			Maintenance 
Router Charge	New Provision	#N/A	Irene Ng	Y		C2P		ONT/Router	Maintenance 
SingNet eVolve Fibre Broadband	New Provision	SGN0005	Irene Ng	Y	FTH0102 /FTH103	C2P		ONT/Router	Installation of new eVolve 
SingNet eVolve Fibre Broadband w 1 Static IP	New Provision	SGN0031	Irene Ng	Y	FTH0102 /FTH103	C2P			Installation of new eVolve 
SingNet eVolve WiFi Mesh  Monthly subscription (Twin)	New Provision	#N/A	Irene Ng	N		C2P	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install	Wifi Mesh	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install
SingNet eVolve WiFi Mesh (Single)	New Provision	#N/A	Irene Ng	N		C2P	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install	Wifi Mesh	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install
SingNet eVolve WiFi Mesh (Twin)	New Provision	#N/A	Irene Ng	N		C2P	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install	Wifi Mesh	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install
SingNet eVolve WiFi Mesh Delivery Charge	New Provision	#N/A	Irene Ng	N		C2P	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install	Wifi Mesh	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install
SingNet eVolve WiFi Mesh Installation & Delivery	New Provision	#N/A	Irene Ng	N		C2P	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install	Wifi Mesh	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install
SingNet eVolve WiFi Mesh Monthly subscription (Single)	New Provision	#N/A	Irene Ng	N		C2P	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install	Wifi Mesh	FE for new eVolve installations only. For existing installlation - mesh delivery by courier, ustomer self-install
  */