import { orderTypeValues } from "../common/Constants";
import {
  CharacteristicSpecification,
  chrspec,
} from "../service-catalog/CharacteristicSpecification";

import {
  chvspecString,
  chvspecStringDefault,
} from "../service-catalog/CharacteristicValueSpecification";

export const chrspecOrderTypeAllValues = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-all",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE),
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});

export const chrspecOrderTypeAllValuesForEvolve = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-evolveonly-c2p",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.HOT_UPGRADE),
    chvspecString(orderTypeValues.HOT_DOWNGRADE),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});

export const chrspecOrderTypeNoHotUpgrade = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.order-type-no-hot-upgrade",
  name: "Order Type",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault(orderTypeValues.NEW_PROVISIONING),
    chvspecString(orderTypeValues.PARALLEL_UPGRADE),
    chvspecString(orderTypeValues.PARALLEL_DOWNGRADE),
    chvspecString(orderTypeValues.PARALLEL_EXT_RELOCATION),
    chvspecString(orderTypeValues.HOT_EXT_RELOCATION),
    chvspecString(orderTypeValues.CEASE_EQ_RECOVERY),
    chvspecString(orderTypeValues.ADD_VAS),
  ],
});


export const chrspecOrderTypeProvide = chrspec({
  id: "serviceCharacteristic:com.singtel.stab.order-type-provide",
  name: "Order Type",
  type: "string",
  isReadOnly: true,
  defaultVal: orderTypeValues.NEW_PROVISIONING,
});

export const chrspecOrderPriority = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.priority",
  name: "Priority",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault("Normal"),
    chvspecString("Express"),
  ],
});

export const chrspecOrderSensitive = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.sensitive",
  name: "Sensitive",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault("Normal"),
    chvspecString("Sensitive"),
  ],
});


export const chrspecCRD = chrspec({
  name: "Customer Requested Date",
  type: "date",
  isMandatory: true,
});

export const chrspecServiceNo = chrspec({
  name: "Service No",
  type: "string",
  isMandatory: false,
});

export const chrspecCustomerTypeBusiness = chrspec({
  name: "Customer Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
  defaultVal: "Business",
});

export const chrspecFieldServiceAddressRole = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.field-service-address-role",
  name: "Field Service Address Role",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault("A-Site"),
    chvspecString("B-Site"),
  ],
});

export const chrspecProductType = chrspec({
  id: "serviceCharacteristic:com.singtel.stab.product-type",
  name: "Product Type",
  type: "string",
  isMandatory: false,
  isReadOnly: true,
});

export const chrspecProductTypeOf = (value) =>
  chrspec({
    id: "serviceCharacteristic:com.singtel.stab.product-type",
    name: "Product Type",
    type: "string",
    isMandatory: false,
    isReadOnly: true,
    defaultVal: value,
  });

  export const chrspecFtthNo = chrspec({
    id: "serviceCharacteristic:com.singtel.stab.ftth-no",
    name: "Ftth No",
    type: "ftthno",
    isMandatory: true,
    isReadOnly: false,
    defaultVal:0,
    value:0
  });

export function chrspecCommonSet(productOffering) {
  return [
    chrspecOrderTypeAllValues,
    chrspecOrderPriority,
    chrspecOrderSensitive,
    chrspecCRD,
    chrspecServiceNo,
    chrspecCustomerTypeBusiness,
    chrspec({
      name: "Product Offering",
      type: "string",
      isMandatory: false,
      isReadOnly: true,
      defaultVal: productOffering,
    }),
  ];
}


export function chrspecCommonSetEvolveOnly(productOffering) {
  return [
    chrspecOrderTypeAllValuesForEvolve,
    chrspecOrderPriority,
    chrspecOrderSensitive,
    chrspecCRD,
    chrspecServiceNo,
    chrspecCustomerTypeBusiness,
    chrspec({
      name: "Product Offering",
      type: "string",
      isMandatory: false,
      isReadOnly: true,
      defaultVal: productOffering,
    }),
    chrspecFtthNo
  ];
}


export function chrspecCommonSetWithFtthNo(productOffering) {
  return [
    chrspecOrderTypeAllValues,
    chrspecOrderPriority,
    chrspecOrderSensitive,
    chrspecCRD,
    chrspecServiceNo,
    chrspecCustomerTypeBusiness,
    chrspec({
      name: "Product Offering",
      type: "string",
      isMandatory: false,
      isReadOnly: true,
      defaultVal: productOffering,
    }),
    chrspecFtthNo
  ];
}

export const chrspecPremiseWiring = new CharacteristicSpecification({
  id: "serviceCharacteristic:com.singtel.stab.premisewiring",
  name: "Wiring",
  valueType: "string",
  minCardinality: 1,
  characteristicValueSpecification: [
    chvspecStringDefault("Not Required"),
    chvspecString("Advance Wiring"),
  ],
});
