import {
  Row,
  Col,
  Button,
  Divider,
  Descriptions,
  Modal,
  Input,
  message,
} from "antd";
import {
  HomeTwoTone,
  PrinterTwoTone,
  MailTwoTone,
  SendOutlined,
  CloseOutlined,
  UserOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";
import { Waiter } from "../common/Waiter";
import { ServiceView } from "../service/serviceView";
import { useRef, useState, useEffect, Fragment } from "react";
import { UploadedDocumentView } from "../document/UploadedDocumentView";
import ReactDOMServer from "react-dom/server";
import { EMAIL_API_ENDPOINT, APPOINTMENTS_URL, SERVICE_ORDER_API_ENDPOINT } from "../api/endpoints";
import { fetchText, fetchJsonRes } from "../api/utils";

import { getAppointmentDetails, getAppointmentGroupDetails, validateEmails } from "../common/CommonUtils";
import { defaultAuthNContext } from "../api/authorization";


import {
  useNavigate
} from "react-router-dom";


export function Summary(props) {
  const {
    customerOrganization,
    initialServices,
    catalog,
    supportingDocuments,
    orderContext,
    addressBookRef,
    phoneBookRef,
    onWizardBack
  } = props;

  const { TextArea } = Input;
  const { current: services } = useRef(orderContext);
  const { current: serviceOrderResponse } = useRef(services?.responseAsObject);
  const { current: servicesRef } = useRef(initialServices);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const emailAddresses = useRef(null);
  const specialInstructions = useRef(null);
  const focalScopeMailbox = window.STABEnvironment.focalScopeMailbox;
 
  const [appointments, setAppointments] = useState([]);
  const [showWaiter, setShowWaiter] = useState(false);
  const appointmentDetails = useRef();
  const appointmentGroupDetails = useRef();
  const retriveSOResponse = useRef();

  let navigate = useNavigate();

  const showModal = () => {
    setConfirmLoading(false);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    
    defaultAuthNContext.getToken().then(async (tkn) => {

      const SERVICE_ORDER_API_ENDPOINT_WITH_QUERY_PARAM =
      SERVICE_ORDER_API_ENDPOINT+ "/"+orderContext.responseAsObject.id;
      
      await fetchJsonRes(SERVICE_ORDER_API_ENDPOINT_WITH_QUERY_PARAM, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          username: "svc-app-dffd365-nprd",
          Authorization: "Bearer " + tkn,
        }}).then((data) => {
          console.log('SERVICE_ORDER_API_ENDPOINT data', data)
          if (data.response.status === 200) {
            retriveSOResponse.current =
              data && data !== "" ? JSON.parse(data.response.body) : null;
              console.log('Retrive serviceOrderResponse data', retriveSOResponse.current)
          }
        });

      await fetchText(
        `${APPOINTMENTS_URL}?relatedEntity.id=${orderContext.responseAsObject.id}`,
        {
          method: "GET",
          Accept: "*/*",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tkn}`,
          }
        }
      ).then((data) => {
        setAppointments(JSON.parse(data.replaceAll("\\", "")));
      });
    })
    .finally(() => {
      setShowWaiter(false);
    });;
  }, []);

  
  appointmentDetails.current = getAppointmentDetails(appointments,retriveSOResponse?.current);
  appointmentGroupDetails.current = getAppointmentGroupDetails(serviceOrderResponse);

  
  const renderServices = () =>
    servicesRef &&
    Object.entries(servicesRef)?.map(([serviceIndex, s]) => {
     console.log("appointmentDetails: serviceIndex -----> ", serviceIndex, "   === ", appointmentDetails?.current?.get(serviceIndex))
     console.log("appointmentGroupDetails: serviceIndex -----> ", serviceIndex, "   === ", appointmentGroupDetails?.current?.get(serviceIndex))

      return (
        <>
        <ServiceView
          key={s?.service?.id ?? "42"}
          edit={true}
          value={s?.service}
          addressbook={addressBookRef.current}
          phonebook={phoneBookRef.current}
          catalog={catalog}
          isEditable={false}
          appontment={appointmentDetails?.current?.get(serviceIndex)}
          appointmentGroupDetails={appointmentGroupDetails?.current?.get(serviceIndex)}
        />
        </>
      );
    });

  const getBody = () => {
    return `
    <html lang="en">
    <head>
    </head>
      <body>
      <p><b>Special Instructions : </b>  ${
        specialInstructions?.current?.resizableTextArea?.textArea?.value
      }</p>
    <table border="1"
           style="background-color:#FFFFFF;
                  border-collapse:collapse;
                  border:1px solid #CCCCFF;
                  color:#000000;
                  width:100%"
            cellpadding="3" 
            cellspacing="3">
            <tr>
              <td>Company</td>
              <td>Email</td>
              <td>Contact Number</td>
              <td>VIP Customer</td>
              <td>Express</td>
            </tr>
            <tr>
              <td>${customerOrganization?.name}</td>
              <td>${customerOrganization?.getEmail()}</td>
              <td>${customerOrganization?.getPhone()}</td>
              <td>${customerOrganization?.getVIP()}</td>
              <td>${customerOrganization?.name}</td>
            </tr>
    </table> 
    <br />
      ${ReactDOMServer.renderToStaticMarkup(renderServices())}
      <br />
     
      ${ReactDOMServer.renderToStaticMarkup(
        <>
          <Divider orientation="left">
            <h3>Uploaded Documents Details</h3>
          </Divider>
          <UploadedDocumentView
            supportingDocuments={supportingDocuments}
            showDeleteButton={false}
          />
        </>
      )}
      </body>
    </html>
     `;
  };

  const getSubject = () => {
    return "Standalone Appointment Booking ";
  };

  const getSenderEmailAddress = () => {
    return "s-noreply_orders@singtel.com";
  };

  const getToEmailAddresses = () => {
    const emails = [];
    var result = emailAddresses?.current?.input?.value
      .replace(/\s/g, "")
      .split(/,|;/);
    for (var i = 0; i < result.length; i++) {
      emails.push({ email: result[i] });
    }

    var defaultMailAddress = focalScopeMailbox?.replace(/\s/g, "").split(/,|;/);
    for (var i = 0; i < defaultMailAddress?.length; i++) {
      emails.push({ email: defaultMailAddress[i] });
    }


    return emails;
  };

  const emailTemplate = () => {
    return {
      callbackFlag: true,
      cc: [],
      characteristic: [],
      content: getBody().replace("/\r?\n|\r/g", ""),
      description: "string",
      logFlag: true,
      priority: 0,
      receiver: getToEmailAddresses(),
      sender: {
        email: getSenderEmailAddress(),
      },
      subject: getSubject(),
      tryTimes: 3,
      type: "Mail",
    };
  };

  const sendEmail = () => {
    if (!validateEmails(emailAddresses?.current?.input?.value)) {
      message.error(`Please verify email address format`, 4);
      return;
    }

    setConfirmLoading(true);
    const emailTemplateBody = emailTemplate();
    console.log("emailTemplateBody -> ", emailTemplateBody);

    defaultAuthNContext.getToken().then((tkn) => {
      fetchText(EMAIL_API_ENDPOINT, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tkn}`,
        },
        body: JSON.stringify(emailTemplateBody).replace("/\r?\n|\r/g", ""),
      })
        .then((data) => {
          if (data?.trim() === '""') {
            console.log("communicationMessage-send:Data Received", data);
            message.success(
              `Great! You were able to successfully send appointment details`,
              5
            );
            setConfirmLoading(true);
            setOpen(false);
          } else {
            message.error(
              "Oops! Some error in sending email, Please try again",
              5
            );
            setConfirmLoading(false);
          }
        })
        .catch((error) => {
          setConfirmLoading(false);
          console.error(
            "communicationMessage-send:ERROR\n%o,\nCause:%o",
            error,
            error?.cause
          );

          message.error(
            "Oops! Some error in sending email, Please try again",
            5
          );
        });
    });
  };

  return (
    <>
      {showWaiter && (
        <Waiter
          initialMessage="We're working to get your appointments Details.Please stay with us."
          expectedDuration={60}
        />
      )}

      {!showWaiter && (
        <Fragment>
          <Divider orientation="left">
            <h3>Special Instructions</h3>
          </Divider>
          <TextArea
            rows={4}
            placeholder="Special Instructions. This notes will be added in to Email"
            ref={specialInstructions}
          />
          <Divider orientation="left">
            <h3>Booking Details</h3>
          </Divider>
          <Descriptions bordered size={"middle"}>
            <Descriptions.Item label="Company">
              {customerOrganization?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {customerOrganization?.getEmail()}
            </Descriptions.Item>
            <Descriptions.Item label="Contact Number">
              {customerOrganization?.getPhone()}
            </Descriptions.Item>
            <Descriptions.Item label="VIP Customer">
              {customerOrganization?.getVIP()}
            </Descriptions.Item>
          </Descriptions>
          <br />
          {renderServices()}
          <br />
          <Divider orientation="left">
            <h3>Uploaded Documents Details</h3>
          </Divider>
          <UploadedDocumentView
            supportingDocuments={supportingDocuments}
            showDeleteButton={false}
          />
          <Divider>End Of Page</Divider>
          <Row gutter={[8, 0]}>
            <Col flex="0 0">
              <Button
                type="primary"
                shape="round"
                size="large"
                className="wizard-navi-button"
                onClick={() => navigate("/")}
              >
                <HomeTwoTone /> Home
              </Button>
            </Col>

            <Col flex="0 0">
          <Button
            type="dashed"
            shape="round"
            size="large"
            className="wizard-navi-button"
            onClick={onWizardBack}
            
          >
            <ArrowLeftOutlined />
            Back
          </Button>
        </Col>


            <Col flex="0 0">
              <Button
                shape="round"
                size="large"
                className="wizard-navi-button"
                onClick={() => window.print()}
              >
                <PrinterTwoTone /> Print
              </Button>
            </Col>

            <Col flex="0 0">
              <Button
                shape="round"
                size="large"
                className="wizard-navi-button"
                onClick={showModal}
              >
                <MailTwoTone /> Email
              </Button>
            </Col>
          </Row>
          <Divider dashed />
          <>
            <Modal
              title="Send Appointment Details"
              open={open}
              onOk={sendEmail}
              destroyOnClose={true}
              closable={true}
              confirmLoading={confirmLoading}
              okButtonProps={{
                icon: <SendOutlined />,
              }}
              cancelButtonProps={{
                hidden: true,
                icon: <CloseOutlined />,
              }}
              onCancel={handleCancel}
              keyboard={true}
              okType={"default"}
              okText={"Send"}
              width={700}
            >
              {}

              <div>
                Please provide the email addresses separated by semicolons (;)
                in the following format:
                <br />
                <i>example1@email.com;example2@email.com;example3@email.com</i>
                <br />
                <br />
                Default: {focalScopeMailbox}
                <Input
                  placeholder="Email Address (Optional)"
                  prefix={<UserOutlined />}
                  ref={emailAddresses}
                />
              </div>

              <Divider orientation="left">
                <h4>Email Preview</h4>
              </Divider>

              <div
                className="scrollable-div"
                dangerouslySetInnerHTML={{
                  __html: getBody().replace("/\r?\n|\r/g", ""),
                }}
              ></div>
            </Modal>
          </>
        </Fragment>
      )}
    </>
  );
}
