export default class ServiceCategory{
  id=null;
  name=null;
  isRoot=false;
  category=[];
  serviceCandidate=[];

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
      if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
      else console.error("ServiceCategory unknown property",e);
      if (!this.id) this.id=this.name;
    });
  }
}