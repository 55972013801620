// eslint-disable-next-line no-undef
import { v4 as uuid } from "uuid";
import React, { useState, Fragment } from "react";
import { Cascader, Divider, Form, Row, Col, Button, Skeleton } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import CharacteristicInput from "./characteristicInput";
import { useForm } from "antd/lib/form/Form";

function ServiceEdit(props) {
  console.debug("ServiceEdit:props", props);
  const {
    value: instance,
    addressbook: addressBook,
    phonebook: phoneBook,
    catalog,
    edit,
    onChange,
  } = props;
  const [serviceSpec, setServiceSpec] = useState(
    catalog.getServiceSpecByInstance(instance)
  );
  const [version, setVersion] = useState(0);
  const [open, setOpen] = useState(true);
  console.debug("ServiceEdit:serviceSpec", serviceSpec);

  const [theForm] = useForm();

  function calcullateFieldValues(spec) {
    const formValues = theForm?.getFieldsValue(true);
    const instanceValues = instance?.asFlatObject?.();
    const vals =
      Object.entries(formValues).length === 0 ? instanceValues : formValues;
    console.debug(
      "ServiceEdit:calcullateFieldValues formValues,instanceValues,vals",
      formValues,
      instanceValues,
      vals
    );
    const effectiveValues = spec?.effectiveValues(vals);
    console.debug(
      "ServiceEdit:calcullateFieldValues effectiveValues",
      effectiveValues
    );
    return { ...vals, ...effectiveValues };
  }

  const fieldInstances = calcullateFieldValues(serviceSpec);

  const fieldGroups =
    serviceSpec?.specs(fieldInstances).reduce((m, spec) => {
      const group = spec.getGroup() ?? "default";
      m[group] = m[group] ?? [];
      m[group].push(spec);
      return m;
    }, {}) ?? {};

  console.debug("ServiceEdit:Field groups", fieldGroups);

  const hasFields = fieldGroups?.default ? true : false;

  /*{
          ...serviceSpec?.defaultValues(),
          ...(instance?.asFlatObject?.())
  };*/
  console.debug("ServiceEdit:Field instances", fieldInstances);

  const onServiceChange = (value, selectedOptions) => {
    setOpen(!open)
    console.debug(
      "ServiceEdit:onServiceChange:value selectedOptions",
      value,
      selectedOptions
    );
    const spec_id = selectedOptions?.pop().value;
    const spec = catalog.getServiceSpec(spec_id);

    console.debug(
      "ServiceEdit:onServiceChange:getFieldsValue",
      theForm.getFieldsValue(true)
    );
    console.debug("ServiceEdit:onServiceChange:Selected Service Spec:", spec);
    setServiceSpec(spec);

    const fieldValues = calcullateFieldValues(spec);

    console.debug("ServiceEdit:onServiceChange:fieldValues", fieldValues);

    theForm.setFieldsValue(fieldValues);
    
  };

  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  function onFormFieldsChanged(changedFields, allFields) {
  
    console.log("onFormFieldsChanged", changedFields, allFields);
    if (
      changedFields?.find((v) =>
        v.name.find((n) => n === "serviceCharacteristic:Order Type")
      )
    )
      setVersion(version + 1);
  }

  function onFormFinish(values) {
    console.log("ServiceEdit:onFormFinish", values);

    const newServiceInstance = serviceSpec.updateInstance(instance, values);
    newServiceInstance.id =
      newServiceInstance.id ?? "STD-APPT-" + uuid().toString();

    console.log(
      "ServiceEdit:onFormFinish newServiceInstance",
      newServiceInstance
    );

    onChange?.(newServiceInstance);
  }

  function onFormCancel() {
    props?.onCancel?.();
  }

  function renderFieldGroup(name, fields) {
    const separator =
      name === "default" ? (
        <></>
      ) : (
        <Divider key={"char-divider-" + name} orientation="left">
          {name}
        </Divider>
      );
    return (
      <Fragment key={"fragment-" + name}>
        {separator}
        <Row key={"form-row-" + name} gutter={8}>
          {fields.map((spec) => {
            return (
              <Col key={"form-col-" + spec.id} flex="0 0">
                <Form.Item
                  key={name}
                  label={spec.getName()}
                  name={spec.getFullName()}
                  rules={[
                    {
                      type: "any",
                      required: spec.isRequired(),
                      transform: (val) => {
                        console.log("Validator:", val);
                        return val?.value;
                      },
                    },
                  ]}
                >
                  <CharacteristicInput
                    edit={edit}
                    addressbook={addressBook}
                    phonebook={phoneBook}
                    spec={spec}
					serviceSpec={serviceSpec}
                  />
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Fragment>
    );
  }



  return (
    <div className="ServiceEdit">
      <div key="ServiceEdit-header" name="header" className="PanelHeader">
        <Cascader
          
          value={serviceSpec?.name}
          options={catalog.buildNavigationTree()}
          onChange={onServiceChange}
          placeholder="Select service"
          displayRender={(labels, selectedOptions) => labels.pop()}
          bordered={false}
          expandTrigger="hover"
          size="large"
          showSearch={filter}
          onSearch={(value) => console.log("OnSearch:" + value)}
          open={open}
        ></Cascader>
      </div>
      <Divider
        key={"main-divider"}
        orientation="left"
        className="compact-divider"
      ></Divider>
      <div key="ServiceEdit-body" name="content" className="PanelBody">
        <Skeleton loading={!hasFields} />
        <Form
          layout="vertical"
          colon={true}
          labelWrap={false}
          initialValues={fieldInstances}
          form={theForm}
          onFieldsChange={onFormFieldsChanged}
          onFinish={onFormFinish}
          validateMessages={{
            // eslint-disable-next-line no-template-curly-in-string
            required: "${label} is required!",
          }}
        >
          {Object.entries(fieldGroups)?.map(([group, fields]) =>
            renderFieldGroup(group, fields)
          )}
          {edit ? (
            <>
              <Divider />
              <Row justify="end" gutter={[8, 8]}>
                <Col>
                  <Button
                    disabled={!hasFields}
                    type="primary"
                    htmlType="submit"
                    icon={<CheckCircleOutlined />}
                  >
                    Save
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="ghost"
                    htmlType="reset"
                    icon={<CloseCircleOutlined />}
                    onClick={onFormCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Fragment />
          )}
        </Form>
      </div>
    </div>
  );
}

export default ServiceEdit;
