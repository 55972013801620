import ServiceCatalog from "../service-catalog/ServiceCatalog"
import  ServiceSpecification  from "../service-catalog/ServiceSpecification"
import {esrASiteAddress,esrASiteContact,esrBSiteAddress,esrBSiteContact} from './EntitySpecRelationsCatalog'
import {chrspecCommonSet,chrspecCommonSetEvolveOnly,chrspecCommonSetWithFtthNo,chrspecFieldServiceAddressRole,chrspecProductType} from '../catalogs/CharacteristicsCatalog'

export const svcspecCableConnectivityC2P=new ServiceSpecification({
      id:"com.singtel.stab.cable.c2p",
      name:"C2P E2E Cable Connectivity",
      category:"E2E Cable Connectivity",
      specCharacteristic:[...chrspecCommonSet("C2P E2E Cable Connectivity"),chrspecProductType,chrspecFieldServiceAddressRole],
      entitySpecRelationship:[
        esrASiteAddress,
        esrASiteContact
    ]
  }
)


export const svcspecCableConnectivityC2PEvolveOnly=new ServiceSpecification({
  id:"com.singtel.stab.cable.c2p",
  name:"C2P E2E Cable Connectivity",
  category:"E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSetEvolveOnly("C2P E2E Cable Connectivity"),chrspecProductType,chrspecFieldServiceAddressRole],
  entitySpecRelationship:[
    esrASiteAddress,
    esrASiteContact
]
}
)

export const svcspecCableConnectivityC2PFtth=new ServiceSpecification({
  id:"com.singtel.stab.cable.c2p",
  name:"C2P E2E Cable Connectivity",
  category:"E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSetWithFtthNo("C2P E2E Cable Connectivity"),chrspecProductType,chrspecFieldServiceAddressRole],
  entitySpecRelationship:[
    esrASiteAddress,
    esrASiteContact
]
}
)



export const svcspecGenericCableConnectivity=new ServiceSpecification({
  id:"com.singtel.stab.generic.cable.c2p",
  name:"Generic E2E Cable Connectivity",
  category:"Generic E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSet("Generic E2E Cable Connectivity"),chrspecProductType,chrspecFieldServiceAddressRole],
  entitySpecRelationship:[
    esrASiteAddress,
    esrASiteContact
]})


export const svcspecGenericFTTHCableConnectivity=new ServiceSpecification({
  id:"com.singtel.stab.generic.cable.c2p",
  name:"Generic E2E Cable Connectivity",
  category:"Generic E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSetWithFtthNo("Generic E2E Cable Connectivity"),chrspecProductType,chrspecFieldServiceAddressRole],
  entitySpecRelationship:[
    esrASiteAddress,
    esrASiteContact
]})


export const svcspecCableConnectivityC2PBSite=new ServiceSpecification({
  id:"com.singtel.stab.cable.c2p",
  name:"C2P E2E Cable Connectivity",
  category:"E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSet("C2P E2E Cable Connectivity"),chrspecProductType,chrspecFieldServiceAddressRole],
  entitySpecRelationship:[
    esrBSiteAddress,
    esrBSiteContact
]
}
)

export const svcspecCableConnectivity2C2P=new ServiceSpecification({
  id:"com.singtel.stab.cable.c2p",
  name:"2C2P E2E Cable Connectivity",
  category:"E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSet("2C2P E2E Cable Connectivity"),chrspecProductType],
  entitySpecRelationship:[
    esrASiteAddress,
    esrASiteContact,
    esrBSiteAddress,
    esrBSiteContact,
]
}
)


export const svcspecCableConnectivityC2C=new ServiceSpecification({
  id:"com.singtel.stab.cable.c2c",
  name:"C2C E2E Cable Connectivity",
  category:"E2E Cable Connectivity",
  specCharacteristic:[...chrspecCommonSet("C2C E2E Cable Connectivity"),chrspecProductType],
  entitySpecRelationship:[
    esrASiteAddress,esrASiteContact,esrBSiteAddress,esrBSiteContact
    ]
}
)







export const catalogCableConnectivity=((new ServiceCatalog())
  .category("Cable Connectivity")
    .serviceSpecs([
      svcspecCableConnectivityC2P,
      svcspecCableConnectivityC2C,
      svcspecCableConnectivity2C2P,
      svcspecGenericCableConnectivity
      //C2C E2E Cable Connectivity
      //C2P E2E Cable Connectivity
      //P2P E2E Cable Connectivity
      
    ])
  .endCategory());








/*
2C2P E2E Cable Connectivity	2C2P Configuration of a cable construction project with business process flow.
C2C E2E Cable Connectivity	C2C Configuration of a cable construction project with business process flow.
C2P E2E Cable Connectivity	C2P Configuration of a cable construction project with business process flow.
E2E Cable Connectivity	    Configuration of a cable construction project with business process flow.
IB Cable Pulling	
P2P E2E Cable Connectivity	P2P Configuration of a cable construction project with business process flow.
*/