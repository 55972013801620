export function isNumber(number) {
  if (number !== undefined && number.trim !== "" && /^\d+$/.test(number)) {
    return true;
  }
}

export function isEmail(email) {
  if (
    email.trim !== undefined &&
    email.trim !== "" &&
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return true;
  }
}

export function isUen(uenSearch) {
  if (uenSearch !== undefined && uenSearch !== null) return true;
}
