import React, { useState } from "react";
import CustomSelectBase from "../common/CustomSelectBase";
import UenForm from "./uenForm.js";
import { fetchJson } from "../api/utils";
import { Organization } from "./Party";
import { UEN_ENDPOINT } from "../api/endpoints";
import { defaultAuthNContext } from "../api/authorization";

function UenSearch(props) {
  const {
    value,
    onChange,
    onFinish,
    edit,
    organizationAddressBook,
    ...otherProps
  } = props;

  const [selectOptions, setSelectOptions] = useState(
    organizationAddressBook.asLabeledValues()
  );

  const [open, setOpen] = useState(false);
  const [dropdownMode, setDropdownMode] = useState("select");
  const [selectValue, setSelectValue] = useState(value?._name());

  async function onDebouncedSearch(searchTerm, abortController) {
    console.debug("search term", searchTerm);

    const requestBody = {
      query: `*${searchTerm}*`,
      limit: 100,
    };

    console.debug("UEnSearch:onDebouncedSearch", searchTerm, abortController);
    const uriEncodedSearchTerm = encodeURIComponent(searchTerm);
    console.log("uriEncodedSearchTerm ", uriEncodedSearchTerm);


    defaultAuthNContext.getToken()
    .then((token) => fetchJson(UEN_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        username: "svc-app-dffd365-nprd",
          Authorization: "Bearer " + token,
      },
      body: JSON.stringify(requestBody),
      signal: abortController.signal,
    })
    
    ).then((data) =>{
      if (data?.response?.docs?.length > 0) {
        const uenSearchResultSet = data?.response?.docs?.map(
          (a) => new Organization(a)
        );
        organizationAddressBook.setTransient(uenSearchResultSet);
        setSelectOptions(organizationAddressBook.asLabeledValues());
      }
    }
    ).catch((error) => {
      console.error(
        "error in uen search:ERROR\n%o,\nCause:%o",
        error,
        error?.cause
      );
    });
  }

  function onSelectChange(value, option) {
    setSelectValue(value);
    if (onChange) onChange(organizationAddressBook.get(option.label));
  }

  //SMELL: [Si] unnecessary code
  function onOptionSelected(option) {
    console.debug("UenSearch:onOptionSelected", option);
    const selectedUen = organizationAddressBook.get(option.label);
    console.debug("UenSearch:onOptionSelected selectedUen", selectedUen);
  }

  function onFormFinish(newUen) {
    organizationAddressBook.setPersistent(newUen);
    setOpen(false);
    onSelectChange(newUen._name(), {
      label: newUen._name(),
      value: newUen._name(),
    });
    setSelectOptions(organizationAddressBook.asLabeledValues());
  }

  function onFormCancel() {
    setDropdownMode("select");
  }

  function onClickManualEditMode(e) {
    console.debug("UenSearch:onClickManualEditMode", e);
    setDropdownMode("uensearch");
  }

  function renderForm(option, mode) {
    console.debug("UenSearch:renderForm", option, mode);
    if (mode === "select")
      return (
        <span>
          Start typing to search or{" "}
          <a onClick={onClickManualEditMode}>
            Click here to enter company details manually
          </a>
        </span>
      );

    return (
      <UenForm
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        mode={mode}
        onFinish={onFormFinish}
        onCancel={onFormCancel}
      />
    );
  }

  return edit ? (
    <CustomSelectBase
      {...otherProps}
      className="form-field-long"
      options={selectOptions}
      value={selectValue}
      mode={dropdownMode}
      onDropdownModeChange={setDropdownMode}
      open={open}
      onDropdownVisibleChange={setOpen}
      dropdownMatchSelectWidth={450}
      onChange={onSelectChange}
      onDebouncedSearch={onDebouncedSearch}
      onOptionSelected={onOptionSelected}
      footerRender={renderForm}
      placeholder="Search by UEN/Company/Postal"
    />
  ) : (
    <div className="form-field-long">{props.value?.name ?? "N/A"}</div>
  );
}

export default UenSearch;