import React, { useRef, useEffect} from 'react';
import {Form,Input,Button,Row,Col, Divider} from 'antd'
import {CheckCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';
import {GeographicAddress,GeographicSubAddress} from './geographicAddress';


function AddressForm(props){
  const {Item}=Form;
  const {mode,onFinish,onCancel,address,...otherProps}=props;
  const focusInputRef=useRef(null);

  const required={rules:[{required:true}]};

  function cleanInput(inputVal){
    if (!inputVal) return undefined;
    return inputVal.trim().toUpperCase();
  }

  function normalizeUnitNo(unitNo){
    if (!unitNo) return undefined;
    const m=unitNo.match(/#*(?<unit>\w+-\w+)/);
    return (m?.groups?.unit)?"#"+m.groups.unit:unitNo;
  }

  function onFormFinish(values){
    console.log('AddressForm::onFinish',values);
    const intialAddress=(mode==="unit")?address:{
      postcode:cleanInput(values?.["Postal Code"])
      ,streetName:cleanInput(values?.["Street Name"])
      ,streetNr:cleanInput(values?.["Block Number"]) 
      ,geographicSubAddress:[new GeographicSubAddress({
        buildingName: cleanInput(values?.["Building Name"])
        })]    
    };

    const res=new GeographicAddress({
      ...intialAddress,
      geographicSubAddress:[
        new GeographicSubAddress({
        ...intialAddress?.geographicSubAddress[0],
        buildingName: cleanInput(values?.["Building Name"]),
        subUnitNumber: normalizeUnitNo(cleanInput(values?.["Unit"]))
        })]    
    })

    console.log('AddressForm::onFinish result',res);
    onFinish(res);
  }

  function onFormCancel(values){
    console.log('AddressForm::onFormCancel',values);
    onCancel();
  }

  useEffect(() => {
    console.log("AddressForm:Effect",focusInputRef);
    if (focusInputRef.current) {
      focusInputRef.current.focus();
    }
  }, [mode]);

  
  function renderAddressItems(){
    console.log(`AddressForm:RenderItems mode=${mode}`);

    switch(mode){
      case "unit":
        return (
          <>
          <Item label="Address" name="addressKey" value={address?.name}>
            <div value={address?.name}>{address?.name}</div>
          </Item>
          <Item  label="Unit" name="Unit"  {...required}>
             <Input ref={focusInputRef} placeholder="#01-02 or LOBBY" />
          </Item>
          </>
        )
      default:
        return (
          <>
            <Item label="Postal Code" name="Postal Code" value={address?.name} {...required}>
              <Input placeholder="239732" ref={focusInputRef}/>
            </Item>
            <Item label="Block Number" name="Block Number" value={address?.streetNr} {...required}>
              <Input placeholder="31A"/>
            </Item>
            <Item label="Street Name" name="Street Name" value={address?.streetName} {...required}>
              <Input placeholder="EXETER ROAD"/>
            </Item>
            <Item label="Building Name" name="Building Name" value={address?.subBuildingName}>
              <Input placeholder="COMCENTRE"/>
            </Item>
            <Item  label="Unit" name="Unit"  {...required}>
              <Input placeholder="#01-02 or LOBBY" />
            </Item>
          </>
        );
    }
  }

  return (
    <Form
    {...otherProps}
    onFinish={onFormFinish}
    >
  
       {renderAddressItems()}

      <Divider className="compact-divider"/>
      <Row justify="end">
        <Col span={6}>
            <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined/>}>Select</Button>
        </Col>
        <Col span={6}>
          <Button type="ghost" htmlType="reset" icon={<CloseCircleOutlined/>} onClick={onFormCancel}>Cancel</Button>
        </Col>
      </Row>
    </Form>
    
  )
}

export default AddressForm;