export class ServiceSchema{
  name="BaseSchema";

  schema={};

  default={ 
    specs:(instance)=>[],
    decompose:(instance)=>[]
  };

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("ServiceSchema unknown property",e);
      });
  }

  //Default Order Type as schema selector- use override if need to customize
  schemaSelector=(flatInstance)=>flatInstance? Object.entries(flatInstance)?.find(([k,c])=>c.name==="Order Type")?.[1].value
  :undefined;

  selectSchema=(flatInstance)=>{
    const selector=this.schemaSelector(flatInstance);
    console.debug("ServiceSchema::selectSchema selector",selector,flatInstance);
    return this.schema[selector] ?? this.schema["default"];
  };

  specs=(flatInstance)=>{
    const spcs=this.selectSchema(flatInstance).specs(flatInstance);
    console.debug("ServiceSchema::specs instanceValues,specs",this.name,flatInstance,spcs);
    return spcs ;
  }

  decompose(flatInstance){
    const orderItems=this.selectSchema(flatInstance).decompose(flatInstance);
    console.debug("ServiceSchema::decompose instance,orderItems",this, this.name,flatInstance,orderItems);
    return orderItems ;
  }
}

export function mapperConst(val){
  return {instanceToValue:(x)=>val,getFullName:()=>"#const-mapper-"+val.toString()};
}