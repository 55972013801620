class MediumCharacteristic{
  //A string. The city.
  city=null;
  //A string. The type of contact, for example: phone number such as mobile, fixed home, fixed office. postal address such as shipping instalation....
  contactType=null;
  //A string. The country.
  country=null;
  //A string. Full email address in standard format.
  emailAddress=null;
  //A string. The primary phone number of the contact.
  phoneNumber=null;

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("MediumCharacteristic unknown property",e);
      });
  }
}

class Characteristic {
  //A String. Name of the characteristic
  name = null;

  //A String
  valueType = null;

  // Any Value. Value of the Characteristic
  value = null;
  // A string. When sub-classing, this defines the super-class.
  "@baseType" = null;

  // An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
  "@schemaLocation" = null;

  // A string. When sub-classing, this defines the sub-class Extensible name.
  "@type" = "Characteristic";

  constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("Characteristic unknown property",e);
      });
  }

}

class OrganizationIdentification {

  identificationId=null;

  identificationType=null;
  
  issuingAuthority=null;
  
  issuingDate=null;

  validFor=null;

   // A string. When sub-classing, this defines the super-class.
   "@baseType" = null;

   // An uri (Uri). A URI to a JSON-Schema file that defines additional attributes and relationships.
   "@schemaLocation" = null;
 
   // A string. When sub-classing, this defines the sub-class Extensible name.
   "@type" = "OrganizationIdentification";


   constructor(pojo){
    Object.entries(pojo).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("OrganizationIdentification unknown property",e);
      });
  }

 
}
class ContactMedium{
static  EMAIL="email address";
static  PHONE="contact number";
// A medium characteristic (MediumCharacteristic). Any additional characteristic(s) of this contact medium.
characteristic=null;
//A string. Type of the contact medium, such as: email address, telephone number, postal address.
mediumType=null;
//A boolean. If true, indicates that is the preferred contact medium.
preferred=false;
//A time period. The time period that the contact medium is valid for.
validFor=null;

constructor(pojo){
  Object.entries(pojo).forEach(e => {
      if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
      else console.error("ContactMedium unknown property",e);
    });
}
static email=(value)=>new ContactMedium({mediumType:ContactMedium.EMAIL,characteristic:new MediumCharacteristic({emailAddress:value})});
static phone=(value)=>new ContactMedium({mediumType:ContactMedium.PHONE,characteristic:new MediumCharacteristic({phoneNumber:value})});
}

export class Individual{

  //A string. First name of the individual.
  givenName=null;
  //A string. Contains the non-chosen or inherited name. Also known as last name in the Western context.
  familyName=null;
  //A string. Middles name or initial.
  middleName=null;
  //A string. Full name flatten (first, middle, and last names).
  fullName=null;
  //A string. A fully formatted name in one string with all of its pieces in their proper place and all of the necessary punctuation. Useful for specific contexts (Chinese, Japanese, Korean,...).
  formattedName=null;
  //A list of contact mediums (ContactMedium [*]). Indicates the contact medium that could be used to contact the party.
  contactMedium=[];
  //A list of characteristics (Characteristic [*]). Describes a given characteristic of an object or entity through a name/value pair.
  partyCharacteristic=null;
  //type of entity
  "@type"="Individual";
  //A string. Hyperlink to access the resource.
  href=null;
  //A string. Unique identifier 
  id=null;

  constructor(pojo){
    const {emailAddress,phoneNumber,...otherProps}=pojo;
    Object.entries(otherProps).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("Individual unknown property",e);
      });
    if (emailAddress) this.contactMedium.push(ContactMedium.email(emailAddress));
    if (phoneNumber) this.contactMedium.push(ContactMedium.phone(phoneNumber));
    if(!this.fullName) this.fullName=[this.givenName,this.middleName,this.familyName].join(' ').replace(/\s+/,' ');
  }

  getEmail=()=>this.contactMedium
                  .find(cm=>cm.mediumType===ContactMedium.EMAIL)
                  ?.characteristic?.emailAddress;
  
  getPhone=()=>this.contactMedium
                  .find(cm=>cm.mediumType===ContactMedium.PHONE)
                  ?.characteristic?.phoneNumber;
  
  //name for compatibility with named cache
  //generate one-string summary of the object
  name=()=>{
    const mail_value=this.getEmail();
    const phone_value=this.getPhone();
    const mail=(mail_value)?"<"+mail_value+">":null;
    const res=this.fullName +
          ((mail)?mail:"")+
          ((phone_value)?", "+phone_value:"");
    return res;
  }
}

export class Organization {
  //A string. Organization name (department name for example).
  name=null;
  //A list of contact mediums (ContactMedium [*]). Indicates the contact medium that could be used to contact the party.
  contactMedium=[];
  //A list of characteristics (Characteristic [*]). Describes a given characteristic of an object or entity through a name/value pair.
  partyCharacteristic=[];

  organizationIdentification=[];
  //type of entity
  "@type" = "Organization";
  //A string. Hyperlink to access the resource.
  href = null;
  //A string. Unique identifier 
  id = null;

  constructor(pojo){
    const {emailAddress,phoneNumber,vipCustomer,sensitiveInfo,uen,entity_name,
           /*ignore porperties of uen search response entity*/
           issuance_agency_id,uen_status,entity_type,reg_street_name,reg_postal_code,_version_,uen_issue_date,
            ...otherProps}=pojo;
    Object.entries(otherProps).forEach(e => {
        if (this.hasOwnProperty(e[0])) this[e[0]]=e[1]
        else console.error("Organization unknown property",e);
      });
    if(entity_name) this.name = entity_name;
    if (emailAddress) this.contactMedium.push(ContactMedium.email(emailAddress));
    if (phoneNumber) this.contactMedium.push(ContactMedium.phone(phoneNumber));
    if(vipCustomer) this.partyCharacteristic.push(new Characteristic({name:"Customer VIP", value: vipCustomer, valueType: "boolean" }));
    if(sensitiveInfo) this.partyCharacteristic.push(new Characteristic({name:"Sensitive Info", value: sensitiveInfo, valueType: "boolean" }));
    if(uen)this.organizationIdentification.push(new OrganizationIdentification({identificationId:uen, identificationType:"UEN"}))

  }

  getEmail=()=>this.contactMedium
                  .find(cm=>cm.mediumType===ContactMedium.EMAIL)
                  ?.characteristic?.emailAddress;

                   
  getPhone=()=>this.contactMedium
  .find(cm=>cm.mediumType===ContactMedium.PHONE)
  ?.characteristic?.phoneNumber;

  getVIP=()=>this.partyCharacteristic
  .find(cm=>cm.name==="Customer VIP")
  ?.value;

  getSensitiveInfo=()=>this.partyCharacteristic
  .find(cm=>cm.name==="Sensitive Info")
  ?.value;

  getuen=()=>this.organizationIdentification
  .find(cm=>cm.identificationType==="UEN")
  ?.identificationId;


  // Name for NamedCache
  _name() {
    const uenId= this.getuen();
    const uenName=this.name;
    const phone_value= this.getPhone();
    const email_value=this.getEmail();

    return (
      "" +
      (uenId && uenId !== "" ? uenId : "") +
      (uenName && uenName !== "" ? ", " + uenName : "") +
      (phone_value && phone_value !== "" ? ", " + phone_value : "")+
      (email_value && email_value !== "" ? ", " + email_value : "")
    );
  };
}