import ServiceCatalog from '../service-catalog/ServiceCatalog'
import {svcspecFieldService} from '../schema/schemaFieldService'
import {svcspecInstallationeBizDelCopper} from '../catalogs/CatalogFieldServices'
import {chrspec} from '../service-catalog/CharacteristicSpecification'
import {chrspecOrderTypeNoHotUpgrade, chrspecPremiseWiring} from './CharacteristicsCatalog'

const chrspecProductTypeDEL=chrspec({
  name:"Product Type",
  type:"string",
  isMandatory:false,
  isReadOnly:true,
  defaultVal:"DEL - Business"
});

function DELTemplate(name){
  const spec= svcspecFieldService({
                      name:name,
                      installationServiceSpec:svcspecInstallationeBizDelCopper,
                      characteristics:[chrspecProductTypeDEL,chrspecPremiseWiring]
                    });

  const i=spec.specCharacteristic.findIndex(e=>e.name==="Order Type");
  if (i>-1) spec.specCharacteristic[i]=chrspecOrderTypeNoHotUpgrade;
  return spec;
};

export const catalogDEL=((new ServiceCatalog())
  .category("Business DEL")
      .serviceSpecs([
        DELTemplate("DEL Business"),
        DELTemplate("Additional Wiring @10/10m"),
        DELTemplate("Singtel Telephone Line - Temporary Service"),
        DELTemplate("Wiring Up To 50m"),
        DELTemplate("External Removal"),
        DELTemplate("Internal Removal"),
        DELTemplate("Calling Number Non-Display Service"),
        DELTemplate("Singtel Telephone Line Rental"),
        DELTemplate("Unlisted Number (with CNND) Service"),
        DELTemplate("Unlisted Number Service")
       ])
   
  .endCategory());
