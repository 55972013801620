export const orderTypeValues = {
  NEW_PROVISIONING: "New Provisioning",
   PARALLEL_UPGRADE: "New Upgrade Parallel",
   PARALLEL_DOWNGRADE: "New Downgrade Parallel",
   PARALLEL_EXT_RELOCATION: "New External Relocation Parallel",
   HOT_UPGRADE: "Change Upgrade Hot",
   HOT_DOWNGRADE: "Change Downgrade Hot",
   HOT_EXT_RELOCATION: "Change External Relocation Hot",
   CEASE_EQ_RECOVERY: "Cease with equipment recovery",
   ADD_VAS: "Change Add VAS",
};


export const orderTypeValuesIsdn = {
  NEW_PROVISIONING: "New Provisioning",
  PARALLEL_UPGRADE: "New Upgrade Parallel",
  PARALLEL_DOWNGRADE: "New Downgrade Parallel",
  PARALLEL_EXT_RELOCATION: "New External Relocation Parallel",
  HOT_UPGRADE: "Change Upgrade Hot",
  HOT_DOWNGRADE: "Change Downgrade Hot",
  HOT_EXT_RELOCATION: "Change External Relocation Hot",
   CEASE_EQ_RECOVERY: "Cease with equipment recovery",
  ADD_VAS: "Change Add VAS",
};



export const orderTypeNoHotUpgradeDowngradeValues = {
  NEW_PROVISIONING: "New Provisioning",
  PARALLEL_UPGRADE: "New Upgrade Parallel",
  PARALLEL_DOWNGRADE: "New Downgrade Parallel",
  PARALLEL_EXT_RELOCATION: "New External Relocation Parallel",
  //HOT_UPGRADE: "Change Upgrade Hot",
  //HOT_DOWNGRADE: "Change Downgrade Hot",
  HOT_EXT_RELOCATION: "Change External Relocation Hot",
   CEASE_EQ_RECOVERY: "Cease with equipment recovery",
  ADD_VAS: "Change Add VAS",
};


export const GENERIC_EE = "Generic Equipment Installation"
export const GENERIC_ER = "Generic Equipment Recovery"
export const GENERIC_EER = "Generic Equipment Installation/Recovery"


export const CAT_EQU_INSTL="Equipment Installation"
export const CAT_EQU_GEN_INSTL="Generic Equipment Installation"





export const Generic_E2E_C2P_Id = "com.singtel.stab.generic.cable.c2p.id"
export const Generic_E2E_C2P_Cat = "Generic E2E Cable Connectivity"
export const Generic_E2E_C2P_Name = "Generic E2E Cable Connectivity"

export const Gen_Eqpt_Instl_Id="serviceCharacteristic:com.singtel.fs.installation.generic.equipment.installation.id"
export const Gen_Eqpt_Instl_Cat = "Equipment Installation"
export const Gen_Equipt_Instl_Name = "Generic Equipment Installation"
export const Gen_Equipt_Recvry_Name = "Generic Equipment Recovery"




// export const CAT_EQU_INSTL="Equipment Installation"
// export const CAT_EQU_GEN_INSTL="Generic Equipment Installation"
// export const CAT_EQU_GEN_INSTL_ID="serviceCharacteristic:com.singtel.fs.installation.generic.equipment.installation"




export const Megpop_Generic_Installation = "Meg@pop Generic Installation"
export const IPTV_Generic_Installation = "IPTV Generic Installation"
export const SingNet_Wireless_Generic_Installation = "SingNet Wireless Generic"
export const SingNet_Generic_Installation = "SingNet Generic Installation"
export const SingNet_MetroE_Generic_Installation = "SingNet MetroE Generic Installation"
export const ISDN_Generic_Equipment_Installation = "ISDN Generic Equipment Installation"
export const ISDN_Generic_Equipment_Recovery = "ISDN Generic Equipment Recovery"
export const SIP_Generic = "SIP Generic"












